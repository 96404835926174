//import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';

const signUpMutationGQL = gql`
    mutation signup(
        $email: String!
        $firstName: String!
        $lastName: String!
        $password: String!
        $phone: String
        $profession: String
        $company: String
        $certifications: Boolean!
        $personalInfoAgreement: Boolean!
        $acceptedPrivacyPolicy: Boolean!
    ) {
        signup(
            input: {
                email: $email
                lastName: $lastName
                firstName: $firstName
                password: $password
                phone: $phone
                profession: $profession
                company: $company
                certifications: $certifications
                personalInfoAgreement: $personalInfoAgreement
                acceptedPrivacyPolicy: $acceptedPrivacyPolicy
            }
        ) {
            result
        }
    }
`;

export const useRegisterMutation = errors => {
    let history = useHistory();

    const [mutation, mutationResults] = useMutation(signUpMutationGQL, {
        update(proxy, result) {
            history.push('/grazie');
        },
        onError(err) {
            if (err.graphQLErrors[0].message === 'validation_email_not_unique') {
                // eslint-disable-next-line no-param-reassign
                errors.email = 'Questa email risulta essere già in uso';
            }
            if (err.graphQLErrors[0].message === 'validation_email_invalid') {
                errors.email = "  L'email inserita non è valida";
            }
        },
    });

    const register = (
        email,
        firstName,
        lastName,
        password,
        phone,
        profession,
        company,
        certifications,
        personalInfoAgreement,
        acceptedPrivacyPolicy,
        ...errors
    ) => {
        return mutation({
            variables: {
                email,
                firstName,
                lastName,
                password,
                phone,
                profession,
                company,
                certifications,
                personalInfoAgreement,
                acceptedPrivacyPolicy,
            },
            ...errors,
        });
    };
    return [register, mutationResults, errors];
};
