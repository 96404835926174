import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Image, Box, Spinner } from '@chakra-ui/core';
import { SliderDots, LogoLink } from '../../components/common/styles';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';
import { usePartnersGQLQuery } from '../../partners/query/getPartners';
SwiperCore.use([Navigation, Pagination, Autoplay]);

const SliderExhibitors = ({ edition }) => {
    // const getYear = () => {
    //     return new Date().getFullYear();
    // };
    // let edition = getYear();
    const { loading, data, error } = usePartnersGQLQuery({ editionName: edition });

    if (loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.500' />
            </Box>
        );
    if (error) return <p>ERROR</p>;
    if (!data) return <p>Not found</p>;

    const partners = data.getPartners;
    return (
        <>
            <Swiper
                spaceBetween={16}
                slidesPerView={1}
                loop={false}
                autoHeight={true}
                autoplay={{
                    delay: 7000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    el: '.c-pagination',
                    type: 'bullets',
                    clickable: 'true',
                }}
                breakpoints={{
                    375: {
                        slidesPerView: 1,
                        spaceBetween: 16,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 32,
                    },
                    1024: {
                        slidesPerView: 2,
                        spaceBetween: 32,
                    },
                    1280: {
                        slidesPerView: 3,
                        spaceBetween: 32,
                    },
                    1650: {
                        slidesPerView: 4,
                        spaceBetween: 32,
                    },
                }}
            >
                {partners && (
                    <>
                        {partners.map(partner => (
                            <SwiperSlide key={partner.id}>
                                <a
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href={partner.website1}
                                >
                                    <LogoLink as={Box}>
                                        <Image loading='lazy' src={partner.logoUrl} alt='xxxxx' />
                                    </LogoLink>
                                </a>
                            </SwiperSlide>
                        ))}
                    </>
                )}
            </Swiper>
            {/* <button className='prev'>prev</button>
            <button className='next'>next</button> */}
            <SliderDots className='c-pagination' />
        </>
    );
};

export default SliderExhibitors;
