import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Heading, Text, Link, Image, Stack, Badge, Box } from '@chakra-ui/core';

import { PlayIcon } from '../common/icons';

import { CardContainer, CardThumb, CardThumbData, CardThumbContent, CardThumbPlay } from './styles';
import Moment from 'react-moment';
import 'moment/locale/it';

class Card extends Component {
    render() {
        const {
            isLive,
            willbeLive,
            videoDate,
            videoTime,
            title,
            subtitle,
            text,
            videoUrl,
            videoThumb,
            author,
            authorUrl,
            event,
        } = this.props;
        return (
            <CardContainer>
                <CardThumb willbeLive={willbeLive}>
                    <Image loading='lazy' src={event.coverPhotoUrl} alt={title} />
                    {!willbeLive ? (
                        <CardThumbPlay
                            as={RouterLink}
                            as={RouterLink}
                            to={`/single-programma-preview/${event.id}`}
                        >
                            <PlayIcon />
                        </CardThumbPlay>
                    ) : null}
                </CardThumb>
                <Stack direction='row' align='baseline' spacing={3} mt='4'>
                    {/* {isLive ? <>Live</> : willbeLive ? <>Will be live</> : <>was Live</>} */}
                    {isLive ? (
                        <>
                            <Badge variant='brand'>Live now</Badge>
                            {event.startAtTimestamp ? (
                                <Text as='span' fontSize='md' fontWeight='500'>
                                    <Moment unix format='D/MM'>
                                        {event.startAtTimestamp}
                                    </Moment>
                                </Text>
                            ) : null}
                            {event.startAtTimestamp ? (
                                <Text as='span' fontSize='sm' color='brand.500'>
                                    <Moment unix format='hh:mm A'>
                                        {event.startAtTimestamp}
                                    </Moment>
                                </Text>
                            ) : null}
                        </>
                    ) : willbeLive ? (
                        <>
                            <Text as='span' fontSize='sm'>
                                In diretta il{' '}
                                <Moment unix format='D/MM'>
                                    {event.startAtTimestamp}
                                </Moment>{' '}
                                <Text as='span' color='brand.300'>
                                    ore{' '}
                                    <Moment unix format='hh:mm A'>
                                        {event.startAtTimestamp}
                                    </Moment>
                                </Text>
                            </Text>
                        </>
                    ) : (
                        <>
                            <Badge variant='brandOutline'>Replay</Badge>
                            {videoDate ? (
                                <Text as='span' fontSize='md'>
                                    Rivedi la diretta del{' '}
                                    <Moment unix format='D/MM'>
                                        {event.startAtTimestamp}
                                    </Moment>
                                </Text>
                            ) : null}
                        </>
                    )}
                </Stack>
                {!willbeLive ? (
                    <>
                        <Heading as='h3' size='lg' mt='2' mb='2'>
                            <Link
                                as={RouterLink}
                                to={`/single-programma/${event.id}`}
                                color='brand.500'
                                className='lines-2-xxx'
                            >
                                {event.name}
                            </Link>
                        </Heading>
                        <Text fontSize='md' mb='2'>
                            {event.eventSpeakers.length > 0 && (
                                <>
                                    {subtitle}{' '}
                                    <>
                                        {event.eventSpeakers.map(speaker => (
                                            <Text
                                                key={speaker.id}
                                                as='span'
                                                color='brand.500'
                                                mr='2'
                                            >
                                                {speaker.name}
                                            </Text>
                                        ))}
                                    </>
                                </>
                            )}
                        </Text>
                        <Text
                            fontSize='sm'
                            className='lines-2'
                            dangerouslySetInnerHTML={{ __html: event.excerpt }}
                        />
                    </>
                ) : (
                    <>
                        <Heading as='h3' size='lg' mt='2' mb='2'>
                            <Box
                                as={Link}
                                href={`/single-programma/${event.id}`}
                                color='brand.500'
                                className='lines-2-xxx'
                            >
                                {event.name}
                            </Box>
                        </Heading>
                        <Text fontSize='md' mb='2'>
                            {event.eventSpeakers.length > 0 && (
                                <>
                                    {subtitle}{' '}
                                    <>
                                        {event.eventSpeakers.map(speaker => (
                                            <Text
                                                key={speaker.id}
                                                as='span'
                                                color='brand.500'
                                                mr='2'
                                            >
                                                {speaker.name}
                                            </Text>
                                        ))}
                                    </>
                                </>
                            )}
                        </Text>
                        <Text
                            fontSize='sm'
                            className='lines-2'
                            dangerouslySetInnerHTML={{ __html: event.excerpt }}
                        />
                    </>
                )}
            </CardContainer>
        );
    }
}

Card.propTypes = {
    videoUrl: PropTypes.string,
    videoThumb: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    text: PropTypes.string,
    author: PropTypes.string,
    authorUrl: PropTypes.string,
    isLive: PropTypes.bool,
    willbeLive: PropTypes.bool,
    videoDate: PropTypes.string,
    videoTime: PropTypes.string,
    handleVideoSchedule: function (props) {
        if (!props['isLive']) {
            if (!props['videoDate'] || !props['videoTime']) {
                return new Error(
                    'Please provide a date and time of video! (videoDate and videoTime) ',
                );
            }
        } else {
            if (!props['videoTime']) {
                return new Error('Please provide a time of live video! (videoTime) ');
            }
        }
    },
};

Card.defaultProps = {
    // videoUrl: './',
    // videoThumb: 'https://picsum.photos/640/640',
    // title: 'Lorem ipsum',
    subtitle: 'a cura di',
    // text:
    //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut diam quam nulla porttitor massa id. Urna nunc id cursus metus aliquam eleifend mi in nulla. Mauris commodo quis imperdiet massa. Vitae suscipit tellus mauris a diam maecenas.',
    // author: 'Sandro Pertini',
    // authorUrl: './',
};

export default Card;
