import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Heading, Text, Link } from '@chakra-ui/core';
import { SliderDots } from '../../components/common/styles';
import VideoThumb from '../../components/common/videoThumb';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Pagination]);

const SliderEvents = () => {
    return (
        <>
            <Swiper
                spaceBetween={16}
                slidesPerView={1}
                loop={false}
                autoHeight={true}
                pagination={{
                    el: '.c-pagination',
                    type: 'bullets',
                    clickable: 'true',
                }}
                breakpoints={{
                    375: {
                        slidesPerView: 1,
                        spaceBetween: 16,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 32,
                    },
                    1024: {
                        slidesPerView: 2,
                        spaceBetween: 32,
                    },
                    1280: {
                        slidesPerView: 3,
                        spaceBetween: 32,
                    },
                }}
            >
                <SwiperSlide>
                    <VideoThumb
                        isSimpleVideo
                        isBig={false}
                        videoUrl='/xxxxx'
                        imgUrl='https://picsum.photos/640/480'
                    />
                    <Heading as='h3' size='lg' my='2'>
                        <Link as={RouterLink} to='/xxxxx' color='brand.500' className='lines-2-xxx'>
                            I cambiamenti incidono sulla salute delle persone sotto sulla salute
                            delle persone sotto sulla salute delle persone sotto...
                        </Link>
                    </Heading>
                    <Text as='p' className='lines-3' fontSize='sm'>
                        Education is the bedrock of a just society in the post-COVID world As the
                        COVID-19 crisis may lead to an erosion of trust in public services and the
                        COVID-19 crisis may lead to an erosion of trust in public services and
                    </Text>
                </SwiperSlide>
                <SwiperSlide>
                    <VideoThumb
                        isSimpleVideo
                        isBig={false}
                        videoUrl='/xxxxx'
                        imgUrl='https://picsum.photos/640/480'
                    />
                    <Heading as='h3' size='lg' my='2'>
                        <Link as={RouterLink} to='/xxxxx' color='brand.500' className='lines-2-xxx'>
                            I cambiamenti incidono sulla salute delle persone sotto sulla salute
                            delle persone sotto sulla salute delle persone sotto...
                        </Link>
                    </Heading>
                    <Text as='p' className='lines-3' fontSize='sm'>
                        Education is the bedrock of a just society in the post-COVID world As the
                        COVID-19 crisis may lead to an erosion of trust in public services and the
                        COVID-19 crisis may lead to an erosion of trust in public services and
                    </Text>
                </SwiperSlide>
                <SwiperSlide>
                    <VideoThumb
                        isSimpleVideo
                        isBig={false}
                        videoUrl='/xxxxx'
                        imgUrl='https://picsum.photos/640/480'
                    />
                    <Heading as='h3' size='lg' my='2'>
                        <Link as={RouterLink} to='/xxxxx' color='brand.500' className='lines-2-xxx'>
                            I cambiamenti incidono sulla salute delle persone sotto sulla salute
                            delle persone sotto sulla salute delle persone sotto
                        </Link>
                    </Heading>
                    <Text as='p' className='lines-3' fontSize='sm'>
                        Education is the bedrock of a just society in the post-COVID world As the
                        COVID-19 crisis may lead to an erosion of trust in public services and the
                        COVID-19 crisis may lead to an erosion of trust in public services and
                    </Text>
                </SwiperSlide>
                <SwiperSlide>
                    <VideoThumb
                        isSimpleVideo
                        isBig={false}
                        videoUrl='/xxxxx'
                        imgUrl='https://picsum.photos/640/480'
                    />
                    <Heading as='h3' size='lg' my='2'>
                        <Link as={RouterLink} to='/xxxxx' color='brand.500' className='lines-2-xxx'>
                            I cambiamenti incidono sulla salute delle persone sotto sulla salute
                            delle persone sotto sulla salute delle persone sotto...
                        </Link>
                    </Heading>
                    <Text as='p' className='lines-3' fontSize='sm'>
                        Education is the bedrock of a just society in the post-COVID world As the
                        COVID-19 crisis may lead to an erosion of trust in public services and the
                        COVID-19 crisis may lead to an erosion of trust in public services and
                    </Text>
                </SwiperSlide>
            </Swiper>
            <SliderDots className='c-pagination' />
        </>
    );
};

export default SliderEvents;
