const config = {
    region: 'eu',
    // appID: process.env.COMET_CHAT_API_ID,
    // apiKey: process.env.COMET_CHAT_API_KEY,
    //old credentials
    // appID: '23683f96d5cb719',
    // apiKey: '0c9949f0db7a869082bdac8c1b73ed6790bb1c28',
    appID: '294036e428d69fc',
    apiKey: '864fb7a1e210ed267801f964097689b71e7a46cc',
};

export default config;
