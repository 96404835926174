export const CheckboxReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_CHECKBOX':
            return [
                ...state,
                {
                    data: action.checkbox.data,
                    name: action.checkbox.name,
                    type: action.checkbox.type,
                },
            ];
        case 'REMOVE_CHECKBOX':
            return state.filter(item => {
                if (item.type !== action.checkbox.type) {
                    return true;
                }
                return item.name !== action.checkbox.name;
            });
        default:
            return state;
    }
};
