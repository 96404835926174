import { gql, useQuery } from '@apollo/client';

const faqQueryGQL = gql`
    query GetFaq($term: String) {
        getFaq(term: $term) {
            faqCategory {
                name
            }
            question
            answer
        }
    }
`;

export const useFaqQuery = ({ term }) => {
    return useQuery(faqQueryGQL, {
        variables: { term },
        fetchPolicy: 'cache-and-network',
    });
};
