import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Link, Image, Spinner, Box } from '@chakra-ui/core';
import { HeaderContainer, HeaderGrid, HeaderLogo } from './styles';

import Navigation from './components/navigation';
import Hamburger from './components/hamburger';
import { useGetPortalSettingsQuery } from '../../query/getPortalSettings';

const Header = props => {
    const portalData = useGetPortalSettingsQuery();
    if (portalData.loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.500' />
            </Box>
        );
    if (portalData.error) return <p>ERROR</p>;
    if (!portalData.data) return <p>Not found</p>;
    const settings = portalData.data.getPortalSettings;

    return (
        <HeaderContainer bgBrand={props.bgBrand}>
            <Container maxWidth='1752px'>
                <HeaderGrid>
                    <HeaderLogo>
                        <Link className='is-mobile' as={RouterLink} to='/'>
                            <Image src={settings.mobileLogoUrl} alt='Logo' />
                        </Link>
                        <Link className='is-desktop' as={RouterLink} to='/'>
                            <Image src={settings.logoUrl} alt='Logo' />
                        </Link>
                    </HeaderLogo>
                    <Navigation edition={settings.edition.name} />
                    <Hamburger />
                </HeaderGrid>
            </Container>
        </HeaderContainer>
    );
};

export default Header;
