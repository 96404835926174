import { gql, useQuery } from '@apollo/client';

const getInterestedInEventsQueryGQL = gql`
    query getInterestedInEvents($page: Int, $limit: Int) {
        getInterestedInEvents(page: $page, limit: $limit) {
            eventDays {
                date
                events {
                    id
                    name
                    status
                    statusAsString
                    eventCategory {
                        id
                        name
                    }
                    startAtTimestamp
                    endAtTimestamp
                    edition
                    location
                    tag
                    excerpt
                    description
                    liveVideoUrl
                    eventVideos {
                        id
                        name
                        videoUrl
                        description
                    }
                    coverPhotoUrl
                    eventSpeakers {
                        id
                        name
                        description
                        photoUrl
                    }
                    eventComitatos {
                        id
                        name
                    }
                    eventFaculties {
                        id
                        name
                    }
                    eventFiles {
                        name
                        downloadUrl
                        fileSize
                    }
                    isInMyAgenda
                    isInInterestedIn
                }
            }
            totalItemCount
        }
    }
`;

export const useGetInterestedInEventsGQLQuery = ({ page, limit }) => {
    return useQuery(getInterestedInEventsQueryGQL, {
        variables: { page, limit },
        //fetchPolicy: 'network-and-cache',
    });
};

export default getInterestedInEventsQueryGQL;
