import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Avatar,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Button,
    Box,
    Spinner,
    Link,
} from '@chakra-ui/core';
import { useUserQuery } from '../config/userQuery';
import { useLogout } from '../config/auth';
import { AuthContext } from '../context/auth';
import { ChevronDownIcon } from '@chakra-ui/icons';
import AgendaDrawer from '../user/components/agendaDrawer';

export const UserAvatar = () => {
    const userData = useUserQuery();
    const logout = useLogout();
    const { user } = useContext(AuthContext);

    return (
        <div>
            {user && (
                <>
                    <Menu placement='bottom-end' autoSelect={false}>
                        <MenuButton
                            as={Button}
                            p={0}
                            height='100%'
                            bg='transparent'
                            borderWidth='0'
                            _hover={{ bg: 'transparent' }}
                            _expanded={{ bg: 'transparent' }}
                            _focus={{ outline: 0, boxShadow: 'none' }}
                            rightIcon={<ChevronDownIcon />}
                        >
                            <Avatar
                                size='sm'
                                name={`${user.first_name} ${user.last_name}`}
                                bg='white'
                                color='brand.500'
                            />
                        </MenuButton>
                        <MenuList bg='black' minWidth='240px' maxWidth='320px' border='none'>
                            <AgendaDrawer />

                            <MenuItem
                                as={RouterLink}
                                to={`/profilo`}
                                _focus={{ bg: 'brand.500' }}
                                _hover={{ bg: 'brand.500' }}
                            >
                                {' '}
                                Impostazioni del profilo
                            </MenuItem>

                            <MenuItem
                                _focus={{ bg: 'brand.500' }}
                                _hover={{ bg: 'brand.500' }}
                                onClick={logout}
                            >
                                {' '}
                                Log out
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </>
            )}
        </div>
    );
};
