//import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useToast } from '@chakra-ui/core';

const changePasswordMutationGQL = gql`
    mutation changePassword($password: String!, $newPassword: String!, $confirmPassword: String!) {
        changePassword(
            input: {
                password: $password
                newPassword: $newPassword
                confirmPassword: $confirmPassword
            }
        ) {
            result
        }
    }
`;

export const useChangePasswordMutation = errors => {
    let toast = useToast();

    const [mutation, mutationResults] = useMutation(changePasswordMutationGQL, {
        onCompleted: data => {
            toast({
                position: 'bottom-left',
                title: 'Password changed.',
                description: 'password is changed successfuly.',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
        },
        onError(err) {
            if (err.graphQLErrors[0].message === 'invalid_credentials') {
                // eslint-disable-next-line no-param-reassign

                errors.password = 'Questa risulta essere già in uso';
            }

            if (err.graphQLErrors[0].message === 'Response not successful') {
                errors.password = 'Questa risulta essere già in uso';
            }
        },
    });

    const changePassword = (
        password,
        newPassword,
        confirmPassword,

        ...errors
    ) => {
        return mutation({
            variables: {
                password,
                newPassword,
                confirmPassword,
            },
            ...errors,
        });
    };
    return [changePassword, mutationResults, errors];
};
