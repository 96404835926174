import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Text, Link, background } from '@chakra-ui/core';
import { NotificationSingle } from '../../lib/styles';

const Notification = ({notification}) => {
    return (
        <>
       {notification.isRead === true ?
            <NotificationSingle>
                Il tuo evento{' '}
                <Text display="inline" color="brand.500">
                    {notification.description}
                </Text>{' '}
            </NotificationSingle>
            :
            <NotificationSingle isUnread>
            Il tuo evento{' '}
            <Text display="inline" color="brand.500">
                {notification.description}
            </Text>{' '}
            </NotificationSingle>
        }
            {/* <NotificationSingle isUnread>
                L'evento Il tuo evento{' '}
                <Link as={RouterLink} to='/xxxxx'>
                    "Event name"
                </Link>{' '}
                a cui sei interessato inizierà domani{' '}
                <Text as='span' color='grey.300'>
                    01/10
                </Text>{' '}
                alle{' '}
                <Text as='span' color='grey.300'>
                    16:30
                </Text>
            </NotificationSingle>
            <NotificationSingle>
                Il tuo evento{' '}
                <Link as={RouterLink} to='/xxxxx'>
                    "La sanità digitale in italia, un processo partecipativo e di condivisione"
                </Link>{' '}
                inizierà a{' '}
                <Text as='span' color='grey.300'>
                    16:30
                </Text>{' '}
                <Text as='span' color='grey.300'>
                    01/10
                </Text>{' '}
            </NotificationSingle>
            <NotificationSingle>
                L'evento Il tuo evento{' '}
                <Link as={RouterLink} to='/xxxxx'>
                    "Event name"
                </Link>{' '}
                a cui sei interessato inizierà domani{' '}
                <Text as='span' color='grey.300'>
                    01/10
                </Text>{' '}
                alle{' '}
                <Text as='span' color='grey.300'>
                    16:30
                </Text>
            </NotificationSingle> */}
        </>
    );
};
export default Notification;
