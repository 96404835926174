import { gql, useQuery } from '@apollo/client';

const getContactPersonsQueryGQL = gql`
    query {
        getContactPersons {
            id
            name
            description
            email
            address
            phone
            partnerOrder
        }
    }
`;

export const useContactPersonsQuery = () => {
    return useQuery(getContactPersonsQueryGQL);
};
