import React from 'react';
import { useProgrammaFileQueryGQL } from '../queries/programmaFile';
import { HStack, ButtonGroup, Text, Link, Spinner, Box, IconButton, Spacer } from '@chakra-ui/core';
import { IconDownload } from '../../components/common/icons';
import { Link as RouterLink } from 'react-router-dom';

const ProgrammaFile = () => {
    const fileData = useProgrammaFileQueryGQL();

    if (fileData.loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.500' />
            </Box>
        );
    if (fileData.error) return <p>ERROR</p>;
    if (!fileData.data) return <p>Not found</p>;
    const files = fileData.data.getProgrammaFile;
    return (
        <>
            {files.map(file => (
                <HStack key={file.id} spacing='12px' align='center'>
                    <Link isExternal href={file.downloadUrl}>
                        <IconButton
                            bg='grey.100'
                            _hover={{ bg: 'brand.500', color: 'white' }}
                            color='brand.700'
                            aria-label='Download'
                            icon={<IconDownload />}
                        />
                    </Link>
                    <Link isExternal href={file.downloadUrl} lineHeight='1.3'>
                        <Text as='div' fontSize='lg'>
                            {file.name}
                        </Text>
                        <Text as='div' color='brand.700' fontSize='sm'>
                            {file.fileSize}
                        </Text>
                    </Link>
                </HStack>
            ))}

            <Spacer />
        </>
    );
};

export default ProgrammaFile;
