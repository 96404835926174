import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image } from '@chakra-ui/core';

import { ThumbContainer, ThumbDate } from '../../components/common/styles';

class BlogThumb extends Component {
    render() {
        const { isBig, imgUrl, title, date, month, year } = this.props;
        return (
            <ThumbContainer>
                <Image loading='lazy' src={imgUrl} alt={title} />
                <ThumbDate isBig={isBig}>
                    <big>{date}</big>
                    <small>
                        {month} '{year}
                    </small>
                </ThumbDate>
            </ThumbContainer>
        );
    }
}

BlogThumb.propTypes = {
    isBig: PropTypes.bool,
    imgUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
};
BlogThumb.defaultProps = {
    isBig: false,
    imgUrl: 'https://picsum.photos/640/640',
    title: 'Lorem ipsum',
    date: '06',
    month: 'Ago',
    year: '20',
};

export default BlogThumb;
