const { body } = document;
/*---------------------------------------
    Sticky header
---------------------------------------*/
(function () {
    window.addEventListener(
        'scroll',
        function () {
            if (this.scrollY > 64) {
                body.classList.add('is-stickyheader');
            } else {
                body.classList.remove('is-stickyheader');
            }
        },
        false,
    );
})();
