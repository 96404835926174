import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Image, Container, Heading, Text, Badge, Link, Stack } from '@chakra-ui/core';
import { HeroSlideBg, HeroSlideContent } from '../styles';
import Moment from 'react-moment';
import 'moment/locale/it';

const SliderEvents = ({ event }) => {
    return (
        <>
            <HeroSlideBg $bg='black'>
                <Image src={event.coverPhotoUrl} alt='xxxxx' />
            </HeroSlideBg>
            <HeroSlideContent
                data-swiper-parallax='-80%'
                data-swiper-parallax-duration='1500'
                data-swiper-parallax-opacity='0'
            >
                <Container maxWidth='1752px'>
                    <Badge variant='whiteOutline'>{event.eventCategory.name}</Badge>
                    <Heading as='h1' size='2xl' my='4' textTransform='uppercase'>
                        {event.name}
                    </Heading>
                    <Text as='p' maxW='55ch' mb='4' fontSize='2xl'>
                        {event.excerpt}
                    </Text>
                    <Text as='p' maxW='55ch' fontSize='xl' mb='8'>
                        a cura di{' '}
                        {event.eventSpeakers && (
                            <>
                                {event.eventSpeakers.map(speaker => (
                                    <Link key={speaker.id} as='span' color='brand.500' mr='2'>
                                        {speaker.name}
                                    </Link>
                                ))}
                            </>
                        )}
                    </Text>
                    <Stack direction='row' align='center' spacing='2'>
                        <Badge variant='brandLarge'>Live</Badge>
                        <Text as='span' fontSize='2xl' fontWeight='500'>
                            <Moment unix format='D/MM'>
                                {event.startAtTimestamp}
                            </Moment>
                        </Text>
                        <Text as='span' fontSize='2xl' fontWeight='500' color='brand.500'>
                            ore{' '}
                            <Moment unix format='hh:mm A'>
                                {event.startAtTimestamp}
                            </Moment>
                        </Text>
                    </Stack>
                </Container>
            </HeroSlideContent>
        </>
    );
};

SliderEvents.propTypes = {};

export default SliderEvents;
