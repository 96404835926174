import { useMutation, gql } from '@apollo/client';
import eventsListQueryGQL from '../queries/eventsList';
import singleEventQueryGQL from '../queries/singleEvent';
import getInterestedInEventsQueryGQL from '../../user/query/getInterestedInEvents';

export const addEventToInterestedInMutationGQL = gql`
    mutation addEventToInterestedIn($id: Int!) {
        addEventToInterestedIn(id: $id) {
            result
        }
    }
`;

export const useAddEventInterestedInMutation = () => {
    const [mutation, mutationResults] = useMutation(addEventToInterestedInMutationGQL, {
        update(proxy, result) {
            // debugger;
        },
        // onCompleted: data => {
        //     debugger;

        // },
        onError(err) {
            // debugger;
        },
    });
    const InterestedIn = id => {
        return mutation({
            variables: {
                id,
            },
            refetchQueries: [
                {
                    query: eventsListQueryGQL,
                },
                {
                    query: getInterestedInEventsQueryGQL,
                    variables: { page: 1, limit: 20 },
                },
            ],
        });
    };
    return [InterestedIn, mutationResults];
};
