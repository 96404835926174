//import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useAuthToken } from '../../config/auth';
import { useHistory, useLocation } from 'react-router-dom';

export const loginMutationGQL = gql`
    mutation login($email: String!, $password: String!) {
        login(input: { email: $email, password: $password }) {
            token
        }
    }
`;

export const useLoginMutation = errors => {
    const [_, setAuthToken, removeAuthtoken] = useAuthToken();
    let history = useHistory();
    let location = useLocation();
    const getYear = () => {
        return new Date().getFullYear();
    };
    let edition = getYear();

    const [mutation, mutationResults] = useMutation(loginMutationGQL, {
        onCompleted: data => {
            setAuthToken(data.login.token);

            if (location.state && location.state.next) {
                return history.push(location.state.next);
            }

            history.push(`/programma/${edition}`);
        },
        onError(err) {
            // console.log(err.graphQLErrors);
            if (err.graphQLErrors[0].message === 'login_failed') {
                // eslint-disable-next-line no-param-reassign
                errors.password = 'Per favore controlla nuovamente le credenziali inserite';
                errors.email = '';
            }
            if (err.graphQLErrors[0].message === 'validation_email_invalid') {
                errors.email = "  L'email inserita non è valida";
            }
            if (err.graphQLErrors[0].message === 'user_disabled') {
                errors.email = '';
                errors.password =
                    'Non hai ativatto il tuo profilo, per favore controlla la tua casella di posta elettronica';
            }
        },
    });

    // full login function
    const login = (email, password, ...errors) => {
        removeAuthtoken();
        return mutation({
            variables: {
                email,
                password,
            },
            ...errors,
        });
    };
    return [login, mutationResults, errors];
};
