import React from 'react';
import { Image, Container, Box, Spinner } from '@chakra-ui/core';
import { HeroContainer, HeroSlideFirstContent, HeroContainerPagination } from '../styles';
import { SliderDotsVert } from '../../components/common/styles';

import heroSlide from '../../images/bg-hero-01.png';
import heroSlideMobile from '../../images/bg-hero-01-mobile.png';
import heroSlide2 from '../../images/bg-hero-02.png';
import heroSlideMobile2 from '../../images/bg-hero-02-M.png';
import heroSlide3 from '../../images/bg-hero-03.png';
import heroSlideMobile3 from '../../images/bg-hero-03-M.png';
import heroSlide4 from '../../images/bg-hero-04.png';
import heroSlideMobile4 from '../../images/bg-hero-04-M.png';
import heroSlide5 from '../../images/bg-hero-05.png';
import heroSlideMobile5 from '../../images/bg-hero-05-M.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay, Parallax } from 'swiper';
import { useEventsListQueryGQLQuery } from '../../events/queries/eventsList';
import SliderEvents from './sliderEvents';
import 'swiper/swiper-bundle.css';
import { useMainSliderGQLQuery } from '../query/getMainSlider';
SwiperCore.use([Navigation, Pagination, Autoplay, Parallax]);

const SliderHero = () => {
    const eventsData = useEventsListQueryGQLQuery({ input: { eventShowIn: ['2'] } });
    const sliderData = useMainSliderGQLQuery();

    if (eventsData.loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.500' />
            </Box>
        );
    if (eventsData.error) return <p>ERROR</p>;
    if (!eventsData.data) return <p>Not found</p>;
    const eventsList = eventsData.data.getEvents.eventDays;

    if (sliderData.loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.500' />
            </Box>
        );
    if (sliderData.error) return <p>ERROR</p>;
    if (!sliderData.data) return <p>Not found</p>;
    const sliders = sliderData.data.getMainSlider;

    return (
        <HeroContainer>
            <Swiper
                spaceBetween={0}
                slidesPerView={1}
                direction='vertical'
                speed={1000}
                loop={true}
                allowTouchMove={false}
                parallax={true}
                autoplay={{
                    delay: 7000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    el: '.c-pagination-hero',
                    type: 'bullets',
                    clickable: 'true',
                }}
            >
                <>
                    {sliders.map(slider => {
                        return (
                            <SwiperSlide>
                                <HeroSlideFirstContent>
                                    <Container width='auto' maxWidth='1752px' textAlign='center'>
                                        <Image
                                            src={slider.mobilePhotoUrl}
                                            alt='Slide'
                                            m='auto'
                                            className='isMobile'
                                        />
                                        <Image
                                            src={slider.photoUrl}
                                            alt='Slide'
                                            m='auto'
                                            className='isDesktop'
                                        />
                                    </Container>
                                </HeroSlideFirstContent>
                            </SwiperSlide>
                        );
                    })}
                </>
                {/*<SwiperSlide>
                    <HeroSlideFirstContent>
                        <Container maxWidth='1752px'>
                            <Image
                                src={heroSlideMobile}
                                alt='Slide'
                                m='auto'
                                className='isMobile'
                            />
                            <Image src={heroSlide} alt='Slide' m='auto' className='isDesktop' />
                        </Container>
                    </HeroSlideFirstContent>
                </SwiperSlide>
                 <SwiperSlide>
                    <HeroSlideFirstContent>
                        <Container maxWidth='1752px'>
                            <Image
                                src={heroSlideMobile2}
                                alt='Slide'
                                m='auto'
                                className='isMobile'
                            />
                            <Image src={heroSlide2} alt='Slide' m='auto' className='isDesktop' />
                        </Container>
                    </HeroSlideFirstContent>
                </SwiperSlide>
                <SwiperSlide>
                    <HeroSlideFirstContent>
                        <Container maxWidth='1752px'>
                            <Image
                                src={heroSlideMobile3}
                                alt='Slide'
                                m='auto'
                                className='isMobile'
                            />
                            <Image src={heroSlide3} alt='Slide' m='auto' className='isDesktop' />
                        </Container>
                    </HeroSlideFirstContent>
                </SwiperSlide>
                <SwiperSlide>
                    <HeroSlideFirstContent>
                        <Container maxWidth='1752px'>
                            <Image
                                src={heroSlideMobile4}
                                alt='Slide'
                                m='auto'
                                className='isMobile'
                            />
                            <Image src={heroSlide4} alt='Slide' m='auto' className='isDesktop' />
                        </Container>
                    </HeroSlideFirstContent>
                </SwiperSlide>
                <SwiperSlide>
                    <HeroSlideFirstContent>
                        <Container maxWidth='1752px'>
                            <Image
                                src={heroSlideMobile5}
                                alt='Slide'
                                m='auto'
                                className='isMobile'
                            />
                            <Image src={heroSlide5} alt='Slide' m='auto' className='isDesktop' />
                        </Container>
                    </HeroSlideFirstContent>
                </SwiperSlide> */}
                {eventsList && (
                    <>
                        {eventsList.map(({ events }, index) => {
                            return (
                                <>
                                    {events.map(event => {
                                        return (
                                            <SwiperSlide>
                                                <SliderEvents event={event} key={event.id} />
                                            </SwiperSlide>
                                        );
                                    })}
                                </>
                            );
                        })}
                    </>
                )}
            </Swiper>
            <HeroContainerPagination>
                <Container maxWidth='1752px' d='flex' justifyContent='flex-end'>
                    <SliderDotsVert className='c-pagination-hero' />
                </Container>
            </HeroContainerPagination>
        </HeroContainer>
    );
};

export default SliderHero;
