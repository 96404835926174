import { gql, useQuery } from '@apollo/client';

const hackatonQueryGQL = gql`
    query GetHackathon($editionName: String) {
        getHackathon(editionName: $editionName) {
            id
            mainLogoUrl
            leftColumnMainImageUrl
            rightColumnMainImageUrl
            description
            subscribeUrl
            prizeValue
            prizeDescription
            hackathonChallenges {
                title
                description
            }
            hackathonTimelineItems {
                startAtTimestamp
                title
                description
            }
            hackathonPastLinks {
                title
                url
            }
            hackathonPresentationVideo {
                title
                description
                url
            }
            hackathonVideos {
                title
                description
                url
            }
            hackathonImages {
                title
                description
                photoUrl
            }
            hackathonExperts {
                name
                description
            }
            hackathonJurys {
                name
                description
                photoUrl
            }
            hackathonFiles {
                name
                fileUrl
            }
            hackathonSponsors {
                title
                description
                photoUrl
                url
            }
            hackathonDocuments {
                name
                fileUrl
            }
            hackathonMediaPartners {
                title
                description
                photoUrl
                url
            }
            isInMyAgenda
        }
    }
`;

export const useHackatonGQLQuery = ({ editionName }) =>
    useQuery(hackatonQueryGQL, { variables: { editionName } });
