import { gql, useQuery } from '@apollo/client';

const sponsorsQueryGQL = gql`
    query getSponsors($editionName: String) {
        getSponsors(editionName: $editionName) {
            id
            name
            website1
            partnerOrder
            logoUrl
        }
    }
`;

export const useSponsorsGQLQuery = ({ editionName }) => {
    return useQuery(sponsorsQueryGQL, {
        variables: { editionName },
    });
};
