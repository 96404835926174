import React, { useRef } from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Button,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    MenuItem,
} from '@chakra-ui/core';
import { useForm } from 'react-hook-form';
import AgendaList from './myAgendaList';
import InterestedInList from './interestedInList';

const AgendaDrawer = ({ agendas, interests }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnMyAgenda = useRef();

    return (
        <>
            <MenuItem
                _focus={{ bg: 'brand.500' }}
                _hover={{ bg: 'brand.500' }}
                ref={btnMyAgenda}
                onClick={onOpen}
                variant='ghost'
            >
                La mia agenda
            </MenuItem>

            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                trapFocus={true}
                initialFocusRef={btnMyAgenda}
                agendas={agendas}
                interests={interests}
                blockScrollOnMount={false}
            >
                <DrawerOverlay zIndex='100000' />
                <DrawerContent zIndex='100000000'>
                    <DrawerCloseButton />
                    <DrawerHeader fontFamily='heading' fontWeight='400' textTransform='uppercase'>
                        La Mia Agenda
                    </DrawerHeader>

                    <DrawerBody>
                        <Tabs>
                            <TabList>
                                <Tab>La mia agenda</Tab>
                                <Tab>Mi interessa</Tab>
                            </TabList>

                            <TabPanels>
                                <TabPanel px='0'>
                                    <AgendaList />
                                </TabPanel>
                                <TabPanel px='0'>
                                    <InterestedInList />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default AgendaDrawer;
