import { CometChat } from '@cometchat-pro/chat';
import { NotificationManager } from 'react-notifications';
import { MAIN_STUDIO } from '../chat/constants/group';
import config from '../config/cometChat';


export const createUid = (id, username) => {
    return id+username;
};

export const createUsername = (firstName, lastName) => {
    return `${firstName} ${lastName}`;
};

export const fetchPreviousChat = (selectedGroup, callback = null, limit = 30) => {
    let messagesRequest = new CometChat.MessagesRequestBuilder()
        .setGUID(selectedGroup)
        .setLimit(limit)
        .build();

    messagesRequest.fetchPrevious().then(
        messages => {
            callback(messages);
            // console.log('Message fetchPrevious', {messages});
            // debugger
        },
        error => {
            // NotificationManager.error('Message fetching failed with error');
            console.error('Message fetching failed with error:', error);
            // debugger
        },
    );
};

export const messageListener = (listenerKey = 'listener-key', callback) => {
    CometChat.removeMessageListener(listenerKey);

    CometChat.addMessageListener(
        listenerKey,
        new CometChat.MessageListener({
            onTextMessageReceived: message => {
                callback(message);
                // console.log('Incoming Message Log', {message});
                // debugger
            },
        }),
    );
};

export const sendMessage = (selectedGroup, message, callback) => {
    const textMessage = new CometChat.TextMessage(
        selectedGroup,
        message,
        CometChat.RECEIVER_TYPE.GROUP,
    );

    CometChat.sendMessage(textMessage).then(
        chatMessage => {
            callback(chatMessage);
            // NotificationManager.success('Message sent successfully!');
            // console.log('Message sent successfully:', message);
            // debugger;
        },
        error => {
            // NotificationManager.error('Message sending failed with error');
            console.error('Message sending failed with error:', error);
            // debugger;
        },
    );
};

export const registrationChat = (uid, username, success) => {

    let user = new CometChat.User(uid);
    user.setName(username);

    CometChat.createUser(user, config.apiKey).then(
        successUser => {
            success(successUser);

            // console.log('Successful Created user for Chat:', { User });
            // debugger
        },
        error => {
            // NotificationManager.error('Cannot register user for chat');
            console.error('error', error);
            // debugger;
        },
    );
};

export const chatLogin = (uid, username, success, errorCallback) => {
    CometChat.login(uid, config.apiKey).then(
        User => {
            success(User);
            // console.log('Successful Login on Chat:', { User });
        },
        error => {
            errorCallback(error);
            // NotificationManager.error('Please try again', 'Login Chat Failed');
            console.error('Login failed with ex¸ception:', { error });
        },
    );
};

// refactor: need to split "groupsRequest.fetchNext" and "CometChat.joinGroup"
export const joinGroup = (setUserCallback, groupType = 'public', groupName = MAIN_STUDIO) => {
    const groupsRequest = new CometChat.GroupsRequestBuilder()
        .setLimit(30)
        .joinedOnly(true)
        .build();

    groupsRequest.fetchNext().then(
        groupList => {
            const foundedGroup = groupList.find(({ guid }) => guid === groupName);

            if (!foundedGroup) {
                CometChat.joinGroup(groupName, groupType, '').then(
                    group => {
                        setUserCallback()
                        // NotificationManager.success('Chat group joined successfully');
                        // console.log('Group joined successfully:', group);
                        // debugger
                    },
                    error => {
                        // NotificationManager.error('Group joining failed with exception');
                        console.error('Group joining failed with exception:', error);
                        // debugger
                    },
                );
            }else {
                setUserCallback()
            }
        },
        error => {
            console.error('Groups list fetching failed with error', error);
        },
    );
};

