import styled from 'styled-components';
import { createBreakpoint } from 'styled-components-breakpoint';
import { styledBreakpoints } from '../style/styledBreakpoints';
import { customTheme } from '../style/customChakraTheme.js';
const breakpoint = createBreakpoint(styledBreakpoints);

export const MainContent = styled.div``;

export const MainLayout = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .mainContent {
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 1.2em auto;
            font-family: ${customTheme.fonts.heading};
            font-size: 24px;
            color: ${customTheme.colors.brand[500]};

            ${breakpoint('md')`
                font-size: 32px;
                margin: 54px auto 36px;
            `}
        }
        p,
        ul,
        ol {
            margin-bottom: 1rem;
            font-size: 1rem;

            ${breakpoint('md')`
                font-size: 18px;
            `}
        }
        ul,
        ol {
            margin-left: 1rem;
        }
        figure {
            margin: 1.2em auto;
            color: ${customTheme.colors.brand[700]};

            figcaption {
                font-size: 14px;
                font-weight: bold;
                margin-top: 12px;
            }

            img,
            figure,
            svg {
                border-radius: 5px;
                width: 100%;
            }

            ${breakpoint('md')`
                margin: 54px auto 36px;
            `}
        }

        &.columns-2 {
            ${breakpoint('md')`
                columns: 2;
                column-gap: 2rem;
            `}
        }
    }
    .mainContentSmall {
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 1.2em auto;
            font-family: ${customTheme.fonts.heading};
            font-size: 24px;
            color: ${customTheme.colors.brand[500]};
        }
        p,
        ul,
        ol {
            margin-bottom: 1rem;
            font-size: 0.875rem;
        }
        ul,
        ol {
            margin-left: 1rem;
        }
        figure {
            margin: 1.2em auto;
            color: ${customTheme.colors.brand[700]};

            figcaption {
                font-size: 14px;
                font-weight: bold;
                margin-top: 12px;
            }

            img,
            figure,
            svg {
                border-radius: 5px;
                width: 100%;
            }
        }
    }

    ${MainContent} {
        flex: 1;
        padding-top: 122px;

        @media screen and (min-width: 1650px) {
            padding-top: 116px;
        }
    }
`;
