import { useMutation, gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';

export const validateEmailMutationGQL = gql`
    mutation validateEmail($token: String!) {
        validateEmail(input: { token: $token }) {
            token
        }
    }
`;
export const useValidateEmailMutation = errors => {
    let history = useHistory();
    const [mutation, mutationResults] = useMutation(validateEmailMutationGQL, {
        onCompleted: data => {
            history.push('/login');
        },
        onError(err) {
            if (err.graphQLErrors[0].message === 'validation_email_invalid') {
                errors.email = "L'email inserita non è valida";
            }
            if (err.graphQLErrors[0].message === 'user_not_found') {
                errors.email = "L'email inserita non è valida";
            }
        },
    });
    const validateEmail = (token, ...errors) => {
        return mutation({
            variables: {
                token,
            },
            ...errors,
        });
    };
    return [validateEmail, mutationResults, errors];
};
