import { gql, useQuery } from '@apollo/client';

const getHeaderLinksQueryGQL = gql`
    query {
        getHeaderLinks {
            id
            name
            url
        }
    }
`;

export const useGetHeaderLinksQuery = () => {
    return useQuery(getHeaderLinksQueryGQL);
};
