import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { useAppApolloClient } from './config/apolloClient';
import { AuthGate } from './authGate';
import { AuthProvider } from './context/auth';
import {CometChat } from "@cometchat-pro/chat";
import config from "./config/cometChat";

function App() {
    try {
        const appSetting = new CometChat.AppSettingsBuilder()
            .subscribePresenceForAllUsers()
            .setRegion(config.region)
            .build();
        CometChat.init(config.appID, appSetting).then(
            () => {
                console.log('Initialization completed successfully');
                // You can now call login function.
            },
            error => {
                console.log('Initialization failed with error:', error);
                // Check the reason for error and take appropriate action.
            },
        );
    }catch (e) {
        console.log(e);
    }

    const apolloClient = useAppApolloClient();
    return (
        <ApolloProvider client={apolloClient}>
                <AuthProvider>
                    <AuthGate />
                </AuthProvider>
        </ApolloProvider>
    );
}
export default App;
