import { gql, useMutation } from '@apollo/client';

import eventsListQueryGQL from '../../events/queries/eventsList';
import getInterestedInEventsQueryGQL from '../query/getInterestedInEvents';
import singleEventQueryGQL from '../../events/queries/singleEvent';

export const removeEventFromInterestedMutationGQL = gql`
    mutation removeEventFromAgenda($id: Int!) {
        removeEventFromAgenda(id: $id) {
            result
        }
    }
`;

export const useRemoveEventFromInterestedMutation = () => {
    const [mutation, mutationResults] = useMutation(removeEventFromInterestedMutationGQL, {
        update(proxy, result) {},
        onCompleted: data => {},
        onError(err) {},
    });
    const removeEventFromInterested = id => {
        return mutation({
            variables: {
                id,
            },

            refetchQueries: [
                {
                    query: getInterestedInEventsQueryGQL,
                    variables: { page: 1, limit: 20 },
                },
                {
                    query: eventsListQueryGQL,
                    //variables: { page: 1, limit: 20 },
                },
                // {
                //     query: singleEventQueryGQL,
                //     variables: { id },
                // },
            ],
        });
    };
    return [removeEventFromInterested, mutationResults];
};
