import React, { useReducer, createContext, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { joinGroup, createUid, registrationChat, chatLogin, createUsername } from '../lib/groupChat';
const initialState = {
    user: null,
};

if (localStorage.getItem('user')) {
    const localUser = localStorage.getItem('user');

    if (localUser.exp < Date.now()) {
        localStorage.removeItem('user');
    } else {
        if (!initialState.user) {
            initialState.user = JSON.parse(localStorage.getItem('user'));
        }
    }
}

const AuthContext = createContext({
    user: null,
    login: userData => {},
    logoutStorage: () => {},
});

function authReducer(state, action) {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                user: action.payload,
            };
        case 'LOGOUT':
            return {
                ...state,
                user: null,
            };
        default:
            return state;
    }
}

function AuthProvider(props) {
    const [state, dispatch] = useReducer(authReducer, initialState);
    const [chatUser, setChatUser] = useState(null);

    function login(userData) {
        let user = { ...userData, exp: new Date().setHours(3) };

        localStorage.setItem('user', JSON.stringify(user));
        dispatch({
            type: 'LOGIN',
            payload: user,
        });
    }

    function logoutStorage() {
        localStorage.removeItem('user');
        dispatch({ type: 'LOGOUT' });
    }

    const loginChat = () => {
        if (state.user && !chatUser) {

            const uid = createUid(state.user.id, state.user.first_name);
            const username = createUsername(state.user.first_name, state.user.last_name);

            chatLogin(
                uid,
                username,
                User => {
                    // setChatUser(User);
                    joinGroup(() => setChatUser(User));
                    // NotificationManager.success('You are now logged in on Chat', 'Login Success');
                },
                Error => {
                    setChatUser(null);

                    if (Error.code === 'ERR_UID_NOT_FOUND') {
                        registrationChat(uid, username, () => {
                            chatLogin(
                                uid,
                                username,
                                User => {
                                    // setChatUser(User);
                                    joinGroup(() => setChatUser(User));

                                    // NotificationManager.success(
                                    //     'You are now logged in on Chat',
                                    //     'Login Success',
                                    // );
                                },
                                Error => {
                                    setChatUser(null);

                                    if (Error.code === 'ERR_UID_NOT_FOUND') {
                                        registrationChat(uid, username, () => {
                                            console.log('SECOND REGISTRATION');
                                        });
                                    }
                                },
                            );
                        });
                    }
                },
            );
        }
    };

    return (
        <AuthContext.Provider
            value={{
                user: state.user,
                login,
                logoutStorage,
                loginChat,
                chatUser,
                registrationChat,
            }}
            {...props}
        />
    );
}

export { AuthContext, AuthProvider };
