import styled from 'styled-components';
// import { createBreakpoint } from 'styled-components-breakpoint';
// import { styledBreakpoints } from '../../style/styledBreakpoints';
import { customTheme } from '../../style/customChakraTheme.js';
// const breakpoint = createBreakpoint(styledBreakpoints);

// background: ${customTheme.colors.brand[500]};
// background: ${props => (props.light && customTheme.colors.brand[100]) || (props.dark && customTheme.colors.brand[700]) || customTheme.colors.brand[500]};
// `url(./images/bg-login.png)`

export const CardContainer = styled.div`
    border-radius: 4px;
    overflow: hidden;
`;
export const CardThumb = styled.div`
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    padding-top: 56.25%;
    padding-top: ${props => (props.willbeLive && '56.25%') || '56.25%'};

    > img,
    > picture,
    > svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;
export const CardThumbData = styled.div`
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: auto;
    z-index: 2;
    color: #fff;
`;
export const CardThumbContent = styled.div`
    position: absolute;
    z-index: 2;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    color: #fff;
`;
export const CardThumbPlay = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 2;
    background: ${customTheme.colors.brand[500]};
    border-radius: 100%;
    color: #fff;
    padding: 0.5em;
    font-size: 24px;
    transition: transform 0.1s ease-in-out;

    &:hover {
        transform: translate3d(-50%, -50%, 0) scale(1.1);
    }
`;
