import { useMutation, gql } from '@apollo/client';
import { useToast } from '@chakra-ui/core';
import userQueryGQL from '../../config/userQuery';

const updateUserMutationGQL = gql`
    mutation updateUser(
        $firstName: String!
        $phone: String
        $lastName: String!
        $company: String
        $profession: String
        $certifications: Boolean
    ) {
        updateUser(
            input: {
                lastName: $lastName
                firstName: $firstName
                phone: $phone
                company: $company
                profession: $profession
                certifications: $certifications
            }
        ) {
            first_name
            phone
            last_name
            profession
            company
            certifications
        }
    }
`;

export const useUpdateUserMutation = errors => {
    let toast = useToast();

    const [mutation, mutationResults] = useMutation(updateUserMutationGQL, {
        update(proxy, result) {
            //history.push('/grazie');
        },
        onCompleted: data => {
            toast({
                position: 'bottom-left',
                title: 'Profilo changed.',
                description: 'profilo is changed successfuly.',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
        },
        onError(err) {
            // debugger;
        },
    });

    const updateUser = (
        firstName,
        lastName,
        phone,
        profession,
        company,
        certifications,

        ...errors
    ) => {
        return mutation({
            variables: {
                firstName,
                lastName,
                phone,
                profession,
                company,
                certifications,
            },
            refetchQueries: [
                {
                    query: userQueryGQL,
                },
            ],
            ...errors,
        });
    };
    return [updateUser, mutationResults, errors];
};
