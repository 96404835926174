import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Flex, Center, Text, Checkbox, Button } from '@chakra-ui/core';
import { useUpdateUserMutation } from '../mutations/notifications';

export const NotificationTable = ({ user }) => {
    const { handleSubmit, errors, register, setValue, reset } = useForm();
    const [updateUserMutation, userUpdateMutationResults] = useUpdateUserMutation(errors);
    //const disableForm = userUpdateMutationResults.loading || loading;
    const certification = user.certifications
    const onSubmit = (values, ...errors) => {
        updateUserMutation(
            (values.first_name = `${user.first_name}`),
            (values.first_name = `${user.last_name}`),
            (values.certifications = certification),
            values.acceptedNewsletterEmail,
            values.reminderUpdatedEvent,
            values.reminderUpdatedEventEmail,
            values.reminderBeforeInterestedInEvent,
            values.reminderBeforeInterestedInEventEmail,
            values.reminderBeforeEvent,
            values.reminderBeforeEventEmail,
        );
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Flex>
                <Center flex='1'></Center>

                <Flex w='150px' flex flexDirection='row' justifyContent='space-around' w='150px'>
                    <Text as='span' mr='10' color='brand.700'>
                        Email
                    </Text>
                    <Text as='span' color='brand.700'>
                        Notifica
                    </Text>
                </Flex>
            </Flex>
            <Box height='2px' width='100%' bg='gray.300' mt='3' mb='3' />
            <Flex>
                <Box flex='1'>
                    <Text>Inviami un promemoria 2 ore prima dell'evento.</Text>
                </Box>

                <Flex w='150px' flex flexDirection='row' justifyContent='space-around'>
                    <Checkbox
                        defaultIsChecked={user.reminderBeforeEventEmail}
                        name='reminderBeforeEventEmail'
                        onChange={e => setValue('reminderBeforeEventEmail', e.target.checked)}
                        ref={register}
                    ></Checkbox>

                    <Checkbox
                        defaultIsChecked={user.reminderBeforeEvent}
                        name='reminderBeforeEvent'
                        onChange={e => setValue('reminderBeforeEvent', e.target.checked)}
                        ref={register}
                    ></Checkbox>
                </Flex>
            </Flex>
            <Box height='2px' width='100%' bg='gray.300' mt='3' mb='3' />
            <Flex>
                <Box flex='1'>
                    <Text>
                        Inviami un promemoria degli eventi a cui sono interessato il giorno prima.
                    </Text>
                </Box>

                <Flex w='150px' flex flexDirection='row' justifyContent='space-around'>
                    <Checkbox
                        defaultIsChecked={user.reminderBeforeInterestedInEventEmail}
                        name='reminderBeforeInterestedInEventEmail'
                        onChange={e =>
                            setValue('reminderBeforeInterestedInEventEmail', e.target.checked)
                        }
                        ref={register}
                    ></Checkbox>

                    <Checkbox
                        defaultIsChecked={user.reminderBeforeInterestedInEvent}
                        name='reminderBeforeInterestedInEvent'
                        onChange={e =>
                            setValue('reminderBeforeInterestedInEvent', e.target.checked)
                        }
                        ref={register}
                    ></Checkbox>
                </Flex>
            </Flex>
            <Box height='2px' width='100%' bg='gray.300' mt='3' mb='3' />
            <Flex>
                <Box flex='1'>
                    <Text>
                        Inviami un promemoria ogni volta che l'evento a cui sto partecipando viene
                        aggiornato.
                    </Text>
                </Box>

                <Flex w='150px' flex flexDirection='row' justifyContent='space-around'>
                    <Checkbox
                        defaultIsChecked={user.reminderUpdatedEventEmail}
                        name='reminderUpdatedEventEmail'
                        onChange={e => setValue('reminderUpdatedEventEmail', e.target.checked)}
                        ref={register}
                    ></Checkbox>

                    <Checkbox
                        defaultIsChecked={user.reminderUpdatedEvent}
                        name='reminderUpdatedEvent'
                        onChange={e => setValue('reminderUpdatedEvent', e.target.checked)}
                        ref={register}
                    ></Checkbox>
                </Flex>
            </Flex>
            <Box height='2px' width='100%' bg='gray.300' mt='3' mb='3' />
            <Flex>
                <Box flex='1'>
                    <Text>Inviami una newsletter via e-mail.</Text>
                </Box>

                <Flex w='150px' flex flexDirection='row' justifyContent='space-around'>
                    <Checkbox
                        defaultIsChecked={user.acceptedNewsletterEmail}
                        name='acceptedNewsletterEmail'
                        onChange={e => setValue('acceptedNewsletterEmail', e.target.checked)}
                        ref={register}
                    ></Checkbox>

                   <Box w="16px"></Box>
                </Flex>
            </Flex>
            <Box height='2px' width='100%' bg='gray.300' mt='3' mb='10' />
            <Button type='submit' size='md' colorScheme='brand' mr='5'>
                Salva
            </Button>
            <Button size='md' colorScheme='grey' onClick={reset}>
                Cancella
            </Button>
        </form>
    );
};
export default NotificationTable;
