import styled from 'styled-components';
import { createBreakpoint } from 'styled-components-breakpoint';
import { styledBreakpoints } from '../../../style/styledBreakpoints';
import { customTheme } from '../../../style/customChakraTheme.js';
const breakpoint = createBreakpoint(styledBreakpoints);

// background: ${customTheme.colors.brand[500]};
// background: ${props => (props.light && customTheme.colors.brand[100]) || (props.dark && customTheme.colors.brand[700]) || customTheme.colors.brand[500]};
// `url(./images/bg-login.png)`

export const HeaderContainer = styled.div`
    background: ${props => (props.bgBrand && customTheme.colors.brand[500]) || null};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: ${customTheme.zIndices.sticky};
    color: #fff;
    padding: 1rem 0;
    transition: all 0.4s ease-in-out;

    @media screen and (min-width: 1650px) {
        /* padding: ${props => (props.bgBrand ? '1rem 0' : '40px 0')}; */
        padding: 40px 0;
    }

    .is-stickyheader & {
        padding: 1rem 0;
        background: ${customTheme.colors.brand[500]};
    }
`;
export const HeaderGrid = styled.div`
    position: relative;

    ${breakpoint('lg')`
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: auto 1fr;
        align-items: center;
    `}
`;
export const HeaderLogo = styled.div`
    position: relative;
    z-index: 1;
    .is-desktop {
        display: none;
    }
    @media screen and (min-width: 1650px) {
        .is-desktop {
            display: block;
        }
        .is-mobile {
            display: none;
        }
    }
    /* ${breakpoint('xl')`
        .is-desktop {
            display: block;
        }
        .is-mobile {
            display: none;
        }
    `} */
`;
export const HeaderNav = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transition: all 0.4s ease-in-out;
    transform: translate3d(100%, 0, 0);

    .is-menuvisible & {
        transform: none;
    }

    ${breakpoint('lg')`
        transform: none;
    `}

    nav {
        display: grid;
        grid-gap: 1rem;
        grid-template-rows: auto 1fr;
        align-items: center;
        background: ${customTheme.colors.brand[500]};
        padding: 170px 1rem 1rem;
        min-height: 100vh;

        > div {
            a {
                display: inline-block;
            }
        }

        ${breakpoint('lg')`
            grid-gap: 2rem;
            padding: 0;
            background: transparent;
            grid-template-rows: auto;
            grid-template-columns: 1fr auto;
            min-height: auto;
        `}
    }

    ul {
        list-style: none;
    }
    li {
        list-style: none;
        margin: 0;

        a {
            display: inline-block;
            position: relative;
            font-size: 18px;
            font-family: ${customTheme.fonts.heading};
            text-transform: uppercase;
            color: #fff;

            &::after {
                content: '';
                display: inline-block;
                position: absolute;
                top: calc(100% + 4px);
                left: 0;
                width: 100%;
                height: 2px;
                border-radius: 50em;
                transition: transform 0.2s ease-in-out;
                background: #008077;
                transform-origin: 100% 0;
                transform: scaleX(0);
            }

            &:hover {
                &::after {
                    transform: scaleX(1);
                    transform-origin: 0 0;
                }
            }

            &.isActive {
                position: relative;
                &::after {
                    transform: scaleX(1);
                }
            }

            &:hover {
                text-decoration: none;
            }
        }
    }
    li + li {
        margin-top: 0.7rem;
    }

    ${breakpoint('lg')`
        position: static;
        width: auto;
        height: auto;
        transform: none;
        place-self: center end;

        li {
            display: inline-block;
        }
        li + li {
            margin-top: 0;
            margin-left: 12px;
        }
    `}
    ${breakpoint('xl')`
        li + li {
            margin-top: 0;
            margin-left: 24px;
        }
    `}
`;
export const HamburgerContainer = styled.button`
    position: absolute;
    z-index: 2;
    top: 50%;
    margin-top: -24px;
    right: 0;
    border: 0;
    width: 48px;
    height: 48px;
    border: 0;
    cursor: pointer;

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 22px;
        height: 2px;
        background: #fff;
        display: inline-block;
        top: 50%;
        left: 50%;
        margin-top: -1px;
        margin-left: -11px;
        transition: all 0.1s ease-in-out;
        transform-origin: 50% 50%;
    }
    &::before {
        top: 21px;
    }
    &::after {
        top: 28px;
    }
    &:focus {
        outline: none;
    }
    &:hover,
    .is-menuvisible & {
        &::before,
        &::after {
            top: 50%;
        }
        &::before {
            transform: rotate(45deg);
        }
        &::after {
            transform: rotate(-45deg);
        }
    }
    ${breakpoint('lg')`
        display: none;
    `}
`;
