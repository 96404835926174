import { gql, useQuery } from '@apollo/client';

const programmaFileQueryGQL = gql`
    query {
        getProgrammaFile {
            name
            downloadUrl
            fileSize
        }
    }
`;

export const useProgrammaFileQueryGQL = () => useQuery(programmaFileQueryGQL);
