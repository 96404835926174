import React from 'react';
import { useParams } from 'react-router-dom';
import { Image, Box, Spinner } from '@chakra-ui/core';
import { SliderDots, LogoLink } from '../../components/common/styles';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';
import { useSponsorsGQLQuery } from '../../patrocini/query/patrociniQuery';
SwiperCore.use([Navigation, Pagination, Autoplay]);

const SliderPatrocini = ({ edition }) => {
    //let { edition } = useParams();
    const { data, loading, error } = useSponsorsGQLQuery({
        editionName: edition,
    });

    if (loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.500' />
            </Box>
        );
    if (error) return <p>ERROR</p>;
    if (!data) return <p>Not found</p>;

    const sponsors = data.getSponsors;
    return (
        <>
            <Swiper
                spaceBetween={16}
                slidesPerView={1}
                loop={false}
                autoHeight={true}
                autoplay={{
                    delay: 7000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    el: '.c-pagination-patrocini',
                    type: 'bullets',
                    clickable: 'true',
                }}
                breakpoints={{
                    375: {
                        slidesPerView: 1,
                        spaceBetween: 16,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 32,
                    },
                    1024: {
                        slidesPerView: 2,
                        spaceBetween: 32,
                    },
                    1280: {
                        slidesPerView: 3,
                        spaceBetween: 32,
                    },
                    1650: {
                        slidesPerView: 4,
                        spaceBetween: 32,
                    },
                }}
            >
                {sponsors && (
                    <>
                        {sponsors.map(sponsor => (
                            <SwiperSlide key={sponsor.id}>
                                <LogoLink
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href={sponsor.website1}
                                >
                                    <Image
                                        loading='lazy'
                                        src={sponsor.logoUrl}
                                        alt={sponsor.name}
                                    />
                                </LogoLink>
                            </SwiperSlide>
                        ))}
                    </>
                )}
            </Swiper>
            {/* <button className='prev'>prev</button>
            <button className='next'>next</button> */}
            <SliderDots className='c-pagination-patrocini' />
        </>
    );
};

export default SliderPatrocini;
