import React from 'react';
import { VStack, Box, Text, StackDivider } from '@chakra-ui/core';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const Experts = ({ experts }) => {
    return (
        <VStack
            divider={<StackDivider borderColor='gray.200' />}
            align='stretch'
            fontSize='lg'
            color='brand.700'
        >
            {experts.map(expert => (
                <Box color='brand.700'>
                    <Text as='p' lineHeight='1.2'>
                        {expert.name}
                    </Text>
                    <Text as='small' color='black'>
                        {expert.description}
                    </Text>
                </Box>
            ))}
        </VStack>
    );
};

export default Experts;
