import React from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import { Image, Heading, Link, Text, Box, AspectRatio } from '@chakra-ui/core';
import { SliderDots } from '../components/common/styles';

import VideoThumb from '../components/common/videoThumb';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Pagination]);

const SliderVideoGallery = ({ videos }) => {
    return (
        <>
            <Swiper
                spaceBetween={16}
                slidesPerView={1}
                loop={false}
                autoHeight={true}
                pagination={{
                    el: '.c-pagination-video-gallery',
                    type: 'bullets',
                    clickable: 'true',
                }}
                autoplay={{
                    delay: 7000,
                    disableOnInteraction: true,
                }}
                breakpoints={{
                    375: {
                        slidesPerView: 1,
                        spaceBetween: 16,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 32,
                    },
                }}
            >
                {videos.map(video => (
                    <SwiperSlide>
                        {/* <VideoThumb
                            isSimpleVideo
                            isBig={false}
                            videoUrl={video.url}
                            imgUrl={'https://picsum.photos/640/480'}
                        /> */}
                        <Box mb={[4, 4, 6, 10]}>
                            <AspectRatio
                                // ratio={16 / 9}
                                ratio={1.7777}
                                borderRadius='md'
                                style={{ overflow: 'hidden' }}
                            >
                                <>
                                    <>
                                        <iframe
                                            title='xxxxxx'
                                            allowFullScreen
                                            src={`https://www.youtube.com/embed/${video.url}`}
                                            allow='autoplay'
                                        />
                                    </>
                                </>
                            </AspectRatio>
                        </Box>
                    </SwiperSlide>
                ))}
            </Swiper>
            <SliderDots className='c-pagination-video-gallery' />
        </>
    );
};

export default SliderVideoGallery;
