import React from 'react';
import { Helmet } from 'react-helmet-async';
import AuthenticationForm from './form/authenticationForm';

import ScrollToTop from '../common/scrollToTop';

const Login = () => {
    return (
        <>
            <ScrollToTop />
            <Helmet>
                <title>Login</title>
            </Helmet>
            <AuthenticationForm />
        </>
    );
};

export default Login;
