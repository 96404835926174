import { useMutation, gql } from '@apollo/client';
import eventsListQueryGQL from '../queries/eventsList';
import getInterestedInEventsQueryGQL from '../../user/query/getInterestedInEvents';
import getMyAgendaEventsQueryGQL from '../../user/query/getMyAgenda';

export const addEventToAgendaMutationGQL = gql`
    mutation addEventToAgenda($id: Int!) {
        addEventToAgenda(id: $id) {
            result
        }
    }
`;

export const useAddEventToAgendaMutation = () => {
    const [mutation, mutationResults] = useMutation(addEventToAgendaMutationGQL, {
        update(proxy, result) {
            // debugger;
        },
        // onCompleted: data => {
        //     debugger;

        // },
        onError(err) {
            // debugger;
        },
    });
    const addEventToAgenda = id => {
        return mutation({
            variables: {
                id,
            },
            refetchQueries: [
                {
                    query: getMyAgendaEventsQueryGQL,
                    variables: { page: 1, limit: 20 },
                },
                {
                    query: getInterestedInEventsQueryGQL,
                    variables: { page: 1, limit: 20 },
                },
                {
                    query: eventsListQueryGQL,
                    //variables: { page: 1, limit: 20 },
                },
            ],
        });
    };
    return [addEventToAgenda, mutationResults];
};
