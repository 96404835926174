import styled from 'styled-components';
import { createBreakpoint } from 'styled-components-breakpoint';
import { styledBreakpoints } from '../style/styledBreakpoints';
import { customTheme } from '../style/customChakraTheme';
const breakpoint = createBreakpoint(styledBreakpoints);

// background: ${customTheme.colors.brand[500]};
// background: ${props => (props.light && customTheme.colors.brand[100]) || (props.dark && customTheme.colors.brand[700]) || customTheme.colors.brand[500]};
// `url(./images/bg-login.png)`
// ${breakpoint('md')`
//      width: 560px;
// `}

export const BlogCardContainer = styled.div`
    display: block;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    outline: 20px solid transparent;

    &:hover {
        background: #fff;
        outline: 20px solid #fff;
    }
`;
export const BlogPostCover = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: 5px;
    overflow: hidden;
    background: ${customTheme.colors.brand[500]};

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 260px;
        background-image: linear-gradient(
            360deg,
            #000000 0%,
            rgba(0, 0, 0, 0.98) 7%,
            rgba(0, 0, 0, 0.95) 14%,
            rgba(0, 0, 0, 0.88) 21%,
            rgba(0, 0, 0, 0.8) 27%,
            rgba(0, 0, 0, 0.71) 34%,
            rgba(0, 0, 0, 0.61) 40%,
            rgba(0, 0, 0, 0.5) 46%,
            rgba(0, 0, 0, 0.39) 53%,
            rgba(0, 0, 0, 0.29) 59%,
            rgba(0, 0, 0, 0.2) 67%,
            rgba(0, 0, 0, 0.12) 74%,
            rgba(0, 0, 0, 0.05) 82%,
            rgba(0, 0, 0, 0.02) 91%,
            rgba(0, 0, 0, 0) 100%
        );
        opacity: 0.55;
        z-index: 1;
    }

    img,
    picture,
    svg {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    ${breakpoint('lg')`
        width: calc(100% + 768px);
    `}
`;

export const PaginationContainer = styled.div`
    position: relative;
    text-align: center;
`;
export const PaginationList = styled.ul`
    margin: 0 auto;
    list-style: none;
    display: inline-flex;
    align-items: center;
    overflow: hidden;

    li {
        margin: 0;
        display: inline-block;
        font-size: 24px;

        &:first-child a,
        &:last-child a {
            color: ${customTheme.colors.grey[200]};
            background: transparent;
            &.isActive {
                color: ${customTheme.colors.brand[500]};
            }

            &:hover {
                background: transparent;
            }
        }
    }
    li {
        display: inline-block;
        font-size: 12px;

        ${breakpoint('md')`
            font-size: 24px;
            `}
    }
    li + li {
        margin-left: 8px;
    }
    a {
        display: flex;
        overflow: hidden;
        height: 24px;
        min-width: 24px;
        align-items: center;
        justify-content: center;

        ${breakpoint('md')`
            height: 42px;
            min-width: 42px;
        `}

        &.isActive {
            text-decoration: none;
            background: ${customTheme.colors.grey[100]};
            color: ${customTheme.colors.brand[500]};
        }
        &:hover {
            text-decoration: none;
            color: ${customTheme.colors.brand[500]};
        }
    }
`;
export const PaginationTotal = styled.div`
    text-align: center;
    ${breakpoint('md')`
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-80%);
    `}
`;
