import React from 'react';
import { Box, Spinner } from '@chakra-ui/core';
import { useGetNotificationsQueryGQL } from '../queries/getNotifications';
import Notification from './notification';
import { NotificationSingle } from '../../lib/styles';

const NotificationList = ({notifications}) => {
    // const notificationsData = useGetNotificationsQueryGQL({ page: 1, limit: 20 });

    // if (notificationsData.loading)
    //     return (
    //         <Box textAlign='center'>
    //             <Spinner color='brand.500' />
    //         </Box>
    //     );
    // if (notificationsData.error) return <p>ERROR</p>;
    // if (!notificationsData.data) return <p>Not found</p>;

    // const notifications = notificationsData.data.getNotifications;

    return (
        <>
            {notifications.length > 0 ? (
                <>
                    {notifications.map(notification => (
                        <Notification notification={notification} key={notification.id} />
                    ))}
                </>
            ) : (
                <NotificationSingle>Non hai nuove notifiche</NotificationSingle>
            )}
             {/* <NotificationSingle>Non hai nuove notifiche</NotificationSingle> */}
        </>
    );
};

export default NotificationList;
