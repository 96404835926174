import styled from 'styled-components';
import { createBreakpoint } from 'styled-components-breakpoint';
import { styledBreakpoints } from '../style/styledBreakpoints';
import { customTheme } from '../style/customChakraTheme.js';
const breakpoint = createBreakpoint(styledBreakpoints);

// background: ${customTheme.colors.brand[500]};
// background: ${props => (props.light && customTheme.colors.brand[100]) || (props.dark && customTheme.colors.brand[700]) || customTheme.colors.brand[500]};
// `url(./images/bg-login.png)`
// ${breakpoint('md')`
//      width: 560px;
// `}

export const ChatInput = styled.input`
    border: none;
    width: 100%;

    &:focus {
        outline: none;
        border: none;
    }
`;
export const ChatSend = styled.button`
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCI+PHBhdGggZmlsbD0iI0VDMDA4QyIgZD0iTTIwIDBjMTEuMDQ2IDAgMjAgOC45NTQgMjAgMjBzLTguOTU0IDIwLTIwIDIwUzAgMzEuMDQ2IDAgMjAgOC45NTQgMCAyMCAwem02LjQyMyAxMy41NzhhLjgyNy44MjcgMCAwMC0uODUzLS4yMDFMMTIuMjM3IDE3LjgyYS44MzIuODMyIDAgMDAuMDk3IDEuNjA2bDYuMzA2IDEuMjgyYS44MzIuODMyIDAgMDEuNjUxLjY1MWwxLjI4MiA2LjMwNmMuMDc1LjM3LjM4OS42NDMuNzY1LjY2NmwuMDUxLjAwMmEuODMyLjgzMiAwIDAwLjc5LS41N2w0LjQ0NS0xMy4zMzRjLjEtLjI5OS4wMjItLjYyOS0uMjAxLS44NTJ6Ii8+PC9zdmc+');
    border: 0;
    display: inline-block;
    margin: 0;

    &:focus {
        outline: none;
        border: none;
    }
`;
