import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Button, Stack, Spinner, Box } from '@chakra-ui/core';
import { UserAvatar } from '../../../../lib/userAvatar';
import { NotificationAvatar } from '../../../../lib/notificationAvatar';
import { useUserQuery } from '../../../../config/userQuery';
import { useAuthToken } from '../../../../config/auth';

import { HeaderNav } from '../styles';
import DinamicLink from './dinamicLink';

const Navigation = ({ edition }) => {
    const [authToken] = useAuthToken();
    const getYear = () => {
        return new Date().getFullYear();
    };
    //let edition = getYear();
    //let edition = '2020';

    return (
        <HeaderNav>
            <nav>
                <ul>
                    <li>
                        <NavLink exact activeClassName='isActive' to='/'>
                            Home
                        </NavLink>
                    </li>

                    <li>
                        <NavLink exact activeClassName='isActive' to={`/programma/${edition}`}>
                            Programma
                        </NavLink>
                    </li>

                    {/* <li>
                        <NavLink exact activeClassName='isActive' to='/blog'>
                            Blog
                        </NavLink>
                    </li>*/}
                    <DinamicLink edition={edition} />
                    <li>
                        <NavLink activeClassName='isActive' to='/contatti'>
                            Contatti
                        </NavLink>
                    </li>
                    {/* <li>
                        <NavLink exact activeClassName='isActive' to='/'>
                            Exhibition
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact activeClassName='isActive' to='/login'>
                            Iscrizioni
                        </NavLink>
                    </li> */}
                </ul>
                <div>
                    {authToken ? (
                        <Stack direction='row' spacing={4}>
                            <NotificationAvatar />
                            <UserAvatar />
                        </Stack>
                    ) : (
                        <>
                            <Button
                                as={RouterLink}
                                to='/registrati'
                                size='xsm'
                                variant='white'
                                color='brand.500'
                            >
                                Registrati
                            </Button>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <Button as={RouterLink} to='/login' size='xsm' variant='whiteOutline'>
                                Login
                            </Button>
                        </>
                    )}
                </div>
            </nav>
        </HeaderNav>
    );
};

export default Navigation;
