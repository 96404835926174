import React from 'react';
import { createIcon } from '@chakra-ui/icon';

// using `path`
export const PlayIcon = createIcon({
    displayName: 'PlayIcon',
    viewBox: '0 0 24 24',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path
            fill='currentColor'
            d='M21.67225,12.6194637 L5.17225,23.8694996 C5.0455,23.9557499 4.89775,24 4.75,24 C4.63,24 4.50925,23.9714999 4.39975,23.9129997 C4.15375,23.7832493 4,23.5282485 4,23.2499976 L4,0.749925919 C4,0.471675032 4.15375,0.21667422 4.39975,0.0869238062 C4.64575,-0.0435766096 4.94275,-0.0255765523 5.173,0.130423945 L21.673,11.3804598 C21.877,11.5199602 22,11.751711 22,11.9999618 C22,12.2482126 21.877,12.4799633 21.67225,12.6194637 Z'
        />
    ),
});
export const GoRightIcon = createIcon({
    displayName: 'GoRightIcon',
    viewBox: '0 0 86 31',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path
            fill='currentColor'
            d='M72.197 1.075L85 14c.333.333.5.833.5 1.5s-.167 1.167-.5 1.5L72.197 29.925a1.5 1.5 0 01-2.225-2.008l.103-.114L80.88 17 1.5 17a1.5 1.5 0 010-3l79.378-.001L70.075 3.197a1.5 1.5 0 012.122-2.122z'
        />
    ),
});
export const GoLeftIcon = createIcon({
    displayName: 'GoLeftIcon',
    viewBox: '0 0 86 31',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path
            fill='currentColor'
            d='M13.303 29.289L.5 16.364c-.333-.333-.5-.833-.5-1.5s.167-1.167.5-1.5L13.303.439a1.5 1.5 0 012.225 2.008l-.103.114L4.62 13.365 84 13.364a1.5 1.5 0 010 3H4.622l10.803 10.803a1.5 1.5 0 01-2.122 2.122z'
        />
    ),
});
export const IconTwitter = createIcon({
    displayName: 'IconTwitter',
    viewBox: '0 0 24 24',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path
            fill='currentColor'
            d='M8.29 20c7.547 0 11.675-6.156 11.675-11.495 0-.175 0-.35-.012-.522A8.265 8.265 0 0022 5.89a8.273 8.273 0 01-2.356.637 4.07 4.07 0 001.804-2.235 8.303 8.303 0 01-2.606.98 4.153 4.153 0 00-5.806-.175 4.006 4.006 0 00-1.187 3.86 11.717 11.717 0 01-8.457-4.22 4.005 4.005 0 001.271 5.392A4.122 4.122 0 012.8 9.625v.051c.001 1.923 1.378 3.579 3.292 3.96a4.144 4.144 0 01-1.852.069c.537 1.646 2.078 2.773 3.833 2.806A8.315 8.315 0 012 18.185a11.754 11.754 0 006.29 1.812'
        />
    ),
});
export const IconFacebook = createIcon({
    displayName: 'IconFacebook',
    viewBox: '0 0 24 24',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path
            fill='currentColor'
            d='M13.821 22v-9h2.733L17 9h-3.179V7.052c0-1.03.027-2.052 1.466-2.052h1.458V2.14c0-.043-1.253-.14-2.52-.14-2.645 0-4.302 1.657-4.302 4.7V9H7v4h2.923v9h3.898z'
        />
    ),
});
export const IconLinkedin = createIcon({
    displayName: 'IconLinkedin',
    viewBox: '0 0 24 24',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path
            fill='currentColor'
            d='M22 22h-4v-6.999c0-1.92-.847-2.991-2.366-2.991-1.653 0-2.634 1.116-2.634 2.991V22H9V9h4v1.462s1.255-2.202 4.083-2.202C19.912 8.26 22 9.986 22 13.558V22zM4.442 6.921A2.451 2.451 0 012 4.46 2.451 2.451 0 014.442 2a2.451 2.451 0 012.441 2.46 2.45 2.45 0 01-2.441 2.461zM2 22h5V9H2v13z'
        />
    ),
});
export const IconInstagram = createIcon({
    displayName: 'IconInstagram',
    viewBox: '0 0 24 24',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path
            fill='currentColor'
            d='M7.87 2.123c-1.628.073-3.04.471-4.179 1.606-1.143 1.14-1.536 2.557-1.61 4.168-.045 1.005-.313 8.601.463 10.593a5.04 5.04 0 002.91 2.903c.634.246 1.356.412 2.416.461 8.86.401 12.145.183 13.53-3.364.246-.631.415-1.353.462-2.41.405-8.883-.066-10.809-1.61-12.351-1.225-1.222-2.666-2.054-12.382-1.606m.081 17.944c-.97-.043-1.496-.205-1.848-.341a3.255 3.255 0 01-1.888-1.883c-.591-1.514-.395-8.703-.342-9.866.051-1.14.282-2.18 1.086-2.985C5.954 4 7.24 3.513 15.993 3.908c1.142.052 2.186.282 2.992 1.084.995.993 1.489 2.288 1.087 11.008-.044.968-.206 1.493-.342 1.843-.901 2.308-2.973 2.628-11.779 2.224M16.09 6.69c0 .657.534 1.19 1.194 1.19.66 0 1.195-.533 1.195-1.19a1.194 1.194 0 00-2.39 0m-9.226 5.298a5.103 5.103 0 005.11 5.097 5.103 5.103 0 005.109-5.097 5.102 5.102 0 00-5.11-5.096 5.102 5.102 0 00-5.11 5.096m1.794 0a3.313 3.313 0 013.316-3.308 3.313 3.313 0 013.317 3.308 3.313 3.313 0 01-3.317 3.31 3.313 3.313 0 01-3.316-3.31'
        />
    ),
});
export const IconYoutube = createIcon({
    displayName: 'IconYoutube',
    viewBox: '0 0 24 24',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path
            fill='currentColor'
            d='M9.988 14.586V8.974c1.993.938 3.536 1.843 5.36 2.82-1.505.834-3.367 1.77-5.36 2.792m11.103-8.403c-.344-.453-.93-.805-1.553-.922-1.833-.348-13.267-.349-15.099 0-.5.094-.945.32-1.328.673C1.5 7.429 2.005 15.452 2.393 16.75c.164.562.375.968.64 1.235.343.352.812.594 1.351.703 1.51.312 9.284.486 15.122.047a2.62 2.62 0 001.39-.712c1.49-1.49 1.388-9.962.195-11.841'
        />
    ),
});
export const IconLocation = createIcon({
    displayName: 'IconLocation',
    viewBox: '0 0 24 24',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path
            fill='currentColor'
            d='M12 0c4.418 0 8 3.618 8 8.082 0 .44-.059.932-.176 1.476l-.063.275c-.068.28-.151.573-.249.879l-.102.31c-.054.157-.111.317-.172.48l-.127.332-.136.34-.147.348-.156.357-.166.365-.175.374-.186.383-.096.194-.2.395-.104.2-.214.409-.224.416-.235.425-.243.434-.254.442-.263.45-.274.46-.14.232-.288.472-.297.48-.307.49-.317.497-.327.506-.337.515-.523.787-.545.807-.375.55L12 24l-.582-.839-.375-.549-.545-.807-.523-.787-.337-.515-.327-.506-.317-.497-.307-.49-.297-.48-.288-.472-.278-.463-.268-.455-.259-.446-.249-.438-.12-.216-.235-.425-.224-.416-.214-.408-.205-.4a39.587 39.587 0 01-.1-.196l-.19-.387-.09-.19-.176-.374-.166-.365-.156-.357-.147-.348-.136-.34-.127-.331a18.352 18.352 0 01-.172-.481l-.102-.31c-.098-.306-.18-.599-.249-.879l-.063-.275A7.066 7.066 0 014 8.082C4 3.618 7.582 0 12 0zm0 4a4 4 0 100 8 4 4 0 000-8z'
        />
    ),
});
export const IconSettings = createIcon({
    displayName: 'IconSettings',
    viewBox: '0 0 24 24',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path
            fill='currentColor'
            d='M13,20.9 L22,20.9 L22,18.8 L13,18.8 L13,16.7 L11,16.7 L11,18.8 L2,18.8 L2,20.9 L11,20.9 L11,23 L13,23 L13,20.9 Z M22,13.55 L22,11.45 L18,11.45 L18,9.35 L16,9.35 L16,11.45 L2,11.45 L2,13.55 L16,13.55 L16,15.65 L18,15.65 L18,13.55 L22,13.55 Z M13,6.2 L13,8.3 L11,8.3 L11,6.2 L2,6.2 L2,4.1 L11,4.1 L11,2 L13,2 L13,4.1 L22,4.1 L22,6.2 L13,6.2 Z'
        />
    ),
});
export const IconPlay = createIcon({
    displayName: 'IconPlay',
    viewBox: '0 0 24 24',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path
            fill='currentColor'
            d='M12,2 C6.486,2 2,6.486 2,12 C2,17.514 6.486,22 12,22 C17.514,22 22,17.514 22,12 C22,6.486 17.514,2 12,2 Z M16.8543995,12.3097155 L9.52100195,17.9347496 C9.46467174,17.9779499 9.3990015,18 9.33334125,18 C9.28000105,18 9.22634085,17.9858249 9.17767067,17.9565747 C9.06834026,17.8916618 9,17.7641986 9,17.6250352 L9,6.37496712 C9,6.23583753 9.06834026,6.1083405 9.17767067,6.04346136 C9.28700108,5.97821097 9.41900157,5.98721102 9.52134196,6.06521899 L16.8546995,11.690253 C16.9453998,11.7600035 17,11.8758792 17,12 C17,12.1240894 16.9453998,12.2399651 16.8543995,12.3097155 Z'
        />
    ),
});
export const IconDownload = createIcon({
    displayName: 'IconDownload',
    viewBox: '0 0 24 24',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path
            fill='currentColor'
            d='M6.35243,9.58738 C6.71941,9.2204 7.31446,9.2204 7.68156,9.58738 L11.06012,12.9659 L11.06,2.939847 C11.06,2.420801 11.4808,2 11.99984,2 C12.519,2 12.9397,2.420801 12.9397,2.939847 L12.9398,12.9657 L16.3181,9.58726 C16.6851,9.22028 17.2802,9.22028 17.6473,9.58726 C18.0143,9.95424 18.0143,10.54935 17.6473,10.91639 L12.6645,15.8993 C12.4882,16.0756 12.2492,16.1746 11.99991,16.1746 C11.75072,16.1746 11.51162,16.0756 11.33543,15.8993 L6.35256,10.91651 C5.98539,10.54954 5.98539,9.95442 6.35243,9.58738 Z M21.0602,22 L2.939847,22 C2.420863,22 2,21.5792 2,21.0602 C2,20.5411 2.420863,20.1203 2.939847,20.1203 L21.0602,20.1203 C21.5791,20.1203 22,20.5411 22,21.0602 C22,21.5792 21.5792,22 21.0602,22 Z'
        />
    ),
});
