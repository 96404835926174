import { gql, useQuery,useLazyQuery } from '@apollo/client';

const getNotificationsQueryGQL = gql`
    query getNotifications($page: Int, $limit: Int) {
        getNotifications(page: $page, limit: $limit) {
            id
            targetUser {
                id
                first_name
                last_name
                email
                phone
                profession
                certifications
                company
                reminderBeforeEvent
                reminderBeforeInterestedInEvent
                reminderUpdatedEvent
                acceptedNewsletter
                reminderBeforeEventEmail
                reminderBeforeInterestedInEventEmail
                reminderUpdatedEventEmail
                acceptedNewsletterEmail
            }
            description
            isRead
        }
    }
`;

export const useGetNotificationsQueryGQL = ({ page, limit }) => {
    return useLazyQuery(getNotificationsQueryGQL, {
        variables: { page, limit },
        fetchPolicy: 'network-only',
    });
};

export default getNotificationsQueryGQL;
