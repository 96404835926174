import { gql, useQuery } from '@apollo/client';

const eventsCategoriesQueryGQL = gql`
    query {
        getEventCategories {
            id
            name
        }
    }
`;

export const useEventsCategoriesQueryGQLQuery = () => useQuery(eventsCategoriesQueryGQL);

export { eventsCategoriesQueryGQL };
