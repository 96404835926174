import React from 'react';
import { Button } from '@chakra-ui/core';
import { useRemoveEventFromInterestedMutation } from '../mutation/removeEventFromInterested';
import { DeleteIcon } from '@chakra-ui/icons';

const RemoveEventFromInterestedButton = ({ id }) => {
    const [
        removeEventFromInterested,
        removeEventFromInterestedResults,
    ] = useRemoveEventFromInterestedMutation(id);
    //const disableForm = addEventToAgendaResults.loading || loading;
    const handleRemoveFromInterested = values => removeEventFromInterested((values.id = id));
    return (
        <>
            <Button
                onClick={handleRemoveFromInterested}
                leftIcon={<DeleteIcon />}
                mt='2'
                colorScheme='gray'
                size='sm'
            >
                Elimina
            </Button>
        </>
    );
};

export default RemoveEventFromInterestedButton;
