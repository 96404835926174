import { gql, useQuery } from '@apollo/client';

const editionsQueryGQL = gql`
    query GetEditions($editionName: String) {
        getEditions(editionName: $editionName) {
            name
            hackathons {
                id
            }
            programmaFiles {
                name
            }
            mainStudios {
                id
            }
            partners {
                id
            }
            sponsors {
                id
            }
            eventSpeakers {
                speakers {
                    id
                }
            }
            editionEvents {
                eventDays {
                    events {
                        id
                    }
                }
                totalItemCount
            }
        }
    }
`;

export const useEditionsGQLQuery = ({ editionName }) => {
    return useQuery(editionsQueryGQL, { variables: { editionName } });
};

export default editionsQueryGQL;
