import React from 'react';
import { Button } from '@chakra-ui/core';
import { useAddEventToAgendaMutation } from '../../events/mutation/addEventToAgenda';

const AddEventToAgendaButton = ({ id }) => {
    const [addEventToAgenda, addEventToAgendaResults] = useAddEventToAgendaMutation();
    //const disableForm = addEventToAgendaResults.loading || loading;
    const handleAddToAgenda = values => addEventToAgenda((values.id = id));
    return (
        <Button size='sm' mt='3' variant='brand' onClick={handleAddToAgenda}>
            Aggiungi alla mia agenda
        </Button>
    );
};

export default AddEventToAgendaButton;
