import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Container, Heading, Text, Box, Spinner, Link, SimpleGrid } from '@chakra-ui/core';

import ScrollToTop from '../common/scrollToTop';

import { useEditionsGQLQuery } from './query/getEditions';

const Editions = () => {
    const getYear = () => {
        return new Date().getFullYear();
    };
    let currentYear = getYear();
    let year = String(currentYear);
    console.log(year);
    const { loading, data, error } = useEditionsGQLQuery([]);

    if (loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.500' />
            </Box>
        );
    if (error) return <p>ERROR</p>;
    if (!data) return <p>Not found</p>;

    const editions = data.getEditions;
    const pastEditions = editions.filter(item => item.name !== year);

    return (
        <>
            <ScrollToTop />
            <Helmet>
                <title>Past editions</title>
            </Helmet>
            <Container maxW='1312px' my={[4, 4, 8, 16]}>
                <Heading as='h1' color='brand.700' size='xl' mb='6'>
                    Past Editions
                </Heading>
                <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={10}>
                    {pastEditions && (
                        <>
                            {pastEditions.map(edition => (
                                <Box bg='white' p='4' pt='6' pb='6'>
                                    <Text
                                        mb={1}
                                        as='p'
                                        fontFamily='heading'
                                        color='brand.700'
                                        fontSize='2xl'
                                        fontWeight='500'
                                    >
                                        {edition.name}
                                    </Text>
                                    {edition.hackathons.length > 0 && (
                                        <Box>
                                            <Box display='inline-block'>
                                                <Link
                                                    as={RouterLink}
                                                    to={`/hackathon/${edition.name}`}
                                                    color='brand.500'
                                                    fontSize='14px'
                                                    fontWeight='500'
                                                >
                                                    Hackathon {edition.name}
                                                </Link>{' '}
                                                <Box h='2px' bg='brand.700' mb='2'></Box>
                                            </Box>
                                            <Box h='1px' bg='grey.100'></Box>
                                        </Box>
                                    )}
                                    {edition.mainStudios.length > 0 && (
                                        <Box>
                                            <Box display='inline-block'>
                                                <Link
                                                    as={RouterLink}
                                                    to={`/main-studio/${edition.name}`}
                                                    color='brand.500'
                                                    fontSize='14px'
                                                    fontWeight='500'
                                                >
                                                    Main studio {edition.name}
                                                </Link>{' '}
                                                <Box h='2px' bg='brand.700' mb='2'></Box>
                                            </Box>
                                            <Box h='1px' bg='grey.100'></Box>
                                        </Box>
                                    )}
                                    {/* {edition.programmaFiles.length > 0 && (
                                    <>
                                        {edition.programmaFiles.map(files => (
                                            <Text>files</Text>
                                        ))}
                                    </>
                                )} */}
                                    {edition.partners.length > 0 && (
                                        <Box>
                                            <Box display='inline-block'>
                                                <Link
                                                    as={RouterLink}
                                                    to={`/partner/${edition.name}`}
                                                    color='brand.500'
                                                    fontSize='14px'
                                                    fontWeight='500'
                                                >
                                                    Partner {edition.name}
                                                </Link>{' '}
                                                <Box h='2px' bg='brand.700' mb='2'></Box>
                                            </Box>
                                            <Box h='1px' bg='grey.100'></Box>
                                        </Box>
                                    )}
                                    {edition.eventSpeakers.length > 0 && (
                                        <Box>
                                            <Box display='inline-block'>
                                                <Link
                                                    as={RouterLink}
                                                    to={`/comitato-scientifico/${edition.name}`}
                                                    color='brand.500'
                                                    fontSize='14px'
                                                    fontWeight='500'
                                                >
                                                    Comitato-scientifico {edition.name}
                                                </Link>{' '}
                                                <Box h='2px' bg='brand.700' mb='2'></Box>
                                            </Box>
                                            <Box h='1px' bg='grey.100'></Box>
                                        </Box>
                                    )}
                                    {edition.editionEvents && (
                                        <>
                                            {edition.editionEvents.totalItemCount > 0 && (
                                                <Box>
                                                    <Box display='inline-block'>
                                                        <Link
                                                            as={RouterLink}
                                                            to={`/programma/${edition.name}`}
                                                            color='brand.500'
                                                            fontSize='14px'
                                                            fontWeight='500'
                                                        >
                                                            Programma {edition.name}
                                                        </Link>{' '}
                                                        <Box h='2px' bg='brand.700' mb='2'></Box>
                                                    </Box>
                                                    <Box h='1px' bg='grey.100'></Box>
                                                </Box>
                                            )}
                                        </>
                                    )}
                                    {edition.sponsors && (
                                        <>
                                            {edition.sponsors.length > 0 && (
                                                <Box>
                                                    <Box display='inline-block'>
                                                        <Link
                                                            as={RouterLink}
                                                            to={`/patrocini/${edition.name}`}
                                                            color='brand.500'
                                                            fontSize='14px'
                                                            fontWeight='500'
                                                        >
                                                            Patrocini {edition.name}
                                                        </Link>{' '}
                                                        <Box h='2px' bg='brand.700' mb='2'></Box>
                                                    </Box>
                                                    <Box h='1px' bg='grey.100'></Box>
                                                </Box>
                                            )}
                                        </>
                                    )}
                                </Box>
                            ))}
                        </>
                    )}
                </SimpleGrid>
            </Container>
        </>
    );
};

export default Editions;
