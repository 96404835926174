import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@chakra-ui/core';
import { useForm } from 'react-hook-form';
import { useValidateEmailMutation } from '../mutations/validateEmailMutation';

const ValidateEmailForm = ({ loading }) => {
    const { handleSubmit, register, errors } = useForm();
    let history = useHistory();
    let { resetToken } = useParams();
    const [validateEmail, validateEmailResults] = useValidateEmailMutation(errors);
    const disableForm = validateEmailResults.loading || loading;
    const onSubmit = (values, errors) => validateEmail((values.token = resetToken));
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Button
                    type='submit'
                    size='lg'
                    colorScheme='brand'
                    isFullWidth
                    disabled={disableForm}
                >
                    Vai a login
                </Button>
            </form>
        </>
    );
};
export default ValidateEmailForm;
