import { useEffect } from 'react';

const ScrollToTop = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('is-menuvisible');
    });
    return null;
};

export default ScrollToTop;
