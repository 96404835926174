import React, { useRef } from 'react';
import { FormErrorMessage, FormLabel, FormControl, Input, Button, Text } from '@chakra-ui/core';
import { useForm } from 'react-hook-form';
import { useChangeEmailMutation } from '../mutations/changeEmail';

const ChangeEmailForm = ({ loading }) => {
    const { handleSubmit, errors, register, reset } = useForm();
    const [changeEmailMutation, changeEmailResults] = useChangeEmailMutation(errors);
    const disableForm = changeEmailResults.loading || loading;
    const onSubmit = (values, errors) =>
        changeEmailMutation(
            values.newEmail,
            values.confirmEmail,
            values.password,
            errors.newEmail,
            errors.confirmEmail,
            errors.password,
        );
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Text fontSize='18' textTransform='uppercase' mb='4'>
                    <strong>Cambia email</strong>
                </Text>
                <FormControl isInvalid={errors.newEmail} mb={[4, 4, 8]}>
                    <FormLabel htmlFor='newEmail'>Nuova Email</FormLabel>
                    <Input
                        type='email'
                        name='newEmail'
                        id='newEmail'
                        placeholder='example@email.com'
                        ref={register({
                            required: { value: true, message: 'Questo campo è obbligatorio' },
                        })}
                    />
                    <FormErrorMessage>
                        {errors.newEmail?.message || errors.newEmail}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.ConfirmEmail} mb={[4, 4, 8]}>
                    <FormLabel htmlFor='confirmEmail'>Confirma Email</FormLabel>
                    <Input
                        type='email'
                        name='confirmEmail'
                        id='confirmEmail'
                        name='confirmEmail'
                        placeholder='example@email.com'
                        ref={register({
                            required: { value: true, message: 'Questo campo è obbligatorio' },
                        })}
                    />
                    <FormErrorMessage>
                        {errors.confirmEmail?.message || errors.confirmEmail}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.password} mb={[4, 4, 8]}>
                    <FormLabel htmlFor='password'>Password</FormLabel>
                    <Input
                        name='password'
                        id='password'
                        type='password'
                        placeholder=''
                        ref={register({
                            required: { value: true, message: 'Questo campo è obbligatorio' },
                        })}
                    />
                    <FormErrorMessage>
                        {errors.password?.message || errors.password}
                    </FormErrorMessage>
                </FormControl>

                <Button type='submit' size='md' colorScheme='brand' disabled={disableForm}>
                    Salva
                </Button>
                <Button size='md' colorScheme='grey' ml='3' onClick={reset}>
                    Cancella
                </Button>
            </form>
        </>
    );
};
export default ChangeEmailForm;
