import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AuthContext } from '../context/auth';
import ChatGroup from './ChatGroup';
import { NotificationContainer } from 'react-notifications';
import { Center, AspectRatio, Button } from '@chakra-ui/core';
import 'react-notifications/lib/notifications.css';

const ChatWrapper = () => {
    const { loginChat, chatUser, user } = useContext(AuthContext);
    loginChat();

    return chatUser && user ? (
        <>
            {/*<Chat user={chatUser} />*/}
            <NotificationContainer />
            <ChatGroup user={chatUser} />
        </>
    ) : (
        <>
            <AspectRatio
                bg='grey.800'
                color='black'
                borderRadius='md'
                mb='6'
                ratio={[1.3, 1.3, 0.89, 0.71]}
                borderRadius='md'
                style={{ overflow: 'hidden' }}
            >
                <Center>
                    <Button as={RouterLink} to='/login' size='xsm' variant='whiteOutline'>
                    Accedi chat
                    </Button>
                </Center>
            </AspectRatio>
        </>
    );
};

Event.propTypes = {};

export default ChatWrapper;
