import React from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';

import {
    FormErrorMessage,
    FormLabel,
    FormControl,
    Input,
    Button,
    Checkbox,
    Link,
    Text,
    InputGroup,
    InputRightElement,
} from '@chakra-ui/core';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useUpdateUserMutation } from '../mutations/userUpdate';

const UpdateForm = ({ loading, user }) => {
    const [show, setShow] = React.useState(false);
    const handlePwdClick = () => setShow(!show);

    const { handleSubmit, errors, register, setValue, defaultChecked, reset } = useForm();
    const [updateUserMutation, userUpdateMutationResults] = useUpdateUserMutation(errors);
    const disableForm = userUpdateMutationResults.loading || loading;
    const onSubmit = (values, ...errors) => {
        updateUserMutation(
            values.firstName,
            values.lastName,
            values.phone,
            values.profession,
            values.company,
            values.certifications,
        );
    };
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl isRequired isInvalid={errors.firstName} mb={[4, 4, 8]}>
                    <FormLabel htmlFor='firstName'>Nome</FormLabel>
                    <Input
                        defaultValue={user.first_name}
                        name='firstName'
                        id='firstName'
                        placeholder='John'
                        ref={register({
                            required: { value: true, message: 'Questo campo è obbligatorio' },
                            maxLength: { value: 50, message: 'max 50 character' },
                        })}
                    />
                    <FormErrorMessage>
                        {errors.firstName?.message || errors.firstName}
                    </FormErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={errors.lastName} mb={[4, 4, 8]}>
                    <FormLabel htmlFor='lastName'>Cognome</FormLabel>
                    <Input
                        defaultValue={user.last_name}
                        name='lastName'
                        id='lastName'
                        placeholder='Doe'
                        ref={register({
                            required: { value: true, message: 'Questo campo è obbligatorio' },
                            maxLength: { value: 50, message: 'max 50 character' },
                        })}
                    />
                    <FormErrorMessage>
                        {errors.lastName?.message || errors.lastName}
                    </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.phone} mb='6'>
                    <FormLabel htmlFor='phone'>Cellulare</FormLabel>
                    <Input
                        defaultValue={user.phone}
                        name='phone'
                        id='phone'
                        type='tel'
                        placeholder='Per favorire la partecipazione'
                        ref={register({
                            minLength: { value: 5, message: 'min 5 character' },
                        })}
                    />
                    <FormErrorMessage>{errors.phone?.message || errors.phone}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.profession} mb='6'>
                    <FormLabel htmlFor='profession'>Professione</FormLabel>
                    <Input
                        defaultValue={user.profession}
                        name='profession'
                        id='profession'
                        type='text'
                        placeholder='La tua risposta'
                        ref={register({
                            minLength: { value: 5, message: 'min 5 character' },
                        })}
                    />
                    <FormErrorMessage>
                        {errors.profession?.message || errors.profession}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.company} mb='6'>
                    <FormLabel htmlFor='company'>Azienda</FormLabel>
                    <Input
                        defaultValue={user.company}
                        name='company'
                        id='company'
                        type='text'
                        placeholder='La tua risposta'
                        ref={register({
                            minLength: { value: 5, message: 'min 5 character' },
                        })}
                    />
                    <FormErrorMessage>{errors.company?.message || errors.company}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.certifications} mb='6'>
                    <FormLabel>Certificazioni</FormLabel>
                    <Checkbox
                        defaultIsChecked={user.certifications}
                        name='certifications'
                        onChange={e => setValue('certifications', e.target.checked)}
                        ref={register}
                    >
                        Richiedo attestato di partecipazione
                    </Checkbox>
                </FormControl>

                <Button type='submit' size='md' colorScheme='brand' mr='5' disabled={disableForm}>
                    Salva
                </Button>
                <Button size='md' colorScheme='grey' onClick={reset}>
                    Cancella
                </Button>
            </form>
        </>
    );
};

export default UpdateForm;
