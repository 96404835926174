import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Text, Link } from '@chakra-ui/core';
import { useAddEventToAgendaMutation } from '../mutation/addEventToAgenda';

const AddEventToAgendaButton = ({ id, isInMyAgenda }) => {
    const [addEventToAgenda, addEventToAgendaResults] = useAddEventToAgendaMutation();
    //const disableForm = addEventToAgendaResults.loading || loading;
    const handleAddToAgenda = values => addEventToAgenda((values.id = id));
    return (
        <>
            {!isInMyAgenda ? (
                <Button size='xsm' variant='brand' onClick={handleAddToAgenda}>
                    Aggiungi alla mia agenda
                </Button>
            ) : (
                <Text as='p' color='grey.300' fontSize='sm'>
                    Aggiunto alla{' '}
                    <Text as='span' color='brand.500'>
                        Mia Agenda
                    </Text>
                </Text>
            )}
        </>
    );
};

AddEventToAgendaButton.propTypes = {};

export default AddEventToAgendaButton;
