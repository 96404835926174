import styled from 'styled-components';
import { createBreakpoint } from 'styled-components-breakpoint';
import { styledBreakpoints } from '../style/styledBreakpoints';
import { customTheme } from '../style/customChakraTheme.js';
const breakpoint = createBreakpoint(styledBreakpoints);

// background: ${customTheme.colors.brand[500]};
// background: ${props => (props.light && customTheme.colors.brand[100]) || (props.dark && customTheme.colors.brand[700]) || customTheme.colors.brand[500]};
// `url(./images/bg-login.png)`
// ${breakpoint('md')`
//      width: 560px;
// `}

export const Timeline = styled.div`
    display: grid;

    ${breakpoint('md')``}
`;

export const TimelineDateName = styled.div``;
export const TimelineDateEvents = styled.div``;
export const TimelineDate = styled.div`
    display: grid;

    ${breakpoint('md')`
        grid-gap: 0;
        grid-template-columns: 132px 1fr;
    `}

    ${TimelineDateName} {
        padding-right: 16px;

        ${breakpoint('md')`
            border-right: 2px solid ${customTheme.colors.brand[500]};
        `}
    }
    ${TimelineDateEvents} {
        padding-bottom: 1rem;

        ${breakpoint('md')`
            padding-bottom: 2rem;
        `}
    }
    & + & {
        border-top: 2px solid #999;

        ${TimelineDateName} {
            padding-top: 1rem;
        }
        ${breakpoint('md')`
            ${TimelineDateEvents} {
                padding-top: 2rem;

            }
        `}
    }
`;
export const EventContainer = styled.div`
    display: grid;
    grid-gap: 1rem;

    ${breakpoint('md')`
        padding-left: ${props => (props.isSearchresult && '0') || '2rem'};
        grid-gap: 1.5rem;
    `}

    & + & {
        margin-top: 1rem;
        padding-top: 1rem;
        border-top: 2px solid ${customTheme.colors.grey[100]};

        ${breakpoint('md')`
            margin-top: 2rem;
            padding-top: 2rem;
        `}
    }

    ${breakpoint('lg')`
        grid-template-columns: ${props => (props.isSearchresult && '1fr 3fr;') || '1fr 2fr 2fr'};
    `}

    ${breakpoint('xl')`
        grid-template-columns: ${props => (props.isSearchresult && '1fr 4fr;') || '1fr 2fr 5fr'};
    `}
`;
