import { gql, useQuery } from '@apollo/client';

const mainStudioQueryGQL = gql`
    query getMainStudios($status: [String], $editionName: String) {
        getMainStudios(status: $status, editionName: $editionName) {
            id
            title
            subTitle
            status
            timeRange
            liveVideoUrl
            description
            coverPhotoUrl
            mainStudioProgrammas {
                title
                mainStudioProgrammaItems {
                    time
                    description
                }
            }
        }
    }
`;

export const useMainStudioQueryGQL = ({ variables: { status, editionName } }) => {
    return useQuery(mainStudioQueryGQL, {
        variables: { status, editionName },
    });
};
