import React from 'react';
import { Button } from '@chakra-ui/core';
import { useRemoveEventFromAgendaMutation } from '../mutation/removeEventFromAgenda';
import { DeleteIcon } from '@chakra-ui/icons';

const RemoveEventToAgendaButton = ({ id }) => {
    const [removeEventFromAgenda, removeEventFromAgendaResults] = useRemoveEventFromAgendaMutation(
        id,
    );
    //const disableForm = addEventToAgendaResults.loading || loading;
    const handleRemoveFromAgenda = values => removeEventFromAgenda((values.id = id));
    return (
        <>
            <Button
                onClick={handleRemoveFromAgenda}
                leftIcon={<DeleteIcon />}
                mt='2'
                colorScheme='gray'
                size='sm'
            >
                Elimina
            </Button>
        </>
    );
};

export default RemoveEventToAgendaButton;
