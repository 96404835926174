import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Heading, FormControl, FormLabel, Input, Button, Textarea } from '@chakra-ui/core';

class ProgramsContactform extends Component {
    render() {
        const { sender, programID } = this.props;
        return (
            <>
                <Heading as='h3' size='md' mb='4' textTransform='uppercase' color='black'>
                    Invia una domanda
                </Heading>
                <form programID={programID}>
                    <FormControl mb='6'>
                        <FormLabel htmlFor='name'>Nome e cognome</FormLabel>
                        <Input
                            isDisabled
                            name='name'
                            type='text'
                            placeholder={sender}
                            value={sender}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor='name'>Domanda</FormLabel>
                        <Textarea rows='5' placeholder='Il tuo messaggio' resize='vertical' />
                    </FormControl>
                    <Button type='submit' size='lg' variant='brand'>
                        Invia Domanda
                    </Button>
                </form>
            </>
        );
    }
}

ProgramsContactform.propTypes = {
    sender: PropTypes.string.isRequired,
    programID: PropTypes.string.isRequired,
};

ProgramsContactform.defaultProps = {
    sender: 'John Smith',
    programID: '123456789',
};

export default ProgramsContactform;
