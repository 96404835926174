import React, { useContext, useRef } from 'react';
import {
    FormControl,
    InputGroup,
    Input,
    InputRightElement,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Button,
} from '@chakra-ui/core';
import { SearchIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { EventContext } from '../context/EventContextProvider';
import { PrepareContext } from '../context/PrepareContextProvider';
import { SEARCH } from '../constants/checkbox';

const Search = () => {
    const { listEvents, searchTerm, setSearchTerm, dispatchListEvents } = useContext(EventContext);
    const { addQueryInput } = useContext(PrepareContext);
    const { handleSubmit } = useForm({});
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnSearch = useRef();

    const handleChange = e => {
        setSearchTerm(e.target.value);
    };

    const onSubmit = () => {
        const index = listEvents.findIndex(el => el.type === SEARCH);

        if (index >= 0) {
            listEvents.splice(index, 1, { data: searchTerm, name: searchTerm, type: SEARCH });
            dispatchListEvents({
                type: 'REMOVE_CHECKBOX',
                checkbox: { name: searchTerm, type: SEARCH },
            });
        }

        dispatchListEvents({
            type: 'ADD_CHECKBOX',
            checkbox: { data: searchTerm, name: searchTerm, type: SEARCH },
        });
        addQueryInput([...listEvents, { data: searchTerm, name: searchTerm, type: SEARCH }]);
        onClose()
    };

    return (
        <>
            <Button
                ref={btnSearch}
                onClick={onOpen}
                variant='ghost'
                rightIcon={<SearchIcon boxSize={4} color='brand.700' />}
            >
                Cerca tra gli eventi
            </Button>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                trapFocus={true}
                initialFocusRef={btnSearch}
                blockScrollOnMount={false}
            >
                <DrawerOverlay zIndex='100000' />
                <DrawerContent zIndex='100000000'>
                    <DrawerCloseButton />
                    <DrawerHeader>Cerca</DrawerHeader>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <DrawerBody>
                            <FormControl>
                                <InputGroup size='lg'>
                                    <Input
                                        name='search-event'
                                        placeholder='Cerca'
                                        value={searchTerm}
                                        onChange={handleChange}
                                    />
                                    <InputRightElement
                                        children={<SearchIcon boxSize={16} color='brand.700' />}
                                    />
                                </InputGroup>
                            </FormControl>
                        </DrawerBody>

                        <DrawerFooter justifyContent='flex-start'>
                            <Button type='submit' mr='4' colorScheme='brand'>
                                Applica
                            </Button>
                            <Button variant='outline' colorScheme='grey' onClick={onClose}>
                                Cancella
                            </Button>
                        </DrawerFooter>
                    </form>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default Search;
