import React from 'react';
import {
    Heading,
    Text,
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/core';

const Programma = ({ title, id, mainStudioProgrammaItems }) => {
    return (
        <>
            <Accordion key={id} allowToggle>
                <AccordionItem bg='white' borderColor='grey.300'>
                    <AccordionButton color='brand.500'>
                        <Box
                            flex='1'
                            textAlign='left'
                            color='brand.500'
                            fontSize='md'
                            fontFamily='body'
                        >
                            <Heading as='h2' size='lg' color='brand.500'>
                                {title}
                            </Heading>
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>{' '}
                    <AccordionPanel pb={1} bg='black'>
                        <Box fontSize='lg' lineHeight='1.5'>
                            {mainStudioProgrammaItems.map(item => {
                                return (
                                    <Text key={item.id}>
                                        <Text as='span' fontFamily='heading' fontWeight='600'>
                                            {item.time}
                                        </Text>{' '}
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: item.description,
                                            }}
                                        />
                                    </Text>
                                );
                            })}
                        </Box>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </>
    );
};

export default Programma;
