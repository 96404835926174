import React, { createContext, useEffect, useReducer, useState } from 'react';
import { CheckboxReducer } from '../reducers/CheckboxReducer';

export const EventContext = createContext();

const EventContextProvider = props => {
    const [searchTerm, setSearchTerm] = useState('');
    const [checked, setChecked] = useState([]);
    const [listEvents, dispatchListEvents] = useReducer(CheckboxReducer, []);

    const isChecked = name => {
        return checked.includes(name);
    };

    const handledFilter = (data, checked, name, type) => {

        if (checked) {
            dispatchListEvents({ type: 'ADD_CHECKBOX', checkbox: { data, name, type } });
        }
        if (!checked) {
            dispatchListEvents({ type: 'REMOVE_CHECKBOX', checkbox: { name, type } });
        }
    };

    useEffect(() => {
        if (listEvents) {
            setChecked([...listEvents.map(({ name }) => name)]);
        }
    }, [listEvents]);

    return (
        <EventContext.Provider
            value={{
                setSearchTerm,
                searchTerm,
                dispatchListEvents,
                listEvents,
                isChecked,
                handledFilter
            }}
        >
            {props.children}
        </EventContext.Provider>
    );
};

export default EventContextProvider;
