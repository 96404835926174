import React, { useState, useEffect } from 'react';
import { Grid, Box, Heading } from '@chakra-ui/core';
import ChatBoxGroup from './ChatBoxGroup';
import { MAIN_STUDIO } from './constants/group';
import 'react-notifications/lib/notifications.css';
import { fetchPreviousChat, messageListener, sendMessage } from '../lib/groupChat';
import TextareaAutosize from 'react-autosize-textarea';

import { ChatInput, ChatSend } from './styles';

const MESSAGE_LISTENER_KEY = 'listener-key';

const ChatGroup = ({ user }) => {
    const [selectedGroup, setSelectedGroup] = useState(MAIN_STUDIO);
    const [chat, setChat] = useState([]);
    const [chatIsLoading, setChatIsLoading] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        // will run when selectedGroup variable value is updated
        // fetch previous messages, remove listener if any
        // create new listener for incoming message

        if (selectedGroup) {
            fetchPreviousChat(selectedGroup, messages => {
                setChat(messages);
                setChatIsLoading(false);
                scrollToBottom();
            });

            messageListener(MESSAGE_LISTENER_KEY, message => {
                setChat(prevState => [...prevState, message]);
            });
        }
    }, []);

    const handleSubmit = event => {
        event.preventDefault();

        sendMessage(selectedGroup, message, chatMessage => {
            setChat([...chat, chatMessage]);
            scrollToBottom();
        });

        setMessage('');
    };

    const scrollToBottom = () => {
        let node = document.getElementById('ccChatBoxGroupEnd');
        node.scrollIntoView({ block: 'nearest' });
    };

    return (
        <Box
            style={{ height: 'auto', overflow: 'hidden' }}
            bg='white'
            color='black'
            borderRadius='md'
        >
            <Heading
                as='h3'
                p='4'
                size='md'
                bg='#efefef'
                textTransform='uppercase'
                borderBottom='1px solid #DEDFE0'
            >
                Live chat
            </Heading>
            <Box p='4' style={{ height: 385, overflow: 'auto' }}>
                <ChatBoxGroup user={user} chat={chat} chatIsLoading={chatIsLoading} />
            </Box>
            <Box px='4' py='2' style={{ bottom: 0, width: '100%' }} borderTop='1px solid #DEDFE0'>
                <form onSubmit={handleSubmit}>
                    <Grid templateColumns='1fr auto' gap={4} alignItems='end'>
                        <Box>
                            {/* <input
                                id='text'
                                type='text'
                                onChange={event => {
                                    setMessage(event.target.value);
                                }}
                                value={message}
                                placeholder='Scrivi qui il messaggio ...'
                            /> */}
                            <TextareaAutosize
                                value={message}
                                placeholder='Scrivi qui il messaggio ...'
                                onChange={event => {
                                    setMessage(event.target.value);
                                }}
                                style={{ width: '100%' }}
                            />
                        </Box>
                        <ChatSend />
                    </Grid>
                </form>
            </Box>
        </Box>
    );
};

export default ChatGroup;
