import styled from 'styled-components';
import { createBreakpoint } from 'styled-components-breakpoint';
import { styledBreakpoints } from '../style/styledBreakpoints';
import { customTheme } from '../style/customChakraTheme.js';
const breakpoint = createBreakpoint(styledBreakpoints);

// background: ${customTheme.colors.brand[500]};
// background: ${props => (props.light && customTheme.colors.brand[100]) || (props.dark && customTheme.colors.brand[700]) || customTheme.colors.brand[500]};
// `url(./images/bg-login.png)`
// ${breakpoint('md')`
//      width: 560px;
// `}

export const PartnerContainer = styled.div`
    display: grid;
    grid-gap: 1rem;

    & + & {
        margin-top: 1rem;
        padding-top: 1rem;
        border-top: 2px solid ${customTheme.colors.grey[100]};

        ${breakpoint('md')`
            margin-top: 3rem;
            padding-top: 3rem;
            `}
    }

    ${breakpoint('lg')`
        grid-template-columns: 280px 1fr;
        grid-gap: 1.5rem;
        `}
    ${breakpoint('xl')`
        grid-template-columns: 400px 1fr;
        grid-gap: 2rem;
    `}
`;
