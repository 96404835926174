import { gql, useQuery } from '@apollo/client';

const eventsListQueryGQL = gql`
    query GetEvents($input: EventFilter) {
        getEvents(input: $input) {
            eventDays {
                date
                events {
                    id
                    name
                    status
                    statusAsString
                    eventCategory {
                        id
                        name
                    }
                    startAtTimestamp
                    endAtTimestamp
                    edition
                    location
                    tag
                    excerpt
                    description
                    liveVideoUrl
                    eventVideos {
                        id
                        name
                        videoUrl
                        description
                    }
                    coverPhotoUrl
                    eventSpeakers {
                        id
                        name
                        description
                        photoUrl
                    }
                    eventComitatos {
                        id
                        name
                    }
                    eventFaculties {
                        id
                        name
                    }
                    eventFiles {
                        name
                        downloadUrl
                        fileSize
                    }
                    isInMyAgenda
                    isInInterestedIn
                }
            }
            totalItemCount
        }
    }
`;

export const useEventsListQueryGQLQuery = ({ input }) => {
    return useQuery(eventsListQueryGQL, { variables: { input } });
};

export default eventsListQueryGQL;
