import React from 'react';

import { Box, VStack, HStack, StackDivider, Avatar, Text, Wrap } from '@chakra-ui/core';

const Jury = ({ juries }) => {
    return (
        <VStack
            divider={<StackDivider borderColor='gray.200' />}
            spacing={4}
            align='stretch'
            fontSize='lg'
            color='brand.700'
        >
            {juries.map(jury => (
                <HStack spacing='12px' align='center'>
                    <Avatar name='Davide Bottalico' src={jury.photoUrl} />
                    <Box>
                        <Text as='div' lineHeight='1.2'>
                            {jury.name}
                        </Text>
                        <Text as='p' fontSize='sm' color='black' lineHeight='1.2'>
                            {jury.description}
                        </Text>
                    </Box>
                </HStack>
            ))}
        </VStack>
    );
};

export default Jury;
