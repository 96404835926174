import React from 'react';
import { Text } from '@chakra-ui/core';

const EmailSent = () => {
    return (
        <>
            <Text fontSize='sm' textAlign='center' mt='3'>
                Per impostare la nuova password segui le istruzioni ricevute per email.
            </Text>
            <Text fontSize='sm' textAlign='center' mb='2' mt='4'>
                Se non dovessi ricevere il messaggio entro 10 minuti, ti invitiamo a controllare la
                cartella Spam.
            </Text>
        </>
    );
};

export default EmailSent;
