import React from 'react';
import {useParams} from 'react-router-dom'
import { Box, Spinner } from '@chakra-ui/core';
import { useMainStudioQueryGQL } from './query/getMainStudio';
import StudioSingle from './studioSingle';
import StudioSinglePast from './studioSinglePast';
import StudioSingleLive from './studioSingleLive';
import ScrollToTop from '../common/scrollToTop';

const MainStudio = () => {
    let {edition} = useParams()
    const { data, loading, error } = useMainStudioQueryGQL({
        variables: { status: ['2', '1', '3'], editionName: edition },
    });

    if (loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.500' />
            </Box>
        );
    if (error) return <p>ERROR</p>;
    if (!data) return <p>Not found</p>;

    const studios = data.getMainStudios;
    //const video = data.getMainStudios.liveVideoUrl;

    return (
        <>
        <ScrollToTop />
            {studios && (
                <>
                    {studios.map(studio => (
                        <div>
                            {studio.status === '2' ? (
                                <StudioSingleLive studio={studio} />
                            ) : studio.status === '1' ? (
                                <StudioSingle studio={studio} />
                            ) : studio.status === '3' ? (
                                <StudioSinglePast studio={studio} />
                            ) : null}
                        </div>
                    ))}
                </>
            )}
        </>
    );
};

export default MainStudio;
