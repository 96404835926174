import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { Link as RouterLink } from 'react-router-dom';
// import { Container } from '@chakra-ui/core';

import '../lib/stickyHeader.js';
import Header from './layoutMain/header/header';
import Footer from './layoutMain/footer/footer';
import { MainLayout, MainContent } from './styles';

class LayoutMain extends Component {
    render() {
        const { children } = this.props;
        return (
            <MainLayout>
                <Header bgBrand />
                <MainContent>
                    {/* {!this.props.location.pathname === '/contatti' ? (
                        <Container maxW='1752px'>{children}</Container>
                    ) : (
                        <>{children}</>
                    )} */}
                    {children}
                </MainContent>
                <Footer />
            </MainLayout>
        );
    }
}

LayoutMain.propTypes = {
    children: PropTypes.any,
    location: PropTypes.object,
    pathname: PropTypes.string,
};

export default LayoutMain;
