import { gql, useQuery } from '@apollo/client';

const partnersQueryGQL = gql`
    query GetPartners($editionName: String) {
        getPartners(editionName: $editionName) {
            id
            name
            partnerTags {
                id
                name
            }
            description
            website1
            website2
            twitterUrl
            facebookUrl
            linkedinUrl
            instagramUrl
            youtubeUrl
            partnerOrder
            partnerFiles {
                name
                downloadUrl
                fileSize
            }
            logoUrl
        }
    }
`;

//export const usePartnersGQLQuery = () => useQuery(partnersQueryGQL);

export const usePartnersGQLQuery = ({ editionName }) => {
    return useQuery(partnersQueryGQL, { variables: { editionName } });
};
