import React from 'react';
import { Text, Box, HStack } from '@chakra-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import {
    LinkedinShareButton,
    TwitterShareButton,
    EmailShareButton,
    FacebookShareButton,
    WhatsappShareButton,
    TwitterIcon,
    FacebookIcon,
    LinkedinIcon,
    WhatsappIcon,
    EmailIcon,
} from 'react-share';

export const Share = () => {
    let history = useHistory();
    return (
        <Box>
            <Text as='h3' size='lg' mb='4'>
                Scegli il tuo Network e Condividi!
            </Text>
            <HStack spacing='12px' align='start'>
                <TwitterShareButton url={`https://crackingcancer.klive.it/programma`}>
                    <TwitterIcon size={42} round={true} />
                </TwitterShareButton>
                <FacebookShareButton url={`https://crackingcancer.klive.it/programma`}>
                    <FacebookIcon size={42} round={true} />
                </FacebookShareButton>
                <LinkedinShareButton url={`https://crackingcancer.klive.it/programma`}>
                    <LinkedinIcon size={42} round={true} />
                </LinkedinShareButton>
                <WhatsappShareButton url={`https://crackingcancer.klive.it/programma`}>
                    <WhatsappIcon size={42} round={true} />
                </WhatsappShareButton>
                <EmailShareButton url={`https://crackingcancer.klive.it/programma`}>
                    <EmailIcon size={42} round={true} />
                </EmailShareButton>
            </HStack>
        </Box>
    );
};

export default Share;
