import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ScrollToTop from '../common/scrollToTop';
import {
    Container,
    Heading,
    Grid,
    Box,
    Button,
    Link,
    Text,
    HStack,
    Image,
    Center,
    Badge,
    Spinner,
} from '@chakra-ui/core';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
    LinkedinShareButton,
    TwitterShareButton,
    EmailShareButton,
    FacebookShareButton,
    WhatsappShareButton,
    TwitterIcon,
    FacebookIcon,
    LinkedinIcon,
    WhatsappIcon,
    EmailIcon,
} from 'react-share';

import Timeline from './components/timeline';
import Challenges from './components/challenges';
import Jury from './components/jury';
import PastEvents from './components/pastEvents';
import Experts from './components/experts';
import Materials from './components/materials';

import SliderPresentationVideos from './sliderPresentationVideos';
import SliderVideoGallery from './sliderVideoGallery';
import SliderPhotoGallery from './sliderPhotoGallery';
import { useHackatonGQLQuery } from './query/hackatonQuery';

const Hackathon = () => {
    let { edition } = useParams();
    const { loading, data, error } = useHackatonGQLQuery({ editionName: edition });

    if (loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.500' />
            </Box>
        );
    if (error) return <p>ERROR</p>;
    if (!data) return <p>Not found</p>;

    const hackaton = data.getHackathon;
    return (
        <>
            <ScrollToTop />
            <Helmet>
                <title>Hackathon sulle malattie rare</title>
            </Helmet>

            <Container maxW='1312px' my={[4, 4, 8, 16]}>
                <Grid
                    templateColumns={{
                        base: '1fr',
                        sm: '1fr',
                        md: '2fr 1fr',
                        lg: '2.5fr 1fr',
                    }}
                    gap={{
                        base: 4,
                        sm: 4,
                        md: 6,
                        lg: 10,
                    }}
                >
                    {/* Left column */}
                    <Box style={{ minWidth: '0' }}>
                        {/* <Heading as='h1' color='brand.500' size='xl' mb={[4, 4, 8]}>
                            Che cos'è un hackathon?
                        </Heading> */}
                        <Box className='mainContentSmall'>
                            <Center mb='10'>
                                <Image w='300px' src={hackaton.mainLogoUrl} alt='Hackathon' />
                            </Center>
                            <Image src={hackaton.leftColumnMainImageUrl} alt='Hackathon' />

                            {hackaton.description && (
                                <Box dangerouslySetInnerHTML={{ __html: hackaton.description }} />
                            )}
                            <Box dangerouslySetInnerHTML={{ __html: hackaton.prizeDescription }} />
                        </Box>
                        <Center my={[4, 4, 8]}>
                            <Badge
                                variant='brandDarkOutlineBig'
                                fontFamily='body'
                                fontWeight='500'
                                fontSize='24px'
                            >
                                {hackaton.prizeValue}
                            </Badge>
                        </Center>

                        <Box bg='grey.100' height='2px' my={[4, 4, 8]} />

                        <Heading as='h2' color='brand.500' size='xl' my={[4, 4, 8]}>
                            Timeline
                        </Heading>
                        <Timeline timelines={hackaton.hackathonTimelineItems} />

                        <Box bg='grey.100' height='2px' my={[4, 4, 8]} />
                        <Heading as='h2' color='brand.500' size='xl' my={[4, 4, 8]}>
                            Le sfide
                        </Heading>
                        <Challenges challenges={hackaton.hackathonChallenges} />
                        {hackaton.hackathonDocuments.length > 0 && (
                            <Box display={{ base: 'none', sm: 'block' }}>
                                <Box bg='grey.100' height='2px' my={[4, 4, 8]} />
                                <Heading as='h2' color='brand.500' size='xl' my={[4, 4, 8]}>
                                    Documentazione
                                </Heading>
                                <>
                                    {hackaton.hackathonDocuments.map(document => (
                                        <Box>
                                            <embed
                                                src={document.fileUrl}
                                                type='application/pdf'
                                                width='100%'
                                                height='480px'
                                            />
                                            <Text
                                                as='p'
                                                size='lg'
                                                color='brand.700'
                                                textTransform='uppercase'
                                                my='4'
                                                mb='8'
                                            >
                                                {document.name}
                                            </Text>
                                        </Box>
                                    ))}
                                </>
                            </Box>
                        )}
                        <Box bg='grey.100' height='2px' my={[4, 4, 8]} />
                        {hackaton.hackathonPresentationVideo.length > 0 && (
                            <>
                                <Box bg='grey.100' height='2px' my={[4, 4, 8]} />
                                <Heading as='h2' color='brand.500' size='xl' my={[4, 4, 8]}>
                                    Presentazioni video dei team
                                </Heading>
                                <SliderPresentationVideos
                                    videos={hackaton.hackathonPresentationVideo}
                                />
                            </>
                        )}
                        {hackaton.hackathonVideos.length > 0 && (
                            <>
                                <Heading as='h2' color='brand.500' size='xl' my={[4, 4, 8]}>
                                    Video gallery
                                </Heading>
                                <SliderVideoGallery videos={hackaton.hackathonVideos} />
                                <Box bg='grey.100' height='2px' my={[4, 4, 8]} />
                            </>
                        )}
                        {hackaton.hackathonImages.length > 0 && (
                            <>
                                <Heading as='h2' color='brand.500' size='xl' my={[4, 4, 8]}>
                                    Photo gallery
                                </Heading>
                                <SliderPhotoGallery images={hackaton.hackathonImages} />
                            </>
                        )}
                    </Box>

                    {/* Right column */}
                    <Box>
                        <Image mb='6' src={hackaton.rightColumnMainImageUrl} alt='Hackathon' />
                        {hackaton.subscribeUrl && (
                            <Button
                                as={Link}
                                href={hackaton.subscribeUrl}
                                size='lg'
                                isExternal
                                variant='brand'
                                isFullWidth
                                mb='6'
                                leftIcon={<ArrowForwardIcon boxSize={6} />}
                            >
                                Iscriviti
                            </Button>
                        )}

                        <Box bg='white' borderRadius='md' mb='6' p='4'>
                            <Heading
                                as='h3'
                                size='md'
                                mb='4'
                                textTransform='uppercase'
                                color='black'
                            >
                                La giuria
                            </Heading>
                            <Jury juries={hackaton.hackathonJurys} />
                        </Box>
                        {hackaton.hackathonExperts.length > 0 && (
                            <Box bg='white' borderRadius='md' mb='6' p='4'>
                                <Heading
                                    as='h3'
                                    size='md'
                                    mb='4'
                                    textTransform='uppercase'
                                    color='black'
                                >
                                    Gli Esperti
                                </Heading>
                                <Experts experts={hackaton.hackathonExperts} />
                            </Box>
                        )}

                        {/* <Box bg='white' borderRadius='md' mb='6' p='4'>
                            <Heading
                                as='h3'
                                size='md'
                                mb='4'
                                textTransform='uppercase'
                                color='black'
                            >
                                Bando
                            </Heading>
                            <Bando />
                        </Box> */}
                        <Box bg='white' borderRadius='md' mb='6' p='4'>
                            <Heading
                                as='h3'
                                size='md'
                                mb='4'
                                textTransform='uppercase'
                                color='black'
                            >
                                Materiale scaricabile
                            </Heading>
                            <Materials files={hackaton.hackathonFiles} />
                        </Box>
                        <Box bg='white' borderRadius='md' mb='6' p='4'>
                            <Heading
                                as='h3'
                                size='md'
                                mb='4'
                                textTransform='uppercase'
                                color='black'
                            >
                                Hackathon sponsor
                            </Heading>
                            {hackaton.hackathonSponsors.map(sponsor => (
                                <>
                                    <Center my='4'>
                                        <Link
                                            href={sponsor.url}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            <Image
                                                w='300px'
                                                src={sponsor.photoUrl}
                                                alt={sponsor.title}
                                            />
                                        </Link>
                                    </Center>
                                </>
                            ))}
                        </Box>
                        <Box bg='white' borderRadius='md' mb='6' p='4'>
                            <Heading
                                as='h3'
                                size='md'
                                mb='4'
                                textTransform='uppercase'
                                color='black'
                            >
                                Media Partner
                            </Heading>
                            {hackaton.hackathonMediaPartners.map(partner => (
                                <Center my='4'>
                                    <Link href={partner.url} isExternal>
                                        <Image src={partner.photoUrl} alt={partner.title} />
                                    </Link>
                                </Center>
                            ))}
                        </Box>

                        {hackaton.hackathonPastLinks.length > 0 && (
                            <Box bg='white' borderRadius='md' mb='6' p='4'>
                                <Heading
                                    as='h3'
                                    size='md'
                                    mb='4'
                                    textTransform='uppercase'
                                    color='black'
                                >
                                    Edizioni Passate
                                </Heading>
                                <PastEvents pastLinks={hackaton.hackathonPastLinks} />
                            </Box>
                        )}

                        <Box>
                            <Text as='h3' size='lg' mb='4'>
                                Scegli il tuo Network e Condividi!
                            </Text>
                            <HStack spacing='12px' align='start'>
                                <TwitterShareButton url='https://klive.it/hackathon'>
                                    <TwitterIcon size={42} round={true} />
                                </TwitterShareButton>
                                <FacebookShareButton url='https://klive.it/hackathon'>
                                    <FacebookIcon size={42} round={true} />
                                </FacebookShareButton>
                                <LinkedinShareButton url='https://klive.it/hackathon'>
                                    <LinkedinIcon size={42} round={true} />
                                </LinkedinShareButton>
                                <WhatsappShareButton url='https://klive.it/hackathon'>
                                    <WhatsappIcon size={42} round={true} />
                                </WhatsappShareButton>
                                <EmailShareButton url='https://klive.it/hackathon'>
                                    <EmailIcon size={42} round={true} />
                                </EmailShareButton>
                            </HStack>
                        </Box>
                    </Box>
                </Grid>
            </Container>
        </>
    );
};

export default Hackathon;
