import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import EventList from './eventsList';
import FilterButtonDrawer from './filterButtonDrawer';
import EventContextProvider from './context/EventContextProvider';
import PrepareContextProvider from './context/PrepareContextProvider';
import FilterBadges from './filters/filterBadges';
import { Container, Image, Stack, Box, Spinner } from '@chakra-ui/core';
import ScrollToTop from '../common/scrollToTop';
import imgLogo from '../images/logo-wide-dark.svg';
import ProgrammaFile from './component/programmaFile';
import ChatWrapper from '../chat/ChatWrapper';
import { useProgrammaMetaQueryGQL } from './queries/getProgrammaMeta';
import { useParams } from 'react-router-dom';

const Event = () => {
    let { edition } = useParams();
    const { loading, data, error } = useProgrammaMetaQueryGQL({ editionName: edition });

    if (loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.500' />
            </Box>
        );
    if (error) return <p>ERROR</p>;
    if (!data) return <p>Not found</p>;

    const programma = data.getProgramma;
    return (
        <PrepareContextProvider>
            <EventContextProvider>
                <ScrollToTop />
                <Helmet>
                    <title>Programma</title>
                </Helmet>
                <Container maxW='1312px' my={[4, 4, 8, 12]} centerContent>
                    {programma.metaPhotoUrl && (
                        <>{<Image src={programma.metaPhotoUrl} alt='Cracking Cancer' />}</>
                    )}
                </Container>
                <Container maxW='1312px'>
                    <Stack direction={['column', 'column', 'row']} alignItems='end' mb={[4, 4, 8]}>
                        <ProgrammaFile />
                        <FilterButtonDrawer />
                    </Stack>
                    <FilterBadges />
                </Container>
                <EventList />
            </EventContextProvider>
        </PrepareContextProvider>
    );
};

Event.propTypes = {};

export default Event;
