import React from 'react';
import { Spacer, Stack } from '@chakra-ui/core';
import FilterDrawer from './filters/filterDrawer';
import SearchDrawer from './filters/searchDrawer';

const FilterButtonDrawer = props => {
    return (
        <div>
            <Stack direction={['column', 'column', 'row']} alignItems='end' mb={[4, 4, 8, 12]}>
                <Spacer />
                <FilterDrawer />
                <SearchDrawer />
            </Stack>
        </div>
    );
};

export default FilterButtonDrawer;
