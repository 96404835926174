import React from 'react';
import { Helmet } from 'react-helmet-async';
// import PropTypes from 'prop-types';
// import { Link, Text } from '@chakra-ui/core';
// import { Link as RouterLink } from 'react-router-dom';
import ScrollToTop from '../common/scrollToTop';
import RegisterForm from './form/registerForm';

const Registration = () => {
    return (
        <>
            <ScrollToTop />
            <Helmet>
                <title>Registrazione</title>
            </Helmet>
            <RegisterForm />
        </>
    );
};

// Registration.propTypes = {};

export default Registration;
