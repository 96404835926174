import React from 'react';
import {
    FormErrorMessage,
    FormLabel,
    FormControl,
    Input,
    Button,
    Textarea,
    SimpleGrid,
    Text,
} from '@chakra-ui/core';
import { useForm } from 'react-hook-form';
import { useContactMutation } from '../mutations/contactMutation';

const ContactForm = ({ loading }) => {
    const { handleSubmit, errors, register } = useForm();
    const [contactMutation, contactResults] = useContactMutation(errors);
    const disableForm = contactResults.loading || loading;
    const onSubmit = (values, errors) =>
        contactMutation(
            values.firstName,
            values.lastName,
            values.email,
            values.subject,
            values.question,
            errors.name,
            errors.lastName,
            errors.email,
            errors.subject,
            errors.question,
        );
    return (
        <>
            <Text mb='6'>
                Compila il modulo sottostante per contattare la Segreteria Organizzativa.
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SimpleGrid columns={{ sm: 1, md: 2, lg: 2 }} spacingX={10}>
                    {/* <SimpleGrid columns={{ xs: 1, sm: 1, md: 2 }} spacingX='40px'> */}
                    <FormControl isInvalid={errors.firstName} mb='5'>
                        <FormLabel htmlFor='firstName'>Nome</FormLabel>
                        <Input
                            type='text'
                            name='firstName'
                            id='firstName'
                            placeholder='John'
                            ref={register({
                                required: { value: true, message: 'Il tuo nome è obbligatorio' },
                            })}
                        />
                        <FormErrorMessage>
                            {errors.firstName?.message || errors.firstName}
                        </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.lastName} mb='5'>
                        <FormLabel htmlFor='lastName'>Cognome</FormLabel>
                        <Input
                            type='text'
                            name='lastName'
                            id='lastName'
                            placeholder='Doe'
                            ref={register({
                                required: {
                                    value: true,
                                    message: 'Il tuo cognome è obbligatorio',
                                },
                            })}
                        />
                        <FormErrorMessage>
                            {errors.lastName?.message || errors.lastName}
                        </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.email} mb='5'>
                        <FormLabel htmlFor='email'>Email</FormLabel>
                        <Input
                            type='email'
                            name='email'
                            id='email'
                            placeholder='example@email.com'
                            ref={register({
                                required: { value: true, message: 'Il tuo Emaile è obbligatorio' },
                            })}
                        />
                        <FormErrorMessage>{errors.email?.message || errors.email}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors.email} mb='5'>
                        <FormLabel htmlFor='subject'>Subject</FormLabel>
                        <Input
                            type='text'
                            name='subject'
                            id='subject'
                            placeholder='Aiuto con la registrazione'
                            ref={register({
                                required: { value: true, message: 'Il soggetto è obbligatorio' },
                            })}
                        />
                        <FormErrorMessage>
                            {errors.subject?.message || errors.subject}
                        </FormErrorMessage>
                    </FormControl>
                </SimpleGrid>
                <FormControl mb='5' isInvalid={errors.question}>
                    <FormLabel htmlFor='question'>Messaggio</FormLabel>
                    <Textarea
                        name='question'
                        id='question'
                        placeholder='Ciao'
                        ref={register({
                            required: { value: true, message: 'Il messaggio è obbligatorio' },
                        })}
                    ></Textarea>
                    <FormErrorMessage>
                        {errors.question?.message || errors.question}
                    </FormErrorMessage>
                </FormControl>
                <Button type='submit' mt='4' size='lg' colorScheme='brand' disabled={disableForm}>
                    Invia messaggio
                </Button>
            </form>
        </>
    );
};
export default ContactForm;
