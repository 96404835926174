import React from 'react';
import { Helmet } from 'react-helmet-async';
import ContactForm from './forms/contactForm';
import { Container, Heading, Box } from '@chakra-ui/core';
import Headquarters from './contact/headquartersComponent';
import ContactPerPerson from './contact/contactPerPerson';
import { Section, GridOneTwo } from '../components/common/styles';
// import HowToGet from './contact/howToGet';
// import ContactMap from './contact/map';

import ScrollToTop from '../common/scrollToTop';

const ContactPage = () => {
    return (
        <>
            <ScrollToTop />
            <Helmet>
                <title>Contatti</title>
            </Helmet>
            <Box bg='white'>
                <Container maxW='1312px' py={[4, 4, 8, 16]}>
                    <Heading as='h1' size='xl' textTransform='uppercase' mb='4' color='brand.700'>
                        Contatti
                    </Heading>
                    <ContactPerPerson />
                </Container>
            </Box>
            <Container maxW='1312px' py={[4, 4, 8, 16]}>
                <GridOneTwo>
                    <div>
                        <Headquarters />
                    </div>
                    <div>
                        <ContactForm />
                    </div>
                </GridOneTwo>
            </Container>
            {/* <Section isWhite style={{ paddingTop: '0px' }}>
                <ContactMap />
                <Container maxWidth='1312px'>
                    <HowToGet />
                </Container>
            </Section> */}
        </>
    );
};

export default ContactPage;
