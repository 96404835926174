import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Heading, Box, Wrap, Tag, TagLabel, TagCloseButton } from '@chakra-ui/core';
import { EventContext } from '../context/EventContextProvider';
import { PrepareContext } from '../context/PrepareContextProvider';

const FilterBadges = () => {
    const { listEvents, dispatchListEvents } = useContext(EventContext);
    const { addQueryInput } = useContext(PrepareContext);

    const handledClick = ({ name, type }) => {
        dispatchListEvents({ type: 'REMOVE_CHECKBOX', checkbox: { name, type } });

        addQueryInput(
            listEvents.filter(item => {
                if (item.type !== type) {
                    return true;
                }
                return item.name !== name;
            }),
        );
    };

    return (
        <>
            {listEvents.length > 0 && (
                <Box mb={[4, 4, 8, 12]} pb={[4, 4, 6]} borderBottom='2px solid #DEDFE0'>
                    <Wrap spacing='12px' align='center'>
                        <Heading as='h3' size='md' textTransform='uppercase'>
                            Filter:
                        </Heading>
                        {listEvents.map(item => {
                            return (
                                <Tag
                                    size='lg'
                                    borderRadius='full'
                                    variant='solid'
                                    colorScheme='brand'
                                    isTruncated
                                    maxW='300px'
                                >
                                    <TagLabel>{item.name}</TagLabel>
                                    <TagCloseButton
                                        onClick={() => {
                                            handledClick(item);
                                        }}
                                    />
                                </Tag>
                            );
                        })}
                    </Wrap>
                </Box>
            )}
        </>
    );
};

FilterBadges.propTypes = {};

export default FilterBadges;
