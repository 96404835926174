import React, { useContext } from 'react';
import { Container, Box, Heading } from '@chakra-ui/core';
import SettingsNavigation from './components/navigation';
import NotificationTable from './components/notification-table';
import { AuthContext } from '../context/auth';
import ScrollToTop from '../common/scrollToTop';

export const NotificationSettings = () => {
    const { user } = useContext(AuthContext);
    return (
        <div>
            <ScrollToTop />
            <Box mb={['24px', '24px', '72px', '120px']}>
                <Container maxW='874px'>
                    <Box mt={['4', '4', '8']} mb={['24px', '24px', '72px']}>
                        <SettingsNavigation />
                    </Box>
                    <Heading
                        as='h1'
                        size='2xl'
                        color='brand.700'
                        mb='5'
                        pb='5'
                        textTransform='uppercase'
                    >
                        Impostazioni di notifica
                    </Heading>
                    <NotificationTable user={user} />
                </Container>
            </Box>
        </div>
    );
};
export default NotificationSettings;
