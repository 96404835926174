import { useMutation, gql } from '@apollo/client';
import { useToast } from '@chakra-ui/core';
import userQueryGQL from '../../config/userQuery';

const updateUserMutationGQL = gql`
    mutation updateUser(
        $firstName: String!
        $lastName: String!
        $certifications: Boolean!
        $acceptedNewsletterEmail: Boolean
        $reminderUpdatedEvent: Boolean
        $reminderUpdatedEventEmail: Boolean
        $reminderBeforeInterestedInEvent: Boolean
        $reminderBeforeInterestedInEventEmail: Boolean
        $reminderBeforeEvent: Boolean
        $reminderBeforeEventEmail: Boolean

    ) {
        updateUser(
            input: {
                lastName: $lastName
                firstName: $firstName
                certifications: $certifications
                acceptedNewsletterEmail: $acceptedNewsletterEmail
                reminderUpdatedEvent: $reminderUpdatedEvent
                reminderUpdatedEventEmail: $reminderUpdatedEventEmail
                reminderBeforeInterestedInEvent: $reminderBeforeInterestedInEvent
                reminderBeforeInterestedInEventEmail: $reminderBeforeInterestedInEventEmail
                reminderBeforeEvent: $reminderBeforeEvent
                reminderBeforeEventEmail: $reminderBeforeEventEmail
            }
        ) {
            first_name
            last_name
            certifications
            acceptedNewsletterEmail
            reminderUpdatedEvent
            reminderUpdatedEventEmail
            reminderBeforeInterestedInEvent
            reminderBeforeInterestedInEventEmail
            reminderBeforeEvent
            reminderBeforeEventEmail
        }
    }
`;

export const useUpdateUserMutation = errors => {
    let toast = useToast();

    const [mutation, mutationResults] = useMutation(updateUserMutationGQL, {
        update(proxy, result) {

            //history.push('/grazie');
        },
        onCompleted: data => { toast({
            position: 'bottom-left',
            title: 'notifications changed.',
            description: 'notifications are changed successfuly.',
            status: 'success',
            duration: 9000,
            isClosable: true,
        });},
        onError(err) {
        },
        
    });

    const updateUser = (
        firstName,
        lastName,
        certifications,
        acceptedNewsletterEmail,
        reminderUpdatedEvent,
        reminderUpdatedEventEmail,
        reminderBeforeInterestedInEvent,
        reminderBeforeInterestedInEventEmail,
        reminderBeforeEvent,
        reminderBeforeEventEmail,

        ...errors
    ) => {
        debugger;
        return mutation(
             {
            variables: {
                firstName,
                lastName,
                certifications,
                acceptedNewsletterEmail,
                reminderUpdatedEvent,
                reminderUpdatedEventEmail,
                reminderBeforeInterestedInEvent,
                reminderBeforeInterestedInEventEmail,
                reminderBeforeEvent,
                reminderBeforeEventEmail,
            },
            refetchQueries: [
                {
                    query: userQueryGQL,
                },
            ],
            ...errors,
        });
    };
    return [updateUser, mutationResults, errors];
};
