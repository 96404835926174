import React from 'react';
import ForgotPasswordForm from './form/forgotPasswordForm';
import { Text } from '@chakra-ui/core';

const ForgotPasswordPage = () => {
    return (
        <>
            <Text fontSize='sm' textAlign='left' mb='6' mt='5'>
                Inserisci l’indirizzo email che hai utilizzato al momento della registrazione e ti
                invieremo le istruzioni per reimpostare la password.
            </Text>
            <ForgotPasswordForm />
        </>
    );
};

export default ForgotPasswordPage;
