import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Text } from '@chakra-ui/core';
import { GoRightIcon, GoLeftIcon } from '../../components/common/icons';

import { PaginationContainer, PaginationList, PaginationTotal } from '../styles';

const Pagination = () => {
    return (
        <PaginationContainer>
            <PaginationList>
                <li>
                    <Link as={RouterLink} to='/'>
                        <GoLeftIcon boxSize={[8, 8, 8, 16]} />
                    </Link>
                </li>
                <li>
                    <Link as={RouterLink} to='/' className='isActive'>
                        1
                    </Link>
                </li>
                <li>
                    <Link as={RouterLink} to='/'>
                        2
                    </Link>
                </li>
                <li>
                    <Link as={RouterLink} to='/'>
                        3
                    </Link>
                </li>
                <li>
                    <Link as={RouterLink} to='/'>
                        4
                    </Link>
                </li>
                <li>
                    <Link as={RouterLink} to='/'>
                        5
                    </Link>
                </li>
                <li>
                    <Link as={RouterLink} to='/'>
                        6
                    </Link>
                </li>
                <li>
                    <Link as={RouterLink} to='/'>
                        7
                    </Link>
                </li>
                <li>
                    <Link as={RouterLink} to='/' className='isActive'>
                        <GoRightIcon boxSize={[8, 8, 8, 16]} />
                    </Link>
                </li>
            </PaginationList>
            <PaginationTotal>
                <Text as='div' color='grey.400' textAlign='center'>
                    Totale 14 pagine
                </Text>
            </PaginationTotal>
        </PaginationContainer>
    );
};

export default Pagination;
