import React from 'react';
import { Text } from '@chakra-ui/core';

const ThankYou = () => {
    return (
        <>
            <Text fontSize='sm' textAlign='center' mt='3'>
                Abbiamo inviato una email con il link per la conferma al tuo indirizzo. Ti invitiamo
                a cliccare il link per verificare il tuo account.
            </Text>
            <Text fontSize='sm' textAlign='center' mb='2' mt='4'>
                Se non dovessi ricevere il messaggio entro 10 minuti, ti invitiamo a controllare la
                cartella Spam.
            </Text>
        </>
    );
};

export default ThankYou;
