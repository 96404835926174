import styled from 'styled-components';
import { createBreakpoint } from 'styled-components-breakpoint';
import { styledBreakpoints } from '../style/styledBreakpoints';
import { customTheme } from '../style/customChakraTheme.js';
const breakpoint = createBreakpoint(styledBreakpoints);

// background: ${customTheme.colors.brand[500]};
// background: ${props => (props.light && customTheme.colors.brand[100]) || (props.dark && customTheme.colors.brand[700]) || customTheme.colors.brand[500]};
// `url(./images/bg-login.png)`
// ${breakpoint('md')`
//      width: 560px;
// `}

export const StepListStep = styled.div``;
export const StepListContent = styled.div`
    padding: 16px;
    border-top: 16px solid #f0f0f0;
    position: relative;
    font-size: 14px;

    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: ${customTheme.fonts.heading};
        color: ${customTheme.colors.brand[700]};
        margin-bottom: 8px;
    }

    &::before {
        content: '';
        width: 24px;
        height: 16px;
        position: absolute;
        top: -16px;
        left: 50%;
        margin-left: -12px;
        border-bottom: 16px solid #fff;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 0;
    }
`;
export const StepListDate = styled.div`
    font-family: ${customTheme.fonts.heading};
    background: #f0f0f0;
    color: ${customTheme.colors.brand[700]};
    text-align: center;
    border-bottom: 4px solid #f0f0f0;

    span {
        display: inline-block;
        margin: auto;
        border: 2px solid ${customTheme.colors.brand[700]};
        background: #fff;
        padding: 4px 16px;
        border-radius: 50em;
        text-transform: uppercase;
    }
`;
export const StepList = styled.div`
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

    ${StepListStep} {
        background: #fff;

        ${breakpoint('lg')`
            margin-left: 0;
        `}
    }
`;
