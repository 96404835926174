import React, { useContext } from 'react';
import PropTypes, { number } from 'prop-types';
import { FormLabel, FormControl, Checkbox, Text, VStack, Box } from '@chakra-ui/core';
import { EventContext } from '../context/EventContextProvider';
import { STATUS } from '../constants/checkbox';

const FilterStatus = () => {
    const { isChecked, handledFilter } = useContext(EventContext);
    const statuses = [
        { id: '1', name: 'Programmato' },
        { id: '2', name: 'Live' },
        { id: '3', name: 'Replay' },
    ];

    return (
        <>
            <FormControl as='fieldset'>
                <FormLabel as='legend'>Status</FormLabel>
                <VStack spacing={4} align='stretch'>
                    {statuses && (
                        <>
                            {statuses.map(({ id, name }) => {
                                return (
                                    <Checkbox
                                        defaultIsChecked={isChecked(name)}
                                        key={id}
                                        value={id}
                                        name={name}
                                        // borderColor='grey.200'
                                        onChange={e => {
                                            handledFilter(
                                                e.target.getAttribute('value'),
                                                e.target.checked,
                                                e.target.getAttribute('name'),
                                                STATUS,
                                            );
                                        }}
                                    >
                                        {name}
                                    </Checkbox>
                                );
                            })}
                        </>
                    )}
                </VStack>
                <Box height='2px' bg='grey.200' mt='20px' mb='20px' />
            </FormControl>

            <></>
        </>
    );
};

FilterStatus.propTypes = {};

export default FilterStatus;
