import React, { createContext, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { CATEGORY, DATE, STATUS, LOCATION, SEARCH, EDITIONNAME } from '../constants/checkbox';

export const PrepareContext = createContext();

const PrepareContextProvider = props => {
    let edition = useParams();
    const [value] = Object.values(edition);
    const [queryInput, setQueryInput] = useState({
        eventCategory: [],
        startAt: [],
        status: [],
        eventLocation: [],
        searchTerm: '',
        limit: '50',
        editionName: value,
    });

    const addQueryInput = listData => {
        const [searchTerm = ''] = listData
            .filter(({ type }) => type === SEARCH)
            .map(({ data }) => data);
        const [editionName = ''] = listData
            .filter(({ type }) => type === EDITIONNAME)
            .map(({ data }) => data);

        setQueryInput({
            eventCategory: listData.filter(({ type }) => type === CATEGORY).map(({ data }) => data),
            startAt: listData.filter(({ type }) => type === DATE).map(({ data }) => data),
            status: listData.filter(({ type }) => type === STATUS).map(({ data }) => data),
            eventLocation: listData.filter(({ type }) => type === LOCATION).map(({ data }) => data),
            searchTerm,
            editionName,
        });
    };

    return (
        <PrepareContext.Provider
            value={{
                queryInput,
                setQueryInput,
                addQueryInput,
            }}
        >
            {props.children}
        </PrepareContext.Provider>
    );
};

export default PrepareContextProvider;
