import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Container,
    Heading,
    Text,
    Button,
    Box,
    Link,
    Badge,
    Wrap,
    Grid,
    VStack,
    HStack,
    StackDivider,
    Avatar,
    ButtonGroup,
    IconButton,
} from '@chakra-ui/core';
import { ArrowBackIcon } from '@chakra-ui/icons';

import {
    LinkedinShareButton,
    TwitterShareButton,
    EmailShareButton,
    FacebookShareButton,
    WhatsappShareButton,
    TwitterIcon,
    FacebookIcon,
    LinkedinIcon,
    WhatsappIcon,
    EmailIcon,
} from 'react-share';

import { IconPlay, IconDownload } from '../components/common/icons';

import ScrollToTop from '../common/scrollToTop';
import VideoThumb from '../components/common/videoThumb';

import SliderEvents from './components/sliderEvents';
import ProgramsContactform from './components/programsContactform';

const ProgramsSingle = () => {
    return (
        <>
            <ScrollToTop />
            <Container maxW='1312px' mb={[4, 4, 8, 12]}>
                <Box py={[4, 4, 4, 8]}>
                    <Link
                        as={RouterLink}
                        to='/programma'
                        color='brand.500'
                        fontSize='14px'
                        fontWeight='500'
                        textTransform='uppercase'
                    >
                        <ArrowBackIcon boxSize={6} color='brand.700' /> Torna a programma
                    </Link>
                </Box>

                <Grid
                    templateColumns={{
                        base: '1fr',
                        sm: '1fr',
                        md: '2fr 1fr',
                        lg: '2.5fr 1fr',
                    }}
                    gap={{
                        base: 4,
                        sm: 4,
                        md: 6,
                        lg: 10,
                    }}
                >
                    <Box>
                        <Wrap spacing='12px' align='center' mb='2'>
                            <Badge variant='brandDarkOutline'>Planaria</Badge>
                            <Text
                                as='span'
                                fontSize='sm'
                                fontWeight='500'
                                textTransform='uppercase'
                                color='brand.700'
                            >
                                #Opening
                            </Text>
                        </Wrap>
                        <Heading as='h1' size='2xl' color='brand.500' mb={[4, 4, 6]}>
                            La governance del SSN tra accentramento e decentramento
                        </Heading>
                        <Wrap spacing='12px' align='center' mb={[4, 4, 6, 10]}>
                            <Badge fontSize='1.4em' variant='brandDark' textTransform='uppercase'>
                                <Text as='span' color='grey.100'>
                                    Giovedi
                                </Text>{' '}
                                01/10
                            </Badge>
                            <Text
                                as='p'
                                fontFamily='heading'
                                fontSize='2xl'
                                fontWeight='500'
                                color='brand.700'
                            >
                                10:30 – 12:00
                            </Text>
                        </Wrap>
                        {/* if it's live */}
                        <Box mb={[4, 4, 6, 10]}>
                            <VideoThumb
                                isLive
                                videoUrl='/xxxxx'
                                imgUrl='https://picsum.photos/800/600'
                            />
                        </Box>
                        {/* END - if it's live */}
                        {/* if it will happen */}
                        <Box mb={[4, 4, 6, 10]}>
                            <VideoThumb isPhoto imgUrl='https://picsum.photos/800/600' />
                        </Box>
                        {/* END - if it will happen */}
                        <Box className='mainContent'>
                            <h2>
                                La pandemia – con il suo lockdown – è stata lo spartiacque tra un
                                prima e un dopo dell’innovazione digitale della sanità italiana che
                                è oggi una delle priorità strategiche su cui fondare la
                                ricostruzione
                            </h2>
                            <p>
                                La pandemia COVID-19 o Severe Acute Respiratory Syndrome CoronaVirus
                                2 (SARS-CoV-2) ha posto molte sfide e ha costretto, potremmo dire
                                finalmente, a ripensare i modelli assistenziali, a partire dalla
                                medicina generale fino all’assistenza ai pazienti fragili, dall’uso
                                dei dati all’impatto su sistemi regionali frammentati, soprattutto
                                dal punto di vista digitale.
                            </p>
                            <p>
                                Sono state proposte soluzioni estemporanee, alcune brillanti, altre
                                facevano sorridere ma la grande assente è stata l’infrastruttura
                                digitale del SSN e della società italiana, che ha pagato con un
                                durissimo prezzo, anche di vite, il ritardo digitale.
                            </p>
                            <figure>
                                <img src='https://picsum.photos/800/600' alt='xxxxx' />
                                <figcaption>
                                    Disagio mentale e Covid-19 andrà in scena al #ForumSalute2020
                                </figcaption>
                            </figure>
                            <h3>
                                PROGRAMMA DELLA SANITÀ DIGITALE IN ITALIA, 1 Ottobre, ore
                                10.30-12.00 (Forum Reloaded)
                            </h3>
                            <p>
                                <strong>
                                    Salute e cure digitali: scenario problemi ed opportunità
                                </strong>
                                <br />
                                <strong>Sergio Pillon</strong> – Componente del Working Group
                                Digital Health della European Public Health Alliance; Direttore
                                medico CIRM – Centro Internazionale Radio Medico; Componente del
                                gruppo di esperti dell’ISS Tecnologie Sanitarie per il contrasto al
                                Covid-19
                            </p>
                            <p>
                                <strong>
                                    Le professioni sanitarie, un’opportunità da cogliere
                                </strong>
                                <br />
                                <strong>Gregorio Cosentino</strong> – Presidente ASSD – Associazione
                                Scientifica per la Sanità Digitale
                            </p>
                            <p>
                                <strong>
                                    L’esperienza operativa e il ruolo delle società scientifiche,
                                    linee guida, formazione
                                </strong>
                                <br />
                                Luciano De Biase – Professore di Cardiologia, Università di Roma La
                                Sapienza II Facoltà di Medicina e Chirurgia, Azienda Ospedaliera
                                Sant’Andrea
                            </p>
                        </Box>
                    </Box>
                    {/*


                    Right column


                    */}
                    <Box>
                        {/* if it's live */}
                        <Button
                            as={RouterLink}
                            to='/xxxxx'
                            size='lg'
                            variant='brand'
                            isFullWidth
                            mb='6'
                            rightIcon={<IconPlay boxSize={6} />}
                        >
                            Play now
                        </Button>
                        {/* END - if it's live */}
                        {/* if it will happen */}
                        <Button size='lg' variant='brand' isFullWidth mb='6'>
                            Aggiungi alla mia agenda
                        </Button>
                        <Button size='lg' variant='brandDark' isFullWidth mb='6'>
                            Mi interessa
                        </Button>
                        {/* END - if it will happen */}
                        <Box bg='white' borderRadius='md' mb='6' p='4'>
                            <ProgramsContactform sender={'Valentino Rossi'} programID={123456789} />
                        </Box>
                        <Box bg='white' borderRadius='md' mb='6' p='4'>
                            <Heading
                                as='h3'
                                size='md'
                                mb='4'
                                textTransform='uppercase'
                                color='black'
                            >
                                Responsabili Scientifici
                            </Heading>
                            <VStack
                                divider={<StackDivider borderColor='gray.200' />}
                                spacing={4}
                                align='stretch'
                                fontSize='lg'
                                color='brand.500'
                            >
                                <Wrap spacing='12px' align='center'>
                                    <Avatar
                                        name='Milena Vainieri'
                                        src='https://randomuser.me/api/portraits/thumb/women/75.jpg'
                                    />
                                    <Text as='p'>Milena Vainieri</Text>
                                </Wrap>
                                <Wrap spacing='12px' align='center'>
                                    <Avatar
                                        name='Andrea Vannucci'
                                        src='https://randomuser.me/api/portraits/thumb/men/75.jpg'
                                    />
                                    <Text as='p'>Andrea Vannucci</Text>
                                </Wrap>
                            </VStack>
                        </Box>

                        <Box bg='white' borderRadius='md' mb='6' p='4'>
                            <Heading
                                as='h3'
                                size='md'
                                mb='4'
                                textTransform='uppercase'
                                color='black'
                            >
                                Comitato
                            </Heading>
                            <VStack
                                divider={<StackDivider borderColor='gray.200' />}
                                align='stretch'
                                fontSize='lg'
                                color='brand.700'
                            >
                                <Text as='p'>Sergio Pillon</Text>
                                <Text as='p'>Massimo Bisogno</Text>
                                <Text as='p'>Gregorio Cosentino</Text>
                                <Text as='p'>Luciano De Biase</Text>
                                <Text as='p'>Giuseppe De Pietro</Text>
                                <Text as='p'>Fabrizio Massimo Ferrara</Text>
                                <Text as='p'>Mauro Grigioni</Text>
                                <Text as='p'>Giuseppe Milanese</Text>
                                <Text as='p'>Giuseppe Recchia</Text>
                                <Text as='p'>Eugenio Santori</Text>
                                <Text as='p'>Chiara Sgarbossa</Text>
                                <Text as='p'>Silvia Stefanelli</Text>
                            </VStack>
                        </Box>

                        <Box bg='white' borderRadius='md' mb='6' p='4'>
                            <Heading
                                as='h3'
                                size='md'
                                mb='4'
                                textTransform='uppercase'
                                color='black'
                            >
                                Scarica file
                            </Heading>
                            <VStack
                                divider={<StackDivider borderColor='gray.200' />}
                                spacing={4}
                                align='stretch'
                                fontSize='lg'
                                color='brand.500'
                            >
                                <HStack spacing='12px' align='center'>
                                    <ButtonGroup
                                        as={RouterLink}
                                        to='/xxxxx'
                                        size='md'
                                        variant='grey'
                                    >
                                        <IconButton aria-label='Download' icon={<IconDownload />} />
                                    </ButtonGroup>
                                    <Link as={RouterLink} to='/xxxxx' lineHeight='1.3'>
                                        <Text as='div' fontSize='lg'>
                                            catalogo farmacia.pdf
                                        </Text>
                                        <Text as='div' color='brand.700' fontSize='sm'>
                                            5.62 Mb
                                        </Text>
                                    </Link>
                                </HStack>
                                <HStack spacing='12px' align='center'>
                                    <ButtonGroup
                                        as={RouterLink}
                                        to='/xxxxx'
                                        size='md'
                                        variant='grey'
                                    >
                                        <IconButton aria-label='Download' icon={<IconDownload />} />
                                    </ButtonGroup>
                                    <Link as={RouterLink} to='/xxxxx' lineHeight='1.3'>
                                        <Text as='div' fontSize='lg'>
                                            catalogo fadfg dg fgdfgdg dfgfrmdfgdfgdfgfgfgfgacia.pdf
                                        </Text>
                                        <Text as='div' color='brand.700' fontSize='sm'>
                                            5.62 Mb
                                        </Text>
                                    </Link>
                                </HStack>
                                <HStack spacing='12px' align='center'>
                                    <ButtonGroup
                                        as={RouterLink}
                                        to='/xxxxx'
                                        size='md'
                                        variant='grey'
                                    >
                                        <IconButton aria-label='Download' icon={<IconDownload />} />
                                    </ButtonGroup>
                                    <Link as={RouterLink} to='/xxxxx' lineHeight='1.3'>
                                        <Text as='div' fontSize='lg'>
                                            catalogo farmacia.pdf
                                        </Text>
                                        <Text as='div' color='brand.700' fontSize='sm'>
                                            5.62 Mb
                                        </Text>
                                    </Link>
                                </HStack>
                            </VStack>
                        </Box>
                        <Box>
                            <Text as='h3' size='lg' mb='4'>
                                Scegli il tuo Network e Condividi!
                            </Text>
                            <HStack spacing='12px' align='start'>
                                <TwitterShareButton url='/xxxxx'>
                                    <TwitterIcon size={42} round={true} />
                                </TwitterShareButton>
                                <FacebookShareButton url='/xxxxx'>
                                    <FacebookIcon size={42} round={true} />
                                </FacebookShareButton>
                                <LinkedinShareButton url='/xxxxx'>
                                    <LinkedinIcon size={42} round={true} />
                                </LinkedinShareButton>
                                <WhatsappShareButton url='/xxxxx'>
                                    <WhatsappIcon size={42} round={true} />
                                </WhatsappShareButton>
                                <EmailShareButton url='/xxxxx'>
                                    <EmailIcon size={42} round={true} />
                                </EmailShareButton>
                            </HStack>
                        </Box>
                    </Box>
                </Grid>
            </Container>
            <Box p={['24px 0', '24px 0', '48px 0']} overflow='hidden' bg='white'>
                <Container maxW='1312px'>
                    <Heading
                        as='h2'
                        size='xl'
                        mb={[4, 4, 6, 10]}
                        color='brand.500'
                        textTransform='uppercase'
                    >
                        Video di eventi
                    </Heading>
                    <SliderEvents />
                </Container>
            </Box>
        </>
    );
};

export default ProgramsSingle;
