import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

    html {
        box-sizing: border-box;
    }
    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    html,
    body,
    div,
    span,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    abbr,
    address,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    samp,
    small,
    strong,
    sub,
    sup,
    var,
    b,
    i,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section,
    summary,
    time,
    mark,
    audio,
    video {
        text-align: left;
    }

    html,
    body {
        margin: 0;
        padding: 0;
    }
    textarea {
        resize: vertical;
    }
    textarea[contenteditable] {
        -webkit-appearance: none;
    }

    code {
        line-height: 1;
        font-family: Monaco, Consolas, "Bitstream Vera Sans Mono", "Andale Mono",
            "DejaVu Sans Mono", "Lucida Console", monospace;
        font-size: 0.9em;
    }

    img,
    svg {
        display: inline-block;
        max-width: 100%;
        height: auto;
        vertical-align: middle;
        font-style: italic;
    }
    a {
        max-width: 100%;
    }
    img[alt=""],
    img:not([alt]) {
        /* border: 5px dashed red; */
    }

    svg {
        fill: currentcolor;
    }

    /* google maps images */
    .gm-style img {
        max-width: none;
    }
    /*
    img[width],
    img[height]{max-width: none;}
    */
    figure {
        max-width: 100% !important;
        margin: 0 0 1em;
    }
    figure > img {
        display: block;
    }

    /* ----------- */
    object,
    embed,
    video {
        max-width: 100%;
    }

    /* ----------- */
    textarea:active,
    textarea:focus {
        cursor: text;
        outline: none;
    }

    abbr[title],
    dfn[title] {
        cursor: help;
    }
    u,
    ins {
        text-decoration: none;
    }

    /*---------------------------------------
        autofill
    ---------------------------------------*/
    input:-webkit-autofill {
        box-shadow: inset 0 0 0px 9999px rgb(255, 255, 255);
        -webkit-box-shadow: inset 0 0 0px 9999px rgb(255, 255, 255);
    }

    /*---------------------------------------
        Utilities
    ---------------------------------------*/
    .lines-2 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .lines-3 {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    /*
    * Has comma
    */
    .has-comma:after {
        content: "," " ";
    }
    .has-comma:last-child:after {
        content: "";
    }
`;
