import React from 'react';
import ScrollToTop from '../common/scrollToTop';

import { Heading, Text } from '@chakra-ui/core';

import Search from './faq/search';

const FaqPage = () => {
    return (
        <>
            <ScrollToTop />
            <Heading as='h1' size='2xl' color='brand.700' mb='4' textTransform='uppercase'>
                Faq
            </Heading>
            <Text fontSize='lg' mb='4'>
                Hai dei dubbi? In questa sezione troverai le risposte alle domande più frequenti
            </Text>
            <Search />
        </>
    );
};

export default FaqPage;
