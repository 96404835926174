import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ScrollToTop from '../common/scrollToTop';
import { Helmet } from 'react-helmet-async';

import { Container, Heading, Text, Button, Link, Box, Wrap, SimpleGrid } from '@chakra-ui/core';
import { ArrowForwardIcon } from '@chakra-ui/icons';

import BlogThumb from './components/blogThumb';
import { GridTwoOne, BackgroundPattern } from '../components/common/styles';
import BlogCard from './components/blogCard';
import Pagination from './components/pagination';

const BlogPage = () => {
    return (
        <>
            <ScrollToTop />

            <Helmet>
                <title>Blog</title>
            </Helmet>

            <Box p={['24px 0', '24px 0', '48px 0']} overflow='hidden'>
                <Container maxW='1312px'>
                    <Box
                        position='relative'
                        // mt='8'
                        // mb='0'
                        borderRadius='5px'
                        bg='brand.500'
                        color='#fff'
                        p={['4', '8', '16']}
                    >
                        <BackgroundPattern />
                        <GridTwoOne style={{ position: 'relative', zIndex: '1' }}>
                            <div>
                                <BlogThumb isBig date='26' month='Jul' year='20' />
                            </div>
                            <div>
                                <Heading as='h2' size='xl' mb='4'>
                                    <Link as={RouterLink} to='/blog-single'>
                                        Dopo Covid ansia e depressione: effetti anche sulla mente
                                        dei pazienti
                                    </Link>
                                </Heading>
                                <Text maxW='50ch' fontSize='sm' mb='4'>
                                    Uno studio dell’Ospedale San Raffaele mostra per la prima volta
                                    le ripercussioni a medio termine che la malattia può lasciare
                                    dal punto di vista psichiatrico Disturbo post traumatico da
                                    stress, ansia, insonnia e depressione: dopo aver superato la
                                    malattia da coronavirus, i pazienti si trovano spesso a fare i
                                    conti con disagi psicologici di ...
                                </Text>
                                <Wrap spacing='12px' mb='4' fontWeight='500'>
                                    <Link as={RouterLink} to='/blog'>
                                        Coronavirus
                                    </Link>
                                    <Link as={RouterLink} to='/blog'>
                                        2020
                                    </Link>
                                    <Link as={RouterLink} to='/blog'>
                                        MALATTIE RARE 2020
                                    </Link>
                                </Wrap>
                                <Button
                                    as={RouterLink}
                                    to='/blog-single'
                                    variant='whiteOutline'
                                    size='lg'
                                    rightIcon={<ArrowForwardIcon boxSize={6} />}
                                >
                                    Leggi di più
                                </Button>
                            </div>
                        </GridTwoOne>
                    </Box>
                </Container>
            </Box>

            <Box p={['24px 0', '24px 0', '48px 0']} overflow='hidden'>
                <Container maxW='1312px'>
                    <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={10}>
                        <BlogCard />
                        <BlogCard />
                        <BlogCard />
                        <BlogCard />
                        <BlogCard />
                        <BlogCard />
                        <BlogCard />
                        <BlogCard />
                        <BlogCard />
                    </SimpleGrid>
                </Container>
            </Box>

            <Box p={['24px 0', '24px 0', '48px 0']} overflow='hidden'>
                <Container maxW='1312px'>
                    <Pagination />
                </Container>
            </Box>
        </>
    );
};

export default BlogPage;
