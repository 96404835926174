import { gql, useQuery } from '@apollo/client';

const staticPageQueryGQL = gql`
    query StaticPage($slug: String!) {
        staticPage(slug: $slug) {
            slug
            title
            content
        }
    }
`;

export const useStaticPageQuery = ({ slug }) => {
    return useQuery(staticPageQueryGQL, {
        variables: { slug },
        fetchPolicy: 'cache-and-network',
    });
};
