import { gql, useQuery } from '@apollo/client';

const getMyAgendaEventsQueryGQL = gql`
    query getMyAgendaEvents($page: Int, $limit: Int) {
        getMyAgendaEvents(page: $page, limit: $limit) {
            eventDays {
                date
                events {
                    id
                    name
                    status
                    statusAsString
                    eventCategory {
                        id
                        name
                    }
                    startAtTimestamp
                    endAtTimestamp
                    edition
                    location
                    tag
                    excerpt
                    description
                    liveVideoUrl
                    eventVideos {
                        id
                        name
                        videoUrl
                        description
                    }
                    coverPhotoUrl
                    eventSpeakers {
                        id
                        name
                        description
                        photoUrl
                    }
                    eventComitatos {
                        id
                        name
                    }
                    eventFaculties {
                        id
                        name
                    }
                    eventFiles {
                        name
                        downloadUrl
                        fileSize
                    }
                    isInMyAgenda
                    isInInterestedIn
                }
            }
            totalItemCount
        }
    }
`;

export const useGetMyAgendaEventsGQLQuery = ({ page, limit }) => {
    return useQuery(getMyAgendaEventsQueryGQL, {
        variables: { page, limit },
        //fetchPolicy: 'cache-and-network',
        //fetchPolicy: 'network-only',
        //fetchPolicy: 'cache-first',
        //nextFetchPolicy: 'cache-only',
        //fetchPolicy: 'cache-only',
        //nextFetchPolicy: 'cache-first',
        //fetchPolicy: 'no-cache',
    });
};

export default getMyAgendaEventsQueryGQL;
