import { gql, useQuery } from '@apollo/client';

const singleEventQueryGQL = gql`
    query GetSingleEvent($id: Int!) {
        getSingleEvent(id: $id) {
            id
            name
            status
            statusAsString
            eventCategory {
                id
                name
            }
            startAtTimestamp
            endAtTimestamp
            edition
            location
            tag
            excerpt
            description
            liveVideoUrl
            eventVideos {
                id
                name
                videoUrl
                description
            }
            coverPhotoUrl
            coverPhotoUrl2
            eventSpeakers {
                id
                name
                description
                photoUrl
            }
            eventComitatos {
                id
                name
                description
            }
            eventFaculties {
                id
                name
                description
            }
            eventFiles {
                name
                downloadUrl
                fileSize
            }
            isInMyAgenda
            isInInterestedIn
        }
    }
`;

export const useSingleEventQueryGQLQuery = ({ id }) => {
    return useQuery(singleEventQueryGQL, { variables: { id } });
};

export default singleEventQueryGQL;
