import React from 'react';
import { VStack, HStack, Link, IconButton, Text, StackDivider } from '@chakra-ui/core';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const PastEvents = ({ pastLinks }) => {
    return (
        <VStack
            divider={<StackDivider borderColor='gray.200' />}
            spacing={4}
            align='stretch'
            fontSize='lg'
            color='brand.500'
        >
            {pastLinks.map(pastLink => (
                <HStack spacing='12px' align='center'>
                    <Link isExternal href={pastLink.url}>
                        <IconButton
                            bg='grey.100'
                            _hover={{ bg: 'brand.500', color: 'white' }}
                            _expanded={{ bg: 'white', color: 'brand.500' }}
                            color='brand.700'
                            aria-label='Download'
                            icon={<ExternalLinkIcon />}
                        />
                    </Link>
                    <Link isExternal href={pastLink.url} lineHeight='1.3'>
                        <Text as='div' fontSize='lg' isTruncated maxW='28ch'>
                            {pastLink.title}
                        </Text>
                    </Link>
                </HStack>
            ))}
        </VStack>
    );
};

export default PastEvents;
