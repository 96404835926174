import React from 'react';
import ReactDOM from 'react-dom';
import { GlobalStyle } from './style/globalStyles';
import App from './App';
import * as serviceWorker from './serviceWorker';
//import { ApolloClient, InMemoryCache , ApolloLink, HttpLink, createHttpLink } from "@apollo/client";
import { CookiesProvider } from 'react-cookie';

import { HelmetProvider } from 'react-helmet-async';
import SimpleReactLightbox from 'simple-react-lightbox';
/* Themes */
import { ChakraProvider } from '@chakra-ui/core';
import { customTheme } from './style/customChakraTheme.js';

ReactDOM.render(
    <React.StrictMode>
        <HelmetProvider>
            <CookiesProvider>
                <ChakraProvider resetCSS theme={customTheme}>
                    <GlobalStyle />
                    <SimpleReactLightbox>
                        <App />
                    </SimpleReactLightbox>
                </ChakraProvider>
            </CookiesProvider>
        </HelmetProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
