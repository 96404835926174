import React, { useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import { useCookies } from 'react-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/auth';

const TOKEN_NAME = 'authToken';

// custom hook to handle authToken - we use compositon to decouple the auth system and it's storage
export const useAuthToken = () => {
    const [cookies, setCookie, removeCookie] = useCookies([TOKEN_NAME]);
    const setAuthToken = authToken => setCookie(TOKEN_NAME, authToken);
    const removeAuthToken = () => removeCookie(TOKEN_NAME);
    return [cookies[TOKEN_NAME], setAuthToken, removeAuthToken];
};

export const useLogout = () => {
    const [, , removeAuthToken] = useAuthToken();
    const apolloClient = useApolloClient();
    let history = useHistory();
    //let location = useLocation();
    //console.log(location);
    //let { from } = location.state || { from: { pathname: '/login' } };
    // const { logoutStorage } = useContext(AuthContext);

    const logout = async () => {
        await apolloClient.clearStore(); // we remove all information in the store
        //await apolloClient.cache.reset();
        removeAuthToken();
        localStorage.removeItem('user');

        // logoutStorage();

        //history.replace('/login');
        history.push('/');
    };

    return logout;
};
