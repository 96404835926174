import React from 'react';
import { Text } from '@chakra-ui/core';
import ValidateEmailForm from './form/validateEmail';

const Congratulation = () => {
    return (
        <>
            <Text fontSize='h3' textAlign='center' mb='4' mt='4'>
                Ti sei registrato con successo
            </Text>
            <ValidateEmailForm />
        </>
    );
};

export default Congratulation;
