import { gql, useQuery } from '@apollo/client';

const mainSliderQueryGQL = gql`
    query {
        getMainSlider {
            name
            sliderOrder
            photoUrl
            mobilePhotoUrl
        }
    }
`;

export const useMainSliderGQLQuery = () => useQuery(mainSliderQueryGQL);