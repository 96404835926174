import React from 'react';
import { Box, Spinner, SimpleGrid } from '@chakra-ui/core';

import Card from '../../components/card/card';

import { useEventsListQueryGQLQuery } from '../../events/queries/eventsList';

const SliderWebinars = ({ edition }) => {
    //const eventsData = useEventsListQueryGQLQuery({ input: { eventShowIn: ['1'] } });
    const eventsData = useEventsListQueryGQLQuery({ input: { editionName: edition, limit: 50 } });

    if (eventsData.loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.500' />
            </Box>
        );
    if (eventsData.error) return <p>ERROR</p>;
    if (!eventsData.data) return <p>Not found</p>;
    const eventsList = eventsData.data.getEvents.eventDays;
    return (
        <>
            <SimpleGrid columns={[1, 1, 2, 4]} spacing={[4, 4, 6, 6]}>
                {eventsList && (
                    <>
                        {eventsList.map(({ events }, index) => {
                            return (
                                <>
                                    {events.map(event => {
                                        return (
                                            <Box key={event.id}>
                                                {event.status === 1 && (
                                                    <Card
                                                        willbeLive
                                                        event={event}
                                                        videoDate='26/7'
                                                        videoTime='12:30'
                                                    />
                                                )}
                                                {event.status === 3 && (
                                                    <Card
                                                        event={event}
                                                        videoDate='26/7'
                                                        videoTime='12:30'
                                                    />
                                                )}
                                                {event.status == 2 && (
                                                    <Card
                                                        isLive
                                                        event={event}
                                                        videoDate='26/7'
                                                        videoTime='12:30'
                                                    />
                                                )}
                                            </Box>
                                        );
                                    })}
                                </>
                            );
                        })}
                    </>
                )}
            </SimpleGrid>
        </>
    );
};

export default SliderWebinars;
