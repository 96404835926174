import React from 'react';
import Moment from 'react-moment';
import 'moment/locale/it';

import { StepList, StepListStep, StepListDate, StepListContent } from '../styles';
const Timeline = ({ timelines }) => {
    return (
        <StepList>
            {timelines.map(timeline => (
                <StepListStep>
                    <StepListDate>
                        <span>
                            <Moment unix format='D' m='5px'>
                                {timeline.startAtTimestamp}
                            </Moment>{' '}
                            <Moment unix format='MMMM'>
                                {timeline.startAtTimestamp}
                            </Moment>
                        </span>
                    </StepListDate>
                    <StepListContent>
                        <h3>{timeline.title}</h3>
                        {timeline.description}
                    </StepListContent>
                </StepListStep>
            ))}
        </StepList>
    );
};

export default Timeline;
