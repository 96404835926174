import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Heading, Text, Link } from '@chakra-ui/core';
import { SliderDots } from '../../../components/common/styles';
import VideoThumb from '../../../components/common/videoThumb';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Pagination]);

const SliderEvents = ({ videos }) => {
    return (
        <>
            <Swiper
                spaceBetween={16}
                slidesPerView={1}
                loop={false}
                autoHeight={true}
                pagination={{
                    el: '.c-pagination',
                    type: 'bullets',
                    clickable: 'true',
                }}
                breakpoints={{
                    375: {
                        slidesPerView: 1,
                        spaceBetween: 16,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 32,
                    },
                    1024: {
                        slidesPerView: 2,
                        spaceBetween: 32,
                    },
                    1280: {
                        slidesPerView: 3,
                        spaceBetween: 32,
                    },
                }}
            >
                {videos.map(video => (
                    <SwiperSlide key={video.id}>
                        <VideoThumb
                            isSimpleVideo
                            isBig={false}
                            videoUrl={video.videoUrl}
                            isSlider
                            videoId={video.id}
                            imgUrl={video.coverPhotoUrl}
                        />
                        <Heading as='h3' size='lg' my='2'>
                            <Link as={Text} color='brand.500' className='lines-2-xxx'>
                                {video.name}
                            </Link>
                        </Heading>
                        <Text as='p' className='lines-3' fontSize='sm'>
                            {video.description}
                        </Text>
                    </SwiperSlide>
                ))}
            </Swiper>

            <SliderDots className='c-pagination' />
        </>
    );
};

export default SliderEvents;
