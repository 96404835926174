import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Container,
    Heading,
    Text,
    Button,
    Image,
    Box,
    Spinner,
    AspectRatio,
} from '@chakra-ui/core';
import { useMainStudioQueryGQL } from '../../studio/query/getMainStudio';

const MainStudio = ({ edition }) => {
    const { data, loading, error } = useMainStudioQueryGQL({
        variables: { status: ['2', '1', '3'], editionName: edition },
    });

    if (loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.500' />
            </Box>
        );
    if (error) return <p>ERROR</p>;
    if (!data) return <p>Not found</p>;

    const studios = data.getMainStudios;

    // get live event
    let studio = studios.find(({ status }) => {
        return status == '2';
    });

    // get sheduled event
    if (!studio) {
        studio = studios.find(({ status }) => {
            return status == '1';
        });
    }

    // get latest past event
    if (!studio) {
        const [first, ...tail] = studios;
        studio = first;
    }

    return (
        <>
            {studio && (
                <>
                    <div>
                        <AspectRatio
                            ratio={1.7777}
                            borderRadius='md'
                            style={{ overflow: 'hidden' }}
                        >
                            <>
                                {studio.status === '1' && (
                                    <Image src={studio.coverPhotoUrl} alt='main studio' />
                                )}
                                <>
                                    {studio.status === '2' && (
                                        <iframe
                                            title='xxxxxx'
                                            allowFullScreen
                                            src={`https://www.youtube.com/embed/${studio.liveVideoUrl}`}
                                            allow='autoplay'
                                        />
                                    )}

                                    {studio.status === '3' && (
                                        <iframe
                                            title='xxxxxx'
                                            allowFullScreen
                                            src={`https://www.youtube.com/embed/${studio.liveVideoUrl}`}
                                            allow='autoplay'
                                        />
                                    )}
                                </>
                            </>
                        </AspectRatio>
                    </div>
                    <div>
                        <Heading as='h2' size='xl' mb='4' textTransform='uppercase'>
                            {studio.title}
                        </Heading>
                        <Text
                            maxW='55ch'
                            fontSize='sm'
                            mb={['4', '4', '8']}
                            dangerouslySetInnerHTML={{ __html: studio.description }}
                        />

                        <Button
                            as={RouterLink}
                            to={`/main-studio/${edition}`}
                            variant='whiteOutline'
                            size='lg'
                        >
                            Vedi tutto
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};
export default MainStudio;
