import React from 'react';
import { Heading, Box, Text, VStack, StackDivider } from '@chakra-ui/core';
import PropTypes from 'prop-types';

const FacultiesList = ({ faculties }) => {
    return (
        <Box bg='white' borderRadius='md' mb='6' p='4'>
            <Heading as='h3' size='md' mb='4' textTransform='uppercase' color='black'>
                Faculty
            </Heading>
            <VStack
                divider={<StackDivider borderColor='gray.200' />}
                align='stretch'
                fontSize='lg'
                color='brand.700'
            >
                {faculties.map(faculty => (
                    <Box key={faculty.id}>
                        <Text>{faculty.name}</Text>
                        <Text as='p' fontSize='sm' color='black' lineHeight='1.2'>
                            {faculty.description}
                        </Text>
                    </Box>
                ))}
            </VStack>
        </Box>
    );
};

FacultiesList.propTypes = {};

export default FacultiesList;
