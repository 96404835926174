import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Heading, Text, Link, Wrap, Button } from '@chakra-ui/core';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import BlogThumb from './blogThumb';

import { BlogCardContainer } from '../styles';

class BlogCard extends Component {
    render() {
        const { postDate, postMonth, postYear, postUrl, postTitle, postText, imgUrl } = this.props;
        return (
            <BlogCardContainer>
                <BlogThumb
                    imgUrl={imgUrl}
                    title={postTitle}
                    date={postDate}
                    month={postMonth}
                    year={postYear}
                />
                <Heading as='h3' size='lg' my='4'>
                    <Link as={RouterLink} to={postUrl} color='brand.500'>
                        {postTitle}
                    </Link>
                </Heading>
                <Text fontSize='sm' mb='4'>
                    {postText}
                </Text>
                <Wrap spacing='12px' mb='4' fontWeight='500'>
                    <Link as={RouterLink} to='/' color='brand.700'>
                        Coronavirus
                    </Link>
                    <Link as={RouterLink} to='/' color='brand.700'>
                        2020
                    </Link>
                    <Link as={RouterLink} to='/' color='brand.700'>
                        MALATTIE RARE 2020
                    </Link>
                </Wrap>

                <Button
                    as={RouterLink}
                    to={postUrl}
                    variant='brand'
                    size='xsm'
                    rightIcon={<ArrowForwardIcon boxSize={5} />}
                >
                    Leggi di più
                </Button>
            </BlogCardContainer>
        );
    }
}

BlogCard.propTypes = {
    postDate: PropTypes.string.isRequired,
    postMonth: PropTypes.string.isRequired,
    postYear: PropTypes.string.isRequired,
    postUrl: PropTypes.string.isRequired,
    postTitle: PropTypes.string.isRequired,
    postText: PropTypes.string.isRequired,
    imgUrl: PropTypes.string.isRequired,
};

BlogCard.defaultProps = {
    postDate: '25',
    postMonth: 'Apr',
    postYear: '20',
    postUrl: '/blog-single',
    postTitle: 'Lorem ipsum domor',
    postText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut diam quam nulla porttitor massa id. Urna nunc id cursus metus aliquam eleifend mi in nulla. Mauris commodo quis imperdiet massa. Vitae suscipit tellus mauris a diam maecenas.',
    imgUrl: 'https://picsum.photos/640/640',
};

export default BlogCard;
