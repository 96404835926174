import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Box } from '@chakra-ui/core';
import { IconPlay } from '../../components/common/icons';

import EventContactForm from '../form/eventContact';
import PropTypes from 'prop-types';
import Share from './components/share';
import FilesList from './components/filesList';
import ComitatosList from './components/comitatosList';
import SpeakerList from './components/speakerList';
import FacultiesList from './components/facultiesList';
import AddEventToInterestedInButton from '../component/addEventToInterestedIn';
import AddEventToAgendaButton from '../component/AddEventToAgenda';
import { AuthContext } from '../../context/auth';

const RightColumn = ({ event }) => {
    const { user } = useContext(AuthContext);
    return (
        <Box>
        {(!user || !user.first_name) ?
        <Box/>:
            <>
            {/* if it's live */}
            {event.status === 2 && (
                <Button
                    as={RouterLink}
                    to={`/single-programma-preview/${event.id}`}
                    size='lg'
                    variant='brand'
                    isFullWidth
                    mb='6'
                    rightIcon={<IconPlay boxSize={6} />}
                >
                    Play now
                </Button>
            )}
            {/* END - if it's live */}
            {/* if it will happen */}
            {event.status === 1 && (
                <Box mb='2'>
                    {!event.isInInterestedIn && (
                        <Box mb='10px'>
                            <AddEventToAgendaButton
                                id={event.id}
                                isInMyAgenda={event.isInMyAgenda}
                                isInterestedIn={event.isInInterestedIn}
                            />
                        </Box>
                    )}

                    {!event.isInMyAgenda && (
                        <Box>
                            <AddEventToInterestedInButton
                                id={event.id}
                                isInterestedIn={event.isInInterestedIn}
                                isInMyAgenda={event.isInMyAgenda}
                            />
                        </Box>
                    )}
                </Box>
            )}{' '}
            </>
            }
            {/* END - if it will happen */}
            {event.status !== 3 && (
                <Box bg='white' borderRadius='md' mb='6' p='4'>
                    <EventContactForm eventName={event.name} />
                </Box>
            )}
            {event.eventSpeakers.length > 0 && <SpeakerList speakers={event.eventSpeakers} />}
            {event.eventComitatos.length > 0 && <ComitatosList comitatos={event.eventComitatos} />}
            {event.eventFiles.length > 0 && <FilesList files={event.eventFiles} />}
            {event.eventFaculties.length > 0 && <FacultiesList faculties={event.eventFaculties} />}
            <Share />
        </Box>
    );
};

RightColumn.propTypes = {};

export default RightColumn;
