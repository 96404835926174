import React from 'react';
import { Heading, Text, Link, Box, Spinner } from '@chakra-ui/core';

import ScrollToTop from '../common/scrollToTop';
import { useStaticPageQuery } from './queries/staticPage';

const TermsConditions = () => {
    const termsData = useStaticPageQuery({ slug: 'termini-e-condizioni' });
    if (termsData.loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.500' />
            </Box>
        );
    if (termsData.error) return <p>ERROR</p>;
    if (!termsData.data) return <p>Not found</p>;
    return (
        <>
            <ScrollToTop />

            {termsData.data.staticPage && (
                <>
                    <Heading as='h1' size='2xl' color='brand.700' textTransform='uppercase'>
                        {termsData.data.staticPage.title}
                    </Heading>
                    <Box
                        className='mainContent'
                        dangerouslySetInnerHTML={{ __html: termsData.data.staticPage.content }}
                    />
                </>
            )}
        </>
    );
};

export default TermsConditions;
