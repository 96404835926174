import React from 'react';
import { Container, Box, Heading } from '@chakra-ui/core';
import ChangeEmailForm from './forms/changeEmail';
import ChangePasswordForm from './forms/changePassword';
import SettingsNavigation from './components/navigation';
import Deactivate from './components/deactivate';
import ScrollToTop from '../common/scrollToTop';

export const Account = () => {
    return (
        <div>
            <ScrollToTop />
            <Box mb={['24px', '24px', '72px', '120px']}>
                <Container maxW='874px'>
                    <Box mt={['4', '4', '8']} mb={['24px', '24px', '72px']}>
                        <SettingsNavigation />
                    </Box>
                    <Heading
                        as='h1'
                        size='2xl'
                        color='brand.700'
                        mb='5'
                        pb='5'
                        textTransform='uppercase'
                    >
                        Impostazioni dell'account
                    </Heading>
                    <ChangeEmailForm />
                    <Box height='2px' bg='grey.100' width='100%' mt='30px' mb='30px' />
                    <ChangePasswordForm />
                    <Box height='2px' bg='grey.100' width='100%' mt='30px' mb='30px' />
                    <Deactivate />
                </Container>
            </Box>
        </div>
    );
};
export default Account;
