import styled from 'styled-components';
import { createBreakpoint } from 'styled-components-breakpoint';
import { styledBreakpoints } from '../style/styledBreakpoints';
import { customTheme } from '../style/customChakraTheme.js';
const breakpoint = createBreakpoint(styledBreakpoints);

// background: ${customTheme.colors.brand[500]};
// background: ${props => (props.light && customTheme.colors.brand[100]) || (props.dark && customTheme.colors.brand[700]) || customTheme.colors.brand[500]};
// `url(./images/bg-login.png)`
// ${breakpoint('md')`
//      width: 560px;
// `}

export const NotificationSingle = styled.div`
    font-size: 14px;
    padding: 12px;
    color: #fff;
    background: ${props => (props.isUnread && '#333') || null};

    & + & {
        border-top: 1px solid #fff;
    }

    a {
        display: inline !important;
        color: ${customTheme.colors.brand[500]};
    }
`;
