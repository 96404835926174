import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Box } from '@chakra-ui/core';

import '../lib/stickyHeader.js';
import ScrollToTop from '../common/scrollToTop';
import Header from './layoutMain/header/header';
import Footer from './layoutMain/footer/footer';
import { MainLayout, MainContent } from './styles';

import LegalNavigation from './navigationLegal';

class LayoutLegal extends Component {
    render() {
        const { children } = this.props;
        return (
            <MainLayout>
                <ScrollToTop />
                <Header bgBrand />
                <MainContent>
                    <Box mb={['24px', '24px', '72px', '120px']}>
                        <Container maxW='874px'>
                            <Box mt={['4', '4', '8']} mb={['24px', '24px', '72px']}>
                                <LegalNavigation />
                            </Box>
                            {children}
                        </Container>
                    </Box>
                </MainContent>
                <Footer />
            </MainLayout>
        );
    }
}

LayoutLegal.propTypes = {};

export default LayoutLegal;
