import React from 'react';

import {
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/core';

const Challenges = ({ challenges }) => {
    return (
        <Accordion allowToggle>
            {challenges.map(challenge => (
                <AccordionItem>
                    <AccordionButton
                        bg='brand.700'
                        color='white'
                        _hover={{ bg: 'brand.500', color: 'white' }}
                        _expanded={{ bg: 'white', color: 'brand.500' }}
                    >
                        <Box
                            flex='1'
                            textAlign='left'
                            fontFamily='heading'
                            textTransform='uppercase'
                            fontSize='24px'
                        >
                            {challenge.title}
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel bg='white'>
                        <Box
                            className='mainContentSmall'
                            pt='4'
                            dangerouslySetInnerHTML={{ __html: challenge.description }}
                        />
                    </AccordionPanel>
                </AccordionItem>
            ))}
        </Accordion>
    );
};

export default Challenges;
