import MDSpinner from 'react-md-spinner';
import React from 'react';
import Moment from 'react-moment';
import { Text, Box, Heading } from '@chakra-ui/core';

const ChatBoxGroup = ({ chat, chatIsLoading, user }) => {
    if (chatIsLoading) {
        return (
            <div>
                <MDSpinner size='72' />
            </div>
        );
    } else {
        return (
            <div>
                {chat.map(chat => (
                    <div key={chat.id}>
                        <div>
                            {/* ako moze klasa da je moja poruka na ovaj div ispod */}
                            <Box
                                display='flex'
                                justifyContent={`${
                                    chat.sender.uid === user.uid ? 'flex-end' : null
                                }`}
                            >
                                <Box>
                                    <Box
                                        borderRadius='md'
                                        px='4'
                                        py='3'
                                        fontSize='sm'
                                        bg={`${
                                            chat.sender.uid === user.uid ? 'grey.100' : 'brand.500'
                                        }`}
                                        color={`${
                                            chat.sender.uid === user.uid ? 'black' : 'white'
                                        }`}
                                    >
                                        <Heading as='h3' size='sm' mb='2' textTransform='uppercase'>
                                            {chat.sender.name}
                                        </Heading>
                                        <Text lineHeight='1.3' style={{ wordBreak: 'break-all' }}>
                                            {chat.text}
                                        </Text>
                                    </Box>
                                    <Text
                                        as='div'
                                        fontSize='12px'
                                        color='grey.300'
                                        my='2'
                                        textAlign={`${
                                            chat.sender.uid === user.uid ? 'right' : null
                                        }`}
                                    >
                                        <Moment unix format='HH:mm'>
                                            {chat.sentAt}
                                        </Moment>
                                    </Text>
                                </Box>
                            </Box>
                        </div>
                    </div>
                ))}
                <div id='ccChatBoxGroupEnd' />
            </div>
        );
    }
};

export default ChatBoxGroup;
