import { useMutation, gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/core';

export const eventContactMutationGQL = gql`
    mutation sendEventContact($eventName: String!, $userName: String!, $email: String, $question: String!) {
        sendEventContact(
            input: { eventName: $eventName, userName: $userName, email: $email, question: $question }
        ) {
            result
        }
    }
`;

export const useEventContactMutation = errors => {
    let history = useHistory();
    let toast = useToast();
    const [mutation, mutationResults] = useMutation(eventContactMutationGQL, {
        onCompleted: data => {
            toast({
                position: 'bottom-left',
                title: 'Question sent.',
                description: 'Question is sent successfuly.',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
        },
        onError(err) {},
    });
    const eventContact = (eventName, userName,email, question, ...errors) => {
        return mutation({
            
            variables: {
                eventName,
                userName,
                email,
                question,
            },
            ...errors,
        });
    };
    return [eventContact, mutationResults, errors];
};
