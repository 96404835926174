import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, gql } from '@apollo/client';

import { Button, Box, Text, AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay, } from '@chakra-ui/core';
import { useDeactivatedMutation } from '../mutations/deactivate';



const Deactivate = () => {
    const [deactivate, mutationResults] = useDeactivatedMutation();
    const [isOpen, setIsOpen] = React.useState();
    const { handleSubmit, errors, } = useForm();
    const onClose = () => setIsOpen(false);
    const cancelRef = React.useRef();
    const onSubmit = () => deactivate();

    return (
        <>
            <Box bg='white' mb={['24px', '24px', '72px', '120px']} p='20px'>
                <Box>
                    <Text fontSize='18' textTransform='uppercase' mb='4'>
                        <strong>Disattiva il tuo account</strong>
                    </Text>
                    Se clicchi sul pulsante "Disattiva account" i tuoi dati saranno eliminati
                    definitivamente e verrai disconnesso. <Text color='brand.700'>NOTA BENE: </Text>{' '}
                    Se deciderai di procedere non sarà possibile recuperare il tuo account e non
                    potrai più accedere con le stesse credenziali.
                </Box>
                <Button size='md' variant='brand' mt='40px' onClick={() => setIsOpen(true)}>
                    Disattiva account
                </Button>
                <AlertDialog
                    isOpen={isOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onClose}
                >
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <AlertDialogOverlay />
                    <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Disattiva account
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Sei sicuro di disattivare il tuo account
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button size='md' variant='gray' ref={cancelRef} onClick={onClose}>
                             Annulla
                        </Button>
                        <Button size='md' variant='brand' type="submit" ml={3}>
                            Confermare
                        </Button>
                    </AlertDialogFooter>
                    </AlertDialogContent>
                    </form>
                </AlertDialog>
            </Box>
        </>
    );
};

export default Deactivate;
