import { useMutation, gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';

export const passwordResetMutationGQL = gql`
    mutation passwordReset($email: String!) {
        passwordReset(input: { email: $email }) {
            result
        }
    }
`;
export const useRessetPasswordMutation = errors => {
    let history = useHistory();
    const [mutation, mutationResults] = useMutation(passwordResetMutationGQL, {
        onCompleted: data => {
            history.push('/email-sent');
        },
        onError(err) {
            if (err.graphQLErrors[0].message === 'validation_email_invalid') {
                errors.email = "L'email inserita non è valida";
            }
            if (err.graphQLErrors[0].message === 'user_not_found') {
                errors.email = "L'email inserita non è valida";
            }
        },
    });
    const ressetPassword = (email, ...errors) => {
        return mutation({
            variables: {
                email,
            },
            ...errors,
        });
    };
    return [ressetPassword, mutationResults, errors];
};
