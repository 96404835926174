import { gql, useQuery } from '@apollo/client';

const comitatoQueryGQL = gql`
    query GetComitatoScietifico($editionName: String) {
        getComitatoScietifico(editionName: $editionName) {
            category
            speakers {
                id
                name
                description
                photoUrl
            }
        }
    }
`;

export const useComitatoQueryGQL = ({ editionName }) =>
    useQuery(comitatoQueryGQL, { variables: { editionName } });
