import React from 'react';
import { FormErrorMessage, FormLabel, FormControl, Input, Button } from '@chakra-ui/core';
import { useForm } from 'react-hook-form';
import { useRessetPasswordMutation } from '../mutations/forgotPasswordMutation';

const ForgotPasswordForm = ({ loading }) => {
    const { handleSubmit, errors, register } = useForm();
    const [ressetPasswordMutation, ressetPasswordResults] = useRessetPasswordMutation(errors);
    const disableForm = ressetPasswordResults.loading || loading;
    const onSubmit = (values, errors) => ressetPasswordMutation(values.email, errors.email);
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl id='email' isInvalid={errors.email} mb={[4, 4, 8]}>
                    <FormLabel htmlFor='email'>Email</FormLabel>
                    <Input
                        name='email'
                        id='email'
                        type='email'
                        placeholder='example@email.com'
                        ref={register({
                            required: { value: true, message: 'Questo campo è obbligatorio' },
                        })}
                    />
                    <FormErrorMessage>{errors.email?.message || errors.email}</FormErrorMessage>
                </FormControl>

                <Button
                    type='submit'
                    size='lg'
                    colorScheme='brand'
                    isFullWidth
                    disabled={disableForm}
                >
                    invia istruzioni
                </Button>
            </form>
        </>
    );
};
export default ForgotPasswordForm;
