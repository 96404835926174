import React from 'react';
import { Heading, Box, Text, Wrap, VStack, StackDivider, Avatar } from '@chakra-ui/core';
import PropTypes from 'prop-types';

const SpeakerList = ({ speakers }) => {
    return (
        <Box bg='white' borderRadius='md' mb='6' p='4'>
            <Heading as='h3' size='md' mb='4' textTransform='uppercase' color='black'>
                Responsabili Scientifici
            </Heading>
            <VStack
                divider={<StackDivider borderColor='gray.200' />}
                spacing={4}
                align='stretch'
                fontSize='lg'
                color='brand.500'
            >
                {speakers.map(speaker => (
                    <Wrap key={speaker.id} spacing='12px' align='center'>
                        <Avatar name={speaker.name} src={speaker.photoUrl} />
                        <Text as='p'>{speaker.name}</Text>
                    </Wrap>
                ))}
            </VStack>
        </Box>
    );
};

SpeakerList.propTypes = {};

export default SpeakerList;
