import React, { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { AuthContext } from '../context/auth';

const userQueryGQL = gql`
    query {
        me {
            id
            first_name
            last_name
            email
            phone
            profession
            certifications
            company
            reminderBeforeEvent
            reminderBeforeInterestedInEvent
            reminderUpdatedEvent
            reminderBeforeEventEmail
            reminderBeforeInterestedInEventEmail
            reminderUpdatedEventEmail
            acceptedNewsletterEmail
        }
    }
`;

export const useUserQuery = () => {
    const context = useContext(AuthContext);

    useQuery(userQueryGQL, {
        onCompleted(userData) {
            context.login(userData.me);
        },
        //fetchPolicy: 'cache-and-network',
        fetchPolicy: 'network-only',
    });
};

export default userQueryGQL;
