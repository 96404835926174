import React from 'react';
import { VisuallyHidden } from '@chakra-ui/core';

import { HamburgerContainer } from '../styles';

const Hamburger = () => {
    const [showMenu, setShowMenu] = React.useState(false);
    const handleShowMenu = () => {
        setShowMenu(!showMenu);
        document.body.classList.toggle('is-menuvisible');
    };

    return (
        <HamburgerContainer onClick={handleShowMenu}>
            <VisuallyHidden>Toggle menu</VisuallyHidden>
        </HamburgerContainer>
    );
};

export default Hamburger;
