import React, { useContext } from 'react';
import PropTypes, { number } from 'prop-types';
import { useEventsDaysQueryGQLQuery } from '../queries/date';
import { FormLabel, FormControl, Box, Spinner, Checkbox, Text, VStack } from '@chakra-ui/core';
import { EventContext } from '../context/EventContextProvider';
import { DATE } from '../constants/checkbox';

const FilterDate = () => {
    const { isChecked, handledFilter } = useContext(EventContext);
    const { loading, data, error } = useEventsDaysQueryGQLQuery();

    if (loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.500' />
            </Box>
        );
    if (error) return <p>ERROR</p>;
    if (!data) return <p>Not found</p>;

    const { getEventDays: dates } = data;

    return (
        <>
            <FormControl as='fieldset'>
                <FormLabel as='legend'>Giorno</FormLabel>
                <VStack spacing={4} align='stretch'>
                    {dates && (
                        <>
                            {dates.map((date, index) => {
                                return (
                                    <Checkbox
                                        defaultIsChecked={isChecked(date)}
                                        key={index}
                                        value={date}
                                        name={index}
                                        onChange={e => {
                                            handledFilter(
                                                e.target.getAttribute('value'),
                                                e.target.checked,
                                                e.target.getAttribute('value'),
                                                DATE,
                                            );
                                        }}
                                    >
                                        {date}
                                    </Checkbox>
                                );
                            })}
                        </>
                    )}
                </VStack>
                <Box height='2px' bg='grey.200' mt='20px' mb='20px' />
            </FormControl>

            <></>
        </>
    );
};

FilterDate.propTypes = {};

export default FilterDate;
