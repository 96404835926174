import { useMutation, gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/core';

export const feedbackInputMutationGQL = gql`
    mutation createFeedback(
        $firstName: String!
        $lastName: String!
        $email: String!
        $subject: String!
        $question: String!
    ) {
        createFeedback(
            input: {
                firstName: $firstName
                lastName: $lastName
                email: $email
                subject: $subject
                question: $question
            }
        ) {
            result
        }
    }
`;

export const useContactMutation = errors => {
    let history = useHistory();
    let toast = useToast();
    const [mutation, mutationResults] = useMutation(feedbackInputMutationGQL, {
        onCompleted: data => {
            toast({
                position: 'bottom-left',
                title: 'Message sent.',
                description: 'Message is sent successfuly.',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
        },
        onError(err) {
            if (err.graphQLErrors[0].message === 'validation_email_invalid') {
                errors.email = ' Invalid email address';
            }
        },
    });
    const ressetPassword = (firstName, lastName, email, subject, question, ...errors) => {
        return mutation({
            variables: {
                firstName,
                lastName,
                email,
                subject,
                question,
            },
            ...errors,
        });
    };
    return [ressetPassword, mutationResults, errors];
};
