import React from 'react';
import { Box, Text, Flex, Spacer } from '@chakra-ui/core';

const Headquarters = () => {
    return (
        <Box bg='white' w='100%' p={4} h='100%' textAlign='left'>
            <Flex direction='column' h='100%'>
                <Text fontSize='32px' color='brand.500' mt='3'>
                    Uffici Koncept
                </Text>
                <Box fontFamily='roboto' fontSize='sm'>
                    {/* <Text fontSize='18px' color='brand.700' mt='3'>
                        Stazione Leopolda
                    </Text> */}
                    <Text>Via Giuseppe Tartini, 5b Firenze Italia</Text>
                    <Text color='brand.500' mt='4'>
                        segreteria@koncept.it
                    </Text>
                    <Text mt='4'>telefono (+39) 055 357223</Text>
                    <Text mt='3'>mobile (+39) 333 9922717</Text>
                </Box>
                <Spacer />
                {/* <Text fontSize='24px' color='brand.500' mt='3'>
                    Ottenere indicazioni
                </Text>
                <Box bg='brand.700' height='3px' width='90px' mb='5'></Box> */}
            </Flex>
        </Box>
    );
};

export default Headquarters;
