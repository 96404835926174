import React from 'react';
import { useParams } from 'react-router-dom';
import { Heading, Text, Link, Box, Spinner, Container } from '@chakra-ui/core';

import ScrollToTop from '../common/scrollToTop';
import { useStaticPageQuery } from './queries/staticPage';

const DinamicPage = () => {
    let { dinamicUrl } = useParams();
    const dinamicData = useStaticPageQuery({ slug: dinamicUrl });
    if (dinamicData.loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.500' />
            </Box>
        );
    if (dinamicData.error) return <p>ERROR</p>;
    if (!dinamicData.data) return <p>Not found</p>;
    return (
        <>
            <ScrollToTop />
            {dinamicData.data.staticPage && (
                <Container maxW='1312px' my={[4, 4, 8, 16]}>
                    <Heading as='h1' size='2xl' color='brand.700' textTransform='uppercase'>
                        {dinamicData.data.staticPage.title}
                    </Heading>
                    <Box
                        className='mainContent'
                        dangerouslySetInnerHTML={{ __html: dinamicData.data.staticPage.content }}
                    />
                </Container>
            )}
        </>
    );
};

export default DinamicPage;
