import { useMutation, gql } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';

export const passwordRestoreMutationGQL = gql`
    mutation passwordRestore($token: String!, $password: String!, $confirmPassword: String!) {
        passwordRestore(
            input: { token: $token, password: $password, confirmPassword: $confirmPassword }
        ) {
            result
        }
    }
`;
export const usePasswordRestoreMutation = errors => {
    let history = useHistory();
    let { resetToken } = useParams();
    const [mutation, mutationResults] = useMutation(passwordRestoreMutationGQL, {
        onCompleted: data => {
            history.push('/login');
        },
        onError(err) {
            // console.log(err.graphQLErrors);
            if (err.graphQLErrors[0].message === 'passwords_are_not_the_same') {
                // eslint-disable-next-line no-param-reassign
                errors.confirmPassword = 'password is not the same';
            }
        },
    });
    const restorePassword = (password, confirmPassword, token, ...errors) => {
        return mutation({
            variables: {
                password,
                confirmPassword,
                token,
            },
            errors,
        });
    };
    return [restorePassword, mutationResults, errors];
};
