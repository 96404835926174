import React from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink, useHistory, useLocation, BrowserRouter } from 'react-router-dom';

import { useLoginMutation } from '../mutations/loginMutation';
import {
    FormErrorMessage,
    FormLabel,
    FormControl,
    Input,
    Button,
    Box,
    Link,
    InputGroup,
    InputRightElement,
} from '@chakra-ui/core';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

const AuthenticationForm = ({ loading }) => {
    const { handleSubmit, errors, register } = useForm();
    const [loginMutation, loginMutationResults] = useLoginMutation(errors);
    const disableForm = loginMutationResults.loading || loading;
    const onSubmit = (values, ...errors) =>
        loginMutation(values.email, values.password, errors.password, errors.email);

    const [show, setShow] = React.useState(false);
    const handlePwdClick = () => setShow(!show);
    // let history = useHistory();
    // let location = useLocation();
    // console.log(history);
    // console.log(location.state);
    // console.log(history.goBack);
    // console.log(history.location.pathname);
    // console.log(history.location.state);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl isInvalid={errors.email} mb={[4, 4, 8]}>
                    <FormLabel htmlFor='email'>Email</FormLabel>
                    <Input
                        type='email'
                        name='email'
                        id='email'
                        placeholder='example@email.com'
                        ref={register({
                            required: { value: true, message: 'Questo campo è obbligatorio' },
                        })}
                    />
                    <FormErrorMessage>{errors.email?.message || errors.email}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.password} mb={4}>
                    <FormLabel htmlFor='password'>Password</FormLabel>
                    <InputGroup size='lg'>
                        <Input
                            pr='4.5rem'
                            type={show ? 'text' : 'password'}
                            name='password'
                            id='password'
                            placeholder='Password'
                            ref={register({
                                required: { value: true, message: 'Questo campo è obbligatorio' },
                                minLength: {
                                    value: 6,
                                    message: 'password deve contenere almeno 6 caratteri',
                                },
                            })}
                        />
                        <InputRightElement>
                            {show ? (
                                <ViewOffIcon
                                    color='brand.500'
                                    onClick={handlePwdClick}
                                    style={{ cursor: 'pointer' }}
                                />
                            ) : (
                                <ViewIcon
                                    color='grey.200'
                                    onClick={handlePwdClick}
                                    style={{ cursor: 'pointer' }}
                                />
                            )}
                        </InputRightElement>
                    </InputGroup>
                    {/* <Input
                        type={show ? 'text' : 'password'}
                        name='password'
                        id='password'
                        borderColor='#999'
                        focusBorderColor='brand.500'
                        placeholder='password'
                        size='lg'
                        ref={register({
                            required: { value: true, message: 'Questo campo è obbligatorio' },
                            minLength: {
                                value: 6,
                                message: 'password deve contenere almeno 6 caratteri',
                            },
                        })}
                    /> */}
                    <FormErrorMessage>
                        {errors.password?.message || errors.password}
                    </FormErrorMessage>
                </FormControl>
                <Box textAlign='right' mb='4'>
                    <Link color='brand.500' as={RouterLink} to='/password-dimenticata'>
                        Password dimenticata?
                    </Link>
                </Box>
                <Button
                    type='submit'
                    size='lg'
                    colorScheme='brand'
                    isFullWidth
                    disabled={disableForm}
                >
                    Login
                </Button>
            </form>
        </>
    );
};
export default AuthenticationForm;
