import React from 'react';
import { Image } from '@chakra-ui/core';
import { SliderDots } from '../components/common/styles';
import { SRLWrapper } from 'simple-react-lightbox';

import { PhotoContainer } from '../components/common/styles';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Pagination]);

const SliderPhotoGallery = ({ images }) => {
    return (
        <SRLWrapper>
            <Swiper
                spaceBetween={16}
                slidesPerView={1}
                loop={false}
                autoHeight={true}
                pagination={{
                    el: '.c-pagination-photo-gallery',
                    type: 'bullets',
                    clickable: 'true',
                }}
                breakpoints={{
                    375: {
                        slidesPerView: 1,
                        spaceBetween: 16,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 32,
                    },
                }}
            >
                {images.map(image => (
                    <SwiperSlide>
                        <PhotoContainer href={image.photoUrl} data-attribute='SRL'>
                            <Image src={image.photoUrl} alt='' />
                        </PhotoContainer>
                    </SwiperSlide>
                ))}
            </Swiper>
            <SliderDots className='c-pagination-photo-gallery' />
        </SRLWrapper>
    );
};

export default SliderPhotoGallery;
