import { gql, useQuery } from '@apollo/client';

const getFooterQueryGQL = gql`
    query {
        getFooter {
            description
            address
            email
            phone1
            phone2
            footerLinks {
                title
                url
            }
            logoUrl
        }
    }
`;

export const useGetFooterQuery = () => {
    return useQuery(getFooterQueryGQL);
};