import { gql, useQuery } from '@apollo/client';

const programmaMetaQueryGQL = gql`
    query GetProgramma($editionName: String) {
        getProgramma(editionName: $editionName) {
            id
            metaTitle
            metaDescription
            metaPhotoUrl
        }
    }
`;

export const useProgrammaMetaQueryGQL = ({ editionName }) =>
    useQuery(programmaMetaQueryGQL, { variables: { editionName } });
