import { useMutation, gql } from '@apollo/client';
import { useToast } from '@chakra-ui/core';
import { useAuthToken } from '../../config/auth';
import { useHistory } from 'react-router-dom';


const deactivateMutationGQL = gql`
    mutation deactivateMutationGQL {
        deactivate {
            result
        }
    }
`;

export const useDeactivatedMutation = errors => {
    const [_, setAuthToken, removeAuthtoken] = useAuthToken();
    let toast = useToast();
    let history = useHistory();

    const [deactivate, mutationResults] = useMutation(deactivateMutationGQL, {
        update(proxy, result) {
            removeAuthtoken()
            history.push('/login');
        },
        onCompleted: data => {
            toast({
                position: 'bottom-left',
                title: 'Successfuly deactivated.',
                description: 'deactivated successfuly.',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
        },
        onError(err) {
        },
    });
    return [deactivate, mutationResults, errors];
};
