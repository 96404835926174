//import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useToast } from '@chakra-ui/core';
import { useAuthToken } from '../../config/auth';

const changeEmailMutationGQL = gql`
    mutation changeEmail($newEmail: String!, $confirmEmail: String!, $password: String!) {
        changeEmail(
            input: { newEmail: $newEmail, confirmEmail: $confirmEmail, password: $password }
        ) {
            token
        }
    }
`;

export const useChangeEmailMutation = errors => {
    const [_, setAuthToken, removeAuthtoken] = useAuthToken();
    let toast = useToast();

    const [mutation, mutationResults] = useMutation(changeEmailMutationGQL, {
        // update(proxy, result) {
        //     debugger;
        //    // history.push('/grazie');
        // },
        onCompleted: data => {
            setAuthToken(data.login.token);
            toast({
                position: 'bottom-left',
                title: 'Email changed.',
                description: 'Email is changed successfuly.',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
        },
        onError(err) {
            if (err.graphQLErrors[0].message === 'invalid_credentials') {
                // eslint-disable-next-line no-param-reassign
                errors.newEmail = 'Questa email risulta essere già in uso';
                errors.confirmEmail = 'Questa email risulta essere già in uso';
                errors.password = 'Questa risulta essere già in uso';
            }
            if (err.graphQLErrors[0].message === 'validation_email_invalid') {
                errors.newEmail = "  L'email inserita non è valida";
                errors.confirmEmail = "L'email inserita non è valida";
                errors.password = "L'email inserita non è valida";
            }
            if (err.graphQLErrors[0].message === 'Response not successful') {
                errors.newEmail = 'Questa email risulta essere già in uso';
                errors.confirmEmail = 'Questa email risulta essere già in uso';
                errors.password = 'Questa risulta essere già in uso';
            }
        },
    });

    const deactivate = (
        newEmail,
        confirmEmail,
        password,

        ...errors
    ) => {
        return mutation({
            variables: {
                newEmail,
                confirmEmail,
                password,
            },
            ...errors,
        });
    };
    return [deactivate, mutationResults, errors];
};
