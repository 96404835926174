import styled from 'styled-components';
import { createBreakpoint } from 'styled-components-breakpoint';
import { styledBreakpoints } from '../style/styledBreakpoints';
import { customTheme } from '../style/customChakraTheme.js';
const breakpoint = createBreakpoint(styledBreakpoints);

// background: ${customTheme.colors.brand[500]};
// background: ${props => (props.light && customTheme.colors.brand[100]) || (props.dark && customTheme.colors.brand[700]) || customTheme.colors.brand[500]};
// `url(./images/bg-login.png)`
// ${breakpoint('md')`
//      width: 560px;
// `}

export const LegalNav = styled.nav`
    ul {
        list-style: none;
        /* display: flex;
        flex-direction: column; */
    }
    li {
        list-style: none;
    }
    a {
        display: block;
        padding: 10px 0;
        border-bottom: 1px solid ${customTheme.colors.grey[100]};
        text-transform: uppercase;
        color: ${customTheme.colors.brand[500]};
        letter-spacing: 0.05em;
        font-weight: 500;
        transition: all 0.1s ease-in-out;

        &.isActive {
            color: ${customTheme.colors.brand[700]};
            border-bottom: 1px solid ${customTheme.colors.brand[700]};
        }
        &:hover {
            color: ${customTheme.colors.brand[700]};
        }
    }

    ${breakpoint('md')`
        ul {
            display: flex;
        }
        a {
            padding: 10px 20px;
        }
    `}
`;
