import React from 'react';
import { Text, Box, Wrap } from '@chakra-ui/core';
import { NotAllowedIcon } from '@chakra-ui/icons';

const NoResults = () => {
    return (
        <Box my={[4, 4, 8, 12]} pb={[4, 4, 6]}>
            <Wrap spacing='12px' align='center'>
                <Box>
                    <NotAllowedIcon color='red.500' boxSize={8} />
                </Box>
                <Box>
                    <Text fontSize='lg'>Nessun risultato di ricerca per questi criteri.</Text>
                    <Text fontSize='sm'>Prova filtri diversi.</Text>
                </Box>
            </Wrap>
        </Box>
    );
};

export default NoResults;
