import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Text, Link } from '@chakra-ui/core';
import { useAddEventInterestedInMutation } from '../mutation/addEventToInterestedIn';

const AddEventToInterestedInButton = ({ id, isInterestedIn, isInMyAgenda }) => {
    const [InterestedIn, InterestedInAgendaResults] = useAddEventInterestedInMutation();
    //const disableForm = addEventToAgendaResults.loading || loading;
    const handleAddInterestedIn = values => InterestedIn((values.id = id));
    return (
        <>
            {!isInterestedIn ? (
                <Button size='xsm' variant='brandDark' onClick={handleAddInterestedIn}>
                    Mi interessa
                </Button>
            ) : (
                <Text as='p' color='grey.300' fontSize='sm'>
                    Aggiunto alla{' '}
                    <Text as='span' color='brand.700'>
                        Mi Interessa
                    </Text>
                </Text>
            )}
        </>
    );
};

AddEventToInterestedInButton.propTypes = {};

export default AddEventToInterestedInButton;
