
import { gql, useQuery } from '@apollo/client';

const countUnreadNotificationsQueryGQL = gql`
    query {
        countUnreadNotifications
    }
`;

export const useCountUnreadNotificationsQueryGQL = () => useQuery(countUnreadNotificationsQueryGQL,{fetchPolicy: 'network-only'});

export default countUnreadNotificationsQueryGQL;

