import React from 'react';
import { NavLink } from 'react-router-dom';
import { LegalNav } from '../../legal/styles';

const SettingsNavigation = () => {
    return (
        <LegalNav>
            <ul>
                <li>
                    <NavLink exact activeClassName='isActive' to='/profilo'>
                        Profilo
                    </NavLink>
                </li>
                <li>
                    <NavLink exact activeClassName='isActive' to='/account'>
                        Account
                    </NavLink>
                </li>
                <li>
                    <NavLink exact activeClassName='isActive' to='/notifiche'>
                        Notifiche
                    </NavLink>
                </li>
            </ul>
        </LegalNav>
    );
};

export default SettingsNavigation;
