import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
//import { useUserQuery } from './config/userQuery';
import { useAuthToken, useLogout } from './config/auth';
/* Layouts */
import LayoutMain from './layout/main';
import LayoutAuth from './layout/layoutAuth';
import LayoutLegal from './layout/legal';
/* Pages */
import Homepage from './homepage/homepage.js';
import Login from './auth/login.js';
import Registration from './auth/registration';
import ForgotPasswordPage from './auth/forgotPassword';
import Private from './private';
import ResetPasswordPage from './auth/ressetPassword';
import ThankYou from './auth/thankYou';
import EmailSent from './auth/emailSent';
import BlogPage from './blog/blogPage';
import ContactPage from './legal/contact';
import FaqPage from './legal/faq';
import PrivacyPolicy from './legal/privacyPolicy';
import TermsConditions from './legal/termsConditions';
import Event from './events/eventsPage';
import Programs from './programs/programs';
import Hackathon from './hackathon/hackathon';
import Patrocini from './patrocini/patrocini';
import Partners from './partners/partners';
// !!!delete
import ProgramsSingle from './programs/programsSingle';
import ProgramsLive from './programs/programsLive';
import BlogSingle from './blog/blogSingle';
// !!!END - delete
import EventSingle from './events/singleEvent/singleEvent';
import ProgramPreview from './events/previewVideo/previewPage';
import ComitatoScientifico from './comitato/comitatoScientifico';
import Congratulation from './auth/congratulation';
import ProgramPreviewSlider from './events/previewVideo/previewSliderVideo';
import Account from './settings/account';
import MainStudio from './studio/mainStudio';
import Error404 from './status/error404';
import jwt_decode from 'jwt-decode';
import { NotificationContainer } from 'react-notifications';
import { AuthProvider } from './context/auth';
import Profilo from './settings/profile';
import NotificationSettings from './settings/notifications';
import DinamicPage from './legal/dinamicPage';
import Editions from './editions/editions';
import usePageTracking from './config/useTrackingPage';

export const AuthGate = () => {
    const [authToken] = useAuthToken();
    const logout = useLogout();
    const getYear = () => {
        return new Date().getFullYear();
    };
    //let edition = getYear();
    let edition = '2020';
    if (authToken) {
        const { exp } = jwt_decode(authToken);

        if (Date.now() > exp * 1000) {
            logout();
        }
    }

    const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => (
        <Route
            {...rest}
            render={props =>
                authToken ? (
                    <Layout {...props}>
                        <NotificationContainer />
                        <Component {...props} />
                    </Layout>
                ) : (
                    <Redirect
                        to={{ pathname: '/login', state: { next: props.location.pathname } }}
                    />
                )
            }
        />
    );

    const AuthRoute = ({ component: Component, layout: Layout, title, withTitle, ...rest }) => (
        <Route
            {...rest}
            render={props =>
                authToken ? (
                    <Redirect to='/' />
                ) : (
                    <Layout {...props} withTitle title={title}>
                        <Component {...props} />
                    </Layout>
                )
            }
        />
    );

    const PublicRoute = ({ component: Component, layout: Layout, ...rest }) => {
        usePageTracking();
        return (
            <Route
                {...rest}
                render={props => (
                    <Layout {...props}>
                        <Component {...props} />
                    </Layout>
                )}
            />
        );
    };

    return (
        <Router>
            <Switch>
                <Route exact path='/' layout={LayoutMain} component={Homepage} />
                <AuthRoute
                    exact
                    path='/login'
                    layout={LayoutAuth}
                    title='Login'
                    component={Login}
                />
                <AuthRoute
                    exact
                    path='/registrati'
                    layout={LayoutAuth}
                    title='modulo di registrazione'
                    component={Registration}
                />
                <AuthRoute
                    exact
                    path='/password-dimenticata'
                    layout={LayoutAuth}
                    title='password dimenticata'
                    component={ForgotPasswordPage}
                />
                <AuthRoute
                    exact
                    path='/reset-password/:resetToken'
                    layout={LayoutAuth}
                    title='scegli la nuova password'
                    component={ResetPasswordPage}
                />
                <AuthRoute
                    exact
                    path='/validate/:resetToken'
                    layout={LayoutAuth}
                    title='Congratulazioni'
                    component={Congratulation}
                />
                <AuthRoute
                    exact
                    path='/grazie'
                    layout={LayoutAuth}
                    title='grazie!'
                    component={ThankYou}
                />

                <AuthRoute
                    exact
                    path='/email-sent'
                    layout={LayoutAuth}
                    title='email inviata'
                    component={EmailSent}
                />
                <PublicRoute exact path='/past-editions' layout={LayoutMain} component={Editions} />
                <PublicRoute exact path='/blog' layout={LayoutMain} component={BlogPage} />
                <PublicRoute exact path='/contatti' layout={LayoutMain} component={ContactPage} />
                <PublicRoute exact path='/faq' layout={LayoutLegal} component={FaqPage} />
                <PublicRoute
                    exact
                    path='/programma-static'
                    layout={LayoutMain}
                    component={Programs}
                />
                <PublicRoute
                    exact
                    path='/hackathon/:edition'
                    layout={LayoutMain}
                    component={Hackathon}
                />
                {/*  !!!!! delete */}
                <PublicRoute exact path='/blog-single' layout={LayoutMain} component={BlogSingle} />
                <PublicRoute
                    exact
                    path='/single-event'
                    layout={LayoutMain}
                    component={ProgramsSingle}
                />
                <PublicRoute
                    exact
                    path='/page/:dinamicUrl'
                    layout={LayoutMain}
                    component={DinamicPage}
                />
                <PublicRoute
                    exact
                    path='/live-event'
                    layout={LayoutMain}
                    component={ProgramsLive}
                />
                <PublicRoute
                    exact
                    path='/patrocini/:edition'
                    layout={LayoutMain}
                    component={Patrocini}
                />
                <PublicRoute
                    exact
                    path={`/partner/:edition`}
                    layout={LayoutMain}
                    component={Partners}
                />
                {/*  END - !!!!! delete */}
                <PublicRoute
                    exact
                    path='/privacy-policy'
                    layout={LayoutLegal}
                    component={PrivacyPolicy}
                />
                <PublicRoute
                    exact
                    path='/termini-condizioni'
                    layout={LayoutLegal}
                    component={TermsConditions}
                />
                <PublicRoute
                    exact
                    path='/comitato-scientifico/:edition'
                    layout={LayoutMain}
                    component={ComitatoScientifico}
                />
                <PublicRoute
                    exact
                    path='/main-studio/:edition'
                    layout={LayoutMain}
                    component={MainStudio}
                />
                <PrivateRoute exact path='/private' layout={LayoutMain} component={Private} />
                <PublicRoute
                    exact
                    path={`/programma/:${edition}`}
                    layout={LayoutMain}
                    component={Event}
                />
                {/* <PublicRoute
                    exact
                    path='/hackathonNew'
                    layout={LayoutMain}
                    component={Hackathon}
                /> */}
                <PublicRoute
                    exact
                    path='/single-programma/:id'
                    layout={LayoutMain}
                    component={EventSingle}
                />
                <PublicRoute
                    exact
                    path='/single-programma-preview/:id'
                    layout={LayoutMain}
                    component={ProgramPreview}
                />
                <PrivateRoute
                    exact
                    path='/single-programma-preview-video/:id'
                    layout={LayoutMain}
                    component={ProgramPreviewSlider}
                />
                <PrivateRoute exact path='/account' layout={LayoutMain} component={Account} />
                <PrivateRoute exact path='/profilo' layout={LayoutMain} component={Profilo} />
                <PrivateRoute
                    exact
                    path='/notifiche'
                    layout={LayoutMain}
                    component={NotificationSettings}
                />

                <Route path='*' component={Error404} />
            </Switch>
        </Router>
    );
};
