import React from 'react';
import { NavLink } from 'react-router-dom';
import { LegalNav } from '../legal/styles';

const LegalNavigation = () => {
    return (
        <LegalNav>
            <ul>
                <li>
                    <NavLink exact activeClassName='isActive' to='/privacy-policy'>
                        Privacy Policy
                    </NavLink>
                </li>
                <li>
                    <NavLink exact activeClassName='isActive' to='/termini-condizioni'>
                        Termini e Condizioni
                    </NavLink>
                </li>
                <li>
                    <NavLink exact activeClassName='isActive' to='/faq'>
                        FAQ
                    </NavLink>
                </li>
            </ul>
        </LegalNav>
    );
};

export default LegalNavigation;
