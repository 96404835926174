import React from 'react';
import ChatWrapper from '../chat/ChatWrapper';
import ScrollToTop from '../common/scrollToTop';
import { Helmet } from 'react-helmet-async';
import {
    Container,
    Heading,
    Text,
    Box,
    Badge,
    Wrap,
    Grid,
    AspectRatio,
    Image,
} from '@chakra-ui/core';
import Programma from './programma';

const StudioSingleLive = ({ studio }) => {
    return (
        <div>
            <Box bg='black' color='white'>
                {/* <ScrollToTop /> */}
                <Helmet>
                    <title>{studio.title}</title>
                </Helmet>

                <Container maxW='1312px' py={[4, 4, 8, 12]}>
                    <Grid
                        templateColumns={{
                            base: '1fr',
                            sm: '1fr',
                            md: '2fr 1fr',
                            lg: '2.5fr 1fr',
                        }}
                        gap={{
                            base: 4,
                            sm: 4,
                            md: 6,
                            lg: 10,
                        }}
                    >
                        <Box minW='0'>
                            <Box mb={[4, 4, 6, 10]}>
                                <AspectRatio
                                    // ratio={16 / 9}
                                    ratio={1.7777}
                                    borderRadius='md'
                                    style={{ overflow: 'hidden' }}
                                >
                                    <>
                                        <>
                                            <iframe
                                                title='xxxxxx'
                                                allowFullScreen
                                                src={`https://www.youtube.com/embed/${studio.liveVideoUrl}`}
                                                allow='autoplay'
                                            />
                                        </>
                                    </>
                                </AspectRatio>
                            </Box>
                            <Heading as='h1' size='2xl' color='brand.500' mb={[4, 4, 6]}>
                                {studio.title}
                            </Heading>
                            <Wrap spacing='12px' align='center' mb={[4, 4, 6, 10]}>
                                <Badge
                                    fontSize='1.4em'
                                    variant='brandDark'
                                    textTransform='uppercase'
                                >
                                    <Text as='span' color='grey.100'>
                                        {studio.subTitle}
                                    </Text>{' '}
                                </Badge>
                                <Text as='p' fontFamily='heading' fontSize='2xl' fontWeight='500'>
                                    {studio.timeRange}
                                </Text>
                            </Wrap>
                            {/* will be live */}
                            <Box
                                className='mainContent  columns-2'
                                mb={[4, 4, 10]}
                                dangerouslySetInnerHTML={{ __html: studio.description }}
                            />

                            {/* END - will be live */}

                            {/* IF IT'S LIVE */}
                            <Grid
                                templateColumns={{
                                    base: '1fr',
                                    sm: '1fr',
                                    md: '1fr',
                                }}
                                gap={{
                                    base: 4,
                                    sm: 4,
                                    md: 6,
                                }}
                            >
                                <>
                                    {studio.mainStudioProgrammas && (
                                        <>
                                            {studio.mainStudioProgrammas.map(
                                                (
                                                    { mainStudioProgrammaItems, title, id },
                                                    index,
                                                ) => {
                                                    return (
                                                        <Programma
                                                            key={id}
                                                            title={title}
                                                            id={id}
                                                            mainStudioProgrammaItems={
                                                                mainStudioProgrammaItems
                                                            }
                                                        />
                                                    );
                                                },
                                            )}
                                        </>
                                    )}
                                </>
                            </Grid>
                            {/* END - IF IT'S LIVE */}
                        </Box>

                        {/* Right column */}

                        <Box minW='0'>
                            <ChatWrapper />
                        </Box>

                        {/*<Box>*/}
                        {/*    <AspectRatio*/}
                        {/*        bg='white'*/}
                        {/*        color='black'*/}
                        {/*        borderRadius='md'*/}
                        {/*        mb='6'*/}
                        {/*        ratio={[1.3, 1.3, 0.89, 0.71]}*/}
                        {/*        borderRadius='md'*/}
                        {/*        style={{ overflow: 'hidden' }}*/}
                        {/*    >*/}
                        {/*        <>*/}
                        {/*<ChatWrapper />*/}
                        {/*</>*/}
                        {/*</AspectRatio>*/}
                        {/*</Box>*/}
                    </Grid>
                </Container>
            </Box>
        </div>
    );
};

export default StudioSingleLive;
