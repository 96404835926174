import React from 'react';
import { Box, Spinner } from '@chakra-ui/core';
import { useFaqQuery } from '../queries/faq';
import FaqAccordion from './accordion';

const FaqAcordionList = ({ searchTerm }) => {
    const faqData = useFaqQuery({ term: searchTerm });
    if (faqData.loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.500' />
            </Box>
        );
    if (faqData.error) return <p>ERROR</p>;
    if (!faqData.data) return <p>Not found</p>;
    return (
        <>
            {faqData.data.getFaq && (
                <>
                    {faqData.data.getFaq.map(faq => (
                        <FaqAccordion faq={faq} key={faq.question} />
                    ))}
                </>
            )}
        </>
    );
};

export default FaqAcordionList;
